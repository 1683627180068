import PropTypes from 'prop-types'
import React from 'react'
import withPermissions from '../../containers/withPermissions'
import FloatingActionButton from 'material-ui/FloatingActionButton'
import { TextBlack } from '../../constants/colors'
import ContentAdd from 'material-ui/svg-icons/content/add'

export const FABwithPermissions = withPermissions(FloatingActionButton)

export const FAB = (props) => {
  const style = { bottom: '32px', position: 'fixed', right: '32px', zIndex: '999' }
  return (
    <FABwithPermissions {...props} backgroundColor={TextBlack} style={style}>
      <ContentAdd />
    </FABwithPermissions>
  )
}

FAB.propTypes = {
  permission: PropTypes.string.isRequired
}

export default FAB
