import * as colors from '../../../constants/colors'

export const StatusColorMap = {
  default: {
    color: '#222',
    background: 'white'
  },
  '20': {
    color: colors.Panic,
    background: 'white'
  },
  '0': {
    color: colors.Success,
    background: 'white'
  },
  '10': {
    color: colors.Panic,
    background: colors.FairPink
  },
  '30' : {
    color: colors.Yellow,
    background: 'white'
  }
}
