import { defineMessages } from 'react-intl'

export default defineMessages({
  edit: {
    id: 'Group.components.edit',
    defaultMessage: 'EDIT'
  },
  editGroupNameTile: {
    id: 'Group.compoents.editGroupNameTitle',
    defaultMessage: 'Edit group name'
  },
  'editGroupNameFloatingLabelText': {
    id: 'Group.compoents.editGroupNameFloatingLabelText',
    defaultMessage: 'Groupname'
  },
  'usersInGroup': {
    id: 'Group.compoents.usersInGroup',
    defaultMessage: 'Users in group'
  },
  'editGroupNameHintText': {
    id: 'Group.compoents.editGroupNameHintText',
    defaultMessage: 'Groupname'
  },
  submit: {
    id: 'Group.components.submit',
    defaultMessage: 'submit'
  },
  screens: {
    id: 'Group.components.view.screens',
    defaultMessage: 'Screens'
  },
  screensName: {
    id: 'Group.components.view.screensName',
    defaultMessage: 'Screen name'
  },
  techName: {
    id: 'Group.components.view.techName',
    defaultMessage: 'Technical name'
  },
  city: {
    id: 'Group.components.view.city',
    defaultMessage: 'city'
  },
  description: {
    id: 'Group.components.view.description',
    defaultMessage: 'description'
  },
  username: {
    id: 'Login.components.username',
    defaultMessage: 'username'
  },
  name: {
    id: 'Users.components.Createuser.name',
    defaultMessage: 'name'
  },
  delete: {
    id: 'Group.components.Group.delete',
    defaultMessage: 'Delete Group'
  },
  company: {
    id: 'Users.components.Createuser.company',
    defaultMessage: 'company'
  },
  title: {
    id: 'Users.components.Createuser.title',
    defaultMessage: 'Title'
  }
})
