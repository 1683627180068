import PropTypes from 'prop-types'
import React from 'react'
import Screen from './Screen'
import CenteredLabel from './CenteredLabel'
import SliderRecyclerView from './SliderRecyclerView'
import RecyclerView from './RecyclerView'
import './screens.scss'
import * as dimensions from '../../../constants/dimensions'
import messages from '../constants/messages'
import { FormattedMessage } from 'react-intl'
import { Background } from '../../../constants/colors'
import {
  SliderHeader,
  ScreensCount,
  AutoSlide,
  ScrollLabel,
  RelevantScreens,
  RelevantScreensWrapper
} from './StyledComponents'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export class Screens extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      width: '0',
      height: '0',
      relevantScreensLimit: 9,
      autoPlay: global.window.localStorage.autoPlay === 'true',
      sliderCount: null
    }
    this.timer = undefined
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  updateWindowDimensions () {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }
  componentDidMount () {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount () {
    // perform necessary cleanup
    window.removeEventListener('resize', this.updateWindowDimensions)
    this.timer = clearInterval(this.timer)
  }
  componentWillMount () {
    this.props.getScreens()
    this.timer = window.setInterval(
      () => this.props.getScreens(true),
      60 * 1000
    )
  }

  slideToggle = () => {
    if (this.state.autoPlay) {
      this.childRef.slickPause()
    } else {
      this.childRef.slickPlay()
    }
  }

  _numberOfColumns (width) {
    if (width >= dimensions.MEDIUM_VIEWPORT) {
      return 4
    } else if (width >= dimensions.SMALL_VIEWPORT) {
      return 2
    }
    return 1
  }

  _handleAutoScrolling () {
    this.setState(prevState => {
      global.window.localStorage.autoPlay = !prevState.autoPlay
      this.slideToggle()
      return { autoPlay: !prevState.autoPlay }
    })
  }
  setRef = (ref) => {
    this.childRef = ref
  }
  setSlideCount = (count) => {
    this.setState({
      sliderCount: count
    })
  }

  getRows = () => {
    const dotsHeight = 25
    const cardHeight = 93
    const headerHeight = document.getElementById('header')
      ? document.getElementById('header').clientHeight
      : 64
    const relevantScreensHeight = document.getElementById('relevant-screens')
      ? document.getElementById('relevant-screens').clientHeight
      : 200
    const remainingHeight = this.state.height - headerHeight - relevantScreensHeight - dotsHeight

    return Math.floor(remainingHeight / cardHeight)
  }
  createScreensArray = (array) => {
    const screensArray = []
    let screensArrayCounter = 0
    array.map((screen, i) => {
      if (i !== 0 && i % this.getRows() === 0) {
        screensArrayCounter++
      }
      if (!screensArray[screensArrayCounter]) {
        screensArray[screensArrayCounter] = []
      }
      screensArray[screensArrayCounter].push(
        <Screen isMobile={this.props.isMobile} key={screen.id} {...screen} screenLocation={screen.location} />
      )
    })
    return screensArray
  }
  createRelevantArray = () => {
    const errorScreens = this.props.screens.filter((a) => a.status.status === 1)
    const hottestOnes = this.props.screens.filter((a) => a.temp >= 40).sort((a, b) => a.temp < b.temp ? 1 : -1)
      .slice(0, (this.state.relevantScreensLimit - errorScreens.length))
    const relevantScreens = errorScreens.concat(hottestOnes)
    return relevantScreens
  }
  calculateTotalScreens = () => {
    const val = window.innerWidth
    if (val > 1400 && window.innerHeight > 1080){
      return 3
    } else if (val > 1400) {
      return 4
    } else if (val < 1400 && val > 800) {
      return 2
    } else if (val < 800) {
      return 1
    } else {
      return 4
    }
  }
  render () {
    const numberOfColumns = this._numberOfColumns(this.state.width)
    const { isMobile, screens } = this.props

    const screensArray = this.createScreensArray(this.props.screens)
    const relevantScreens = this.createScreensArray(this.createRelevantArray())
    this.createRelevantArray()
    const sliderSettings = {
      dots: true,
      infinite: this.props.screens.length >= 24,
      autoplay: this.state.autoPlay,
      autoplaySpeed: 5000,
      speed: 500,
      slidesToShow:this.calculateTotalScreens(),
      centerMode: false,
      centerPadding: '0',
      mobileFirst: true,
      rows: 1,
      adaptiveHeight: false
    }

    return this.props.children || (
      <div style={{ backgroundColor: Background }}>
        {screens.length === 0
          ? this.props.loading ? null : <CenteredLabel label={<FormattedMessage {...messages.noScreensFound} />} />
          : (
            <div>
              {
                isMobile
                  ? ''
                  : relevantScreens.length > 0 && (
                    <RelevantScreensWrapper id={"relevant-screens"}>
                      <RelevantScreens>
                        <ScreensCount>
                          Relevant ({relevantScreens[0].length || 0 + relevantScreens[1].length})
                        </ScreensCount>
                      </RelevantScreens>
                      <RecyclerView
                        numberOfColumns={numberOfColumns}
                        isMobile={isMobile}
                        content={relevantScreens}
                      />
                    </RelevantScreensWrapper>
                  )
              }
              <div>
                {isMobile
                  ? ''
                  : (
                    <SliderHeader>
                      <ScreensCount>
                        All ({this.props.screens.length})
                      </ScreensCount>
                      <AutoSlide>
                        <ScrollLabel>Autoscroll </ScrollLabel>
                        <input
                          type='checkbox'
                          checked={this.state.autoPlay}
                          onClick={() => this._handleAutoScrolling()}
                        />
                      </AutoSlide>
                    </SliderHeader>
                  )
                }
                <SliderRecyclerView
                  setRef={this.setRef}
                  height={this.state.height}
                  numberOfColumns={numberOfColumns}
                  isMobile={isMobile}
                  content={screensArray}
                  sliderSettings={sliderSettings}
                />
              </div>
            </div>
          )
        }
      </div>
    )
  }
}

Screens.propTypes = {
  loading: PropTypes.any.isRequired,
  isMobile: PropTypes.any.isRequired,
  screens: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired
  })).isRequired,
  getScreens: PropTypes.func.isRequired,
  children: PropTypes.element,
  history: PropTypes.object
}

export default Screens
