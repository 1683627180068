import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'Roles.components.create.title',
    defaultMessage: 'Create Role'
  },
  submit: {
    id: 'Roles.components.create.submit',
    defaultMessage: 'submit'
  },
  roleName: {
    id: 'Roles.components.create.roleName',
    defaultMessage: 'Role name'
  }

})
