import { defineMessages } from 'react-intl'

export default defineMessages({
  passwordsNotIdentical: {
    id: 'error.passwords.do.not.match',
    defaultMessage: 'Passwords should be equal.'
  },
  password: {
    id: 'Reset.component.password',
    defaultMessage: 'password'
  },
  repeatPassword: {
    id: 'Reset.component.repeatPassword',
    defaultMessage: 'repeat password'
  },
  resetPassword: {
    id: 'Reset.component.resetPassword',
    defaultMessage: 'reset password'
  }
})
