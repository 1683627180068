import * as dimensions from '../constants/dimensions'
import * as actions from '../constants/actions'

// ------------------------------------
// Actions
// ------------------------------------
export function resizeChange (mobile = true) {
  return {
    type: actions.WINDOW_RESIZE,
    mobile
  }
}

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = window.innerWidth <= dimensions.MOBILE_WIDTH

export default function locationReducer (state = initialState, action) {
  return action.type === actions.WINDOW_RESIZE
    ? action.mobile
    : state
}
