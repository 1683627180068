import { connect } from 'react-redux'
import { logout } from '../store/authentication'
import { withRouter } from 'react-router'

import Header from '../components/Header/Header'

const mapActionCreators = {
  logout
}

const mapStateToProps = (state) => ({
  loggedIn: state.auth.authenticated
})

export default withRouter(connect(mapStateToProps, mapActionCreators)(Header))
