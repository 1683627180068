import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Notice from './Notice'

export class Notices extends Component {
  constructor (props) {
    super(props)
    this.timer = undefined
  }
  componentWillMount () {
    if (this.props.notices.length && !this.timer) {
      this.clearNoticesWithTimeout()
    }
  }

  componentWillReceiveProps (nextProps) {
    // remove notices after navigating to a new page
    if (this.props.location !== nextProps.location) {
      this.props.removeAllMessages()
    }
    if (nextProps.notices.length && !this.timer) {
      this.clearNoticesWithTimeout()
    }
  }

  componentWillUnmount () {
    // perform necessary cleanup
    this.timer = clearInterval(this.timer)
  }

  clearNoticesWithTimeout () {
    // Remove the current error message after 3 seconds
    this.timer = setInterval(() => {
      // cleanup timer if this is the last notice
      if (this.props.notices.length <= 1) this.timer = clearInterval(this.timer)
      if (this.props.notices.length) this.props.popMessage()
    }, 3000)
  }

  render () {
    const { notices } = this.props
    const open = !!notices.length
    const tail = open ? notices[notices.length - 1] : { message: '', type: '' }

    return (
      <div>
        <Notice {...tail} open={open} />
      </div>
    )
  }
}

Notices.propTypes = {
  notices     : PropTypes.array.isRequired,
  removeAllMessages : PropTypes.func.isRequired,
  popMessage : PropTypes.func.isRequired,
  location : PropTypes.string.isRequired
}

export default Notices
