// User
export const USER_FETCHED = 'USER_FETCHED'
export const CLEAR_USER = 'CLEAR_USER'

// Users
export const PUT_USERS = 'PUT_USERS'
export const DELETE_USER = 'DELETE_USER'
export const ADD_USER = 'ADD_USER'
export const CHANGE_USER = 'CHANGE_USER'

// Groups
export const GROUPS_CHANGE = 'GROUPS_CHANGE'
export const GROUPS_UPDATE_GROUP = 'GROUPS_UPDATE_GROUP'
export const GROUPS_ADD_GROUP = 'GROUPS_ADD_GROUP'
export const GROUPS_DELETE_GROUP = 'GROUPS_DELETE_GROUP'

// Group
export const UPDATE_GROUP = 'UPDATE_GROUP'
export const UPDATE_GROUP_SCREENS = 'UPDATE_GROUP_SCREENS'
export const UPDATE_GROUP_USERS = 'UPDATE_GROUP_USERS'
export const ADD_GROUP_USER = 'ADD_GROUP_USER'
export const ADD_GROUP_SCREEN = 'ADD_GROUP_SCREEN'
export const REMOVE_GROUP_USER = 'REMOVE_GROUP_USER'
export const REMOVE_GROUP_SCREEN = 'REMOVE_GROUP_SCREEN'

// isMobile
export const WINDOW_RESIZE = 'WINDOW_RESIZE'

// Me
export const ME_CHANGE = 'ME_CHANGE'

// Notice
export const SET_NOTICE = 'SET_NOTICE'
export const CLEAR_NOTICE = 'CLEAR_NOTICE'
export const CLEAR_ALL_NOTICES = 'CLEAR_ALL_NOTICES'

// Role
export const UPDATE_ROLE = 'UPDATE_ROLE'
export const UPDATE_ROLE_PERMISSIONS = 'UPDATE_ROLE_PERMISSIONS'
export const UPDATE_ROLE_USERS = 'UPDATE_ROLE_USERS'
export const ADD_ROLE_USER = 'ADD_ROLE_USER'
export const ADD_ROLE_PERMISSION = 'ADD_ROLE_PERMISSION'
export const DELETE_ROLE = 'DELETE_ROLE'
export const REMOVE_ROLE_USER = 'REMOVE_ROLE_USER'
export const REMOVE_ROLE_PERMISSION = 'REMOVE_ROLE_PERMISSION'

// Roles
export const UPDATE_ROLES = 'UPDATE_ROLES'
export const ROLES_UPDATE_ROLE = 'ROLES_UPDATE_ROLE'
export const ROLES_ADD_ROLE = 'ROLES_ADD_ROLE'
export const ROLES_DELETE_ROLE = 'ROLES_DELETE_ROLE'

// Screen
export const UPDATE_SCREEN = 'UPDATE_SCREEN'
export const CLEAR_INCIDENT = 'CLEAR_INCIDENT'
export const UPDATE_DATE_TIME = 'UPDATE_DATE_TIME'
export const UPDATE_STATS = 'UPDATE_STATS'

// Screens
export const UPDATE_SCREENS = 'UPDATE_SCREENS'
export const UPDATE_STREAM = 'UPDATE_STREAM'
export const SET_SEARCH_FILTER = 'SET_SEARCH_FILTER'
export const CLEAR_SEARCH_FILTER = 'CLEAR_SEARCH_FILTER'
export const UPDATE_REALTIME_AUDIENCE = 'UPDATE_REALTIME_AUDIENCE'
export const UPDATE_DATE_TIME_AUDIENCE = 'UPDATE_DATE_TIME_AUDIENCE'
export const UPDATE_SENDER_BOX_LOADING = 'UPDATE_SENDER_BOX_LOADING'
export const UPDATE_SENDER_BOX = 'UPDATE_SENDER_BOX'
