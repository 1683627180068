import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import IsMobile from '../components/IsMobile'
import { resizeChange } from '../store/isMobile'

const mapActionCreators = {
  resizeChange
}

const mapStateToProps = (state) => ({
  isMobile: state.isMobile
})

export default withRouter(connect(mapStateToProps, mapActionCreators)(IsMobile))
