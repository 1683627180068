import PropTypes from 'prop-types'
import React, { Component } from 'react'
import './User.scss'
import Edit from './Edit'
import View from './View'
import { EDIT_USER, VIEW_USER } from '../../../../../../../constants/acl'

export class User extends Component {

  componentWillMount () {
    console.log(this.props.myPermissions)
    this.props.getUser(this.props.match.params.id)
  }

  componentWillUnmount () {
    this.props.clearUser()
  }

  render () {
    const permissions = this.props.myPermissions
    const user = getUserFromParent(this.props) || {}
    const customProps = {
      ...this.props,
      user: { ...this.props.user, ...user },
      permissions: [...this.props.myPermissions]
    }
    if(!permissions){
      return null
    }
    return (
      <div>
        {permissions.includes(EDIT_USER)
          ? <Edit {... customProps} />
          : permissions.includes(VIEW_USER)
            ? <View {...this.props} />
            : null}
      </div>
    )
  }
}

// get user from route parent if available to increase performance
const getUserFromParent = (props) => {
  // get current user object by ID
  if (!props.users.length) return false
  return props.users.find(function (obj) {
    return obj.id === Number(this)
  }, props.match.params.id) || false
}

User.propTypes = {
  match: PropTypes.object.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    title:  PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
    groups: PropTypes.array.isRequired
  }),
  getUser: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  myPermissions: PropTypes.array.isRequired,
  clearUser: PropTypes.func
}

export default User
