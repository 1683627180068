import PropTypes from 'prop-types'
import React from 'react'
import {
  ScreenGridTile,
  ListItemWrapper,
  MobileListItem, ScreenDivider,
  ScreenCard,
  ScreenCardText
} from './StyledComponents'
import { withRouter } from 'react-router'
import { SCREENS } from '../../../constants/routes'

import Content from './ScreenContent'

export const Screen = (props) => {
  const { id, status, isMobile } = props
  const toScreen = () => {
    props.history.push(`/${SCREENS}/${id}`)
  }
  const remappedStatus = {
    incidents: status.incidents,
    status: status.statusString,
    statusString: status.statusString,
    value: status.status
  }
  const content = <Content {...props} status={remappedStatus} toScreen={toScreen} />

  return isMobile
    ? (
      <ListItemWrapper>
        <MobileListItem insetChildren={false} onTouchTap={toScreen}>
          {content}
        </MobileListItem>
        <ScreenDivider inset={false} />
      </ListItemWrapper>
    )
    : (
      <ScreenGridTile key={id}>
        <ScreenCard status={status}>
          <ScreenCardText>
            {content}
          </ScreenCardText>
        </ScreenCard>
      </ScreenGridTile>
    )
}

Screen.propTypes = {
  id: PropTypes.number.isRequired,
  isMobile: PropTypes.any.isRequired,
  status: PropTypes.shape({
    status: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    statusString: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.any
}

export default withRouter(Screen)
