import { fetchFromSlimAPI } from '../libs/api'
import * as actions from '../constants/actions'

// ------------------------------------
// Async Actions
// ------------------------------------

export const getUsers = () => {
  return (dispatch) => {
    dispatch(fetchFromSlimAPI('user/all', 'get', undefined, (success) => {
      if (success && !success.code) {
        dispatch(updateUsers([...success]))
        return success
      }
    }))
  }
}

export const deleteUser = (userID) => {
  return (dispatch) => {
    dispatch(fetchFromSlimAPI(`user/${userID}`, 'delete', undefined, (success) => {
      if (success && !success.code) {
        dispatch(userDeleted(userID))
      }
    }))
  }
}

// ------------------------------------
// Actions
// ------------------------------------

export function updateUsers (users = []) {
  return {
    type  : actions.PUT_USERS,
    users : users
  }
}

export function userDeleted (userID) {
  return {
    type  : actions.DELETE_USER,
    userID
  }
}

export function userAdded (user) {
  return {
    type  : actions.ADD_USER,
    user
  }
}

export function userChanged (user) {
  return {
    type  : actions.CHANGE_USER,
    user
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = { users: [] }
export default function usersReducer (state = initialState, action) {
  switch (action.type){
  case actions.PUT_USERS:
    return ({ ...state, users: action.users })

  case actions.DELETE_USER: {
    let users = state.users.filter((e) => e.id !== Number(this), action.userID)
    return ({ ...state, users })
  }

  case actions.ADD_USER:
    return ({ ...state, users: [...state.users, action.user] })

  case actions.CHANGE_USER: {
    let users = state.users.map(function (user) {
      if (user.id === action.user.id) {
        return { ...user, ...action.user }
      }
      return user
    })
    return ({ ...state, users })
  }
  default:
    return { ...state }
  }
}
