import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from "react-intl"
import messages from "../constants/messages"
import ScreenCard from './ScreenCard'
import * as dimensions from "../../../../../constants/dimensions"
import { Avatar } from "material-ui"
import { Panic } from "../../../../../constants/colors"

export class Stream extends Component {

  componentWillMount () {
    // Temporarly disabled this
    // this.props.getStream(this.props.match.params.screen)
  }

  _expandable (width) {
    return width < dimensions.SMALL_VIEWPORT
  }

  render () {
    const widthStyle = {
      width: '100%',
      marginTop: '16px'
    }
    return this.props.streamUrl.streamUrl && (
      <ScreenCard
        title={<FormattedMessage {...messages.cardTitleStream} />}
        expandable={this._expandable(this.props.windowWidth)}
        noPadding
        className={'stream'}
        avatar={<Avatar aria-label='icon' size={12} backgroundColor={Panic} />}
      >
        <div>
          <img
            style={widthStyle}
            id={"screen-stream"}
            src={this.props.streamUrl.streamUrl}
            alt={""}
          />
        </div>
      </ScreenCard>
    )
  }
}

Stream.propTypes = {
  streamUrl: PropTypes.any,
  getStream: PropTypes.func.isRequired,
  windowWidth: PropTypes.any,
  match: PropTypes.any
}

export default Stream
