import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'
/* eslint-disable */
const Icon = (props) => (
  <SvgIcon {...props}>

    <g>
      <g>
        <path
          fill='none'
          stroke={props.color}
          strokeLinejoin='round'
          strokeMiterlimit='10'
          d='M21.896,22.115l1-1.732l-1-0.577 c0.137-0.579,0.136-1.038,0-1.614l0.999-0.577l-1-1.732l-0.999,0.577c-0.391-0.369-0.869-0.64-1.397-0.799v-1.162h-2v1.162 c-0.529,0.159-1.006,0.43-1.397,0.799l-0.999-0.577l-1,1.732l0.999,0.577c-0.136,0.576-0.137,1.035,0,1.614l-1,0.577l1,1.732 l1-0.577c0.391,0.369,0.869,0.64,1.397,0.798v1.163h2v-1.163c0.528-0.158,1.006-0.429,1.397-0.798L21.896,22.115z' />
        <circle
          fill='none'
          stroke={props.color}
          strokeLinejoin='round'
          strokeMiterlimit='10'
          cx='18.499'
          cy='18.999'
          r='1.5' />
      </g>
      <path
        fill='none'
        stroke={props.color}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        d='M14.553,13.282 C13.776,13.025,13,12.749,12,12.429v-2.5c0,0,1.5-0.619,1.5-3c1,0,1-2.071,0-2.071c0-0.233,0.917-1.607,0.587-2.929 c-0.473-1.904-6.07-1.904-6.543,0C5.176,1.452,6.5,4.609,6.5,4.929c-0.5,0-0.5,2,0,2c0,2.381,1.5,3,1.5,3v2.5 c-2.5,1.056-6,1.756-6.413,3C1.114,16.857,1,19.5,1,19.5h10.5' />
    </g>

  </SvgIcon>
)
/* eslint-enable */
export default Icon
