import PropTypes from 'prop-types'
import React from 'react'
import Divider from 'material-ui/Divider'
import { ListItem } from 'material-ui/List'
import { Candela, White } from '../../../../../constants/colors'

const Styles = {
  listItem: {
    display: 'inline-block',
    maxWidth: '100%',
    width: '100%',
    textOverflow: 'ellipsis',
    whiteSpace: 'no-wrap',
    overflow: 'hidden',
    fontFamily: 'Didot'
  }
}

export const Role = (props) => {
  return (
    <div style={{ backgroundColor: White }}>
      <ListItem
        style={Styles.listItem}
        onClick={() => {
          const pathname = props.history.location.pathname
          props.history.push(`${pathname}/${props.id}`)
        }}
      >
        <div className='roles-wrapper'>
          <div className='roles-name'>{props.name}</div>
        </div>
      </ListItem>
      <Divider inset={false} style={{ backgroundColor: Candela }} />
    </div>
  )
}

Role.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  history: PropTypes.object
}

export default Role
