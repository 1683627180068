import { defineMessages } from 'react-intl'

export default defineMessages({
  company: {
    id: 'application.company',
    defaultMessage: 'Ocean'
  },
  title: {
    id: 'application.name',
    defaultMessage: 'Extended Screencontrol'
  },
  management: {
    id: 'Header.components.management',
    defaultMessage: 'Management'
  }
})
