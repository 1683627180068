import { defineMessages } from 'react-intl'

export default defineMessages({
  username: {
    id: 'Login.components.username',
    defaultMessage: 'Username'
  },
  password: {
    id: 'Login.components.password',
    defaultMessage: 'Password'
  },
  login: {
    id: 'Login.components.login',
    defaultMessage: 'Log in'
  },
  forgotPassword: {
    id: 'Login.components.forgotPassword',
    defaultMessage: 'Forgot password?'
  },
  company: {
    id: 'application.company',
    defaultMessage: 'Ocean Outdoor'
  },
  applicationName: {
    id: 'application.name',
    defaultMessage: 'Extended Screencontrol'
  },
  invalidEmail: {
    id: 'Login.components.invalidEmail',
    defaultMessage: 'email address is invalid'
  },
  loginFailed: {
    id: 'Login.error.login.failed',
    defaultMessage: 'Username or password invalid'
  },
  backToLogin: {
    id: 'Login.components.backToLogin',
    defaultMessage: 'Go back to login page'
  },
  requestPasswordReset: {
    id: 'Login.components.requestPasswordReset',
    defaultMessage: 'Request password reset'
  }

})
