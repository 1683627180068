import { fetchFromSlimAPI } from '../libs/api'
import { setErrorMessage, setSuccessMessage } from './notice'
import messages from '../constants/messages'
import * as actions from '../constants/actions'

// ------------------------------------
// Async Actions
// ------------------------------------

export const getRoles = () => {
  return (dispatch) => {
    dispatch(fetchFromSlimAPI('role/all', 'get', undefined, (success) => {
      if (success && !success.code) {
        dispatch(rolesChange([...success]))
      }
    }))
  }
}

export const deleteRole = (roleID) => {
  return (dispatch) => {
    // check if all fields are filled
    if (!roleID) {
      dispatch(setErrorMessage(messages.fieldMissing))
      return
    }
    dispatch(fetchFromSlimAPI(`role/${roleID}`, 'delete', undefined, (success) => {
      if (success && !success.code) {
        // should return empty response on success
        dispatch(removeRoleFromRoles(roleID))
        dispatch(setSuccessMessage(messages.didRemoveRole))
      }
    }))
  }
}

// ------------------------------------
// Actions
// ------------------------------------

export function rolesChange (roles = []) {
  return {
    type  : actions.UPDATE_ROLES,
    roles : roles
  }
}

export function updateRoleInRoles (role) {
  return {
    type  : actions.ROLES_UPDATE_ROLE,
    role
  }
}

export function addRoleToRoles (role) {
  return {
    type  : actions.ROLES_ADD_ROLE,
    role
  }
}

export function removeRoleFromRoles (id) {
  return {
    type  : actions.ROLES_DELETE_ROLE,
    id
  }
}

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = { roles: [] }
export default function rolesReducer (state = initialState, action) {
  switch (action.type){
  case actions.UPDATE_ROLES:
    return ({ ...state, roles: action.roles })

  case actions.ROLES_DELETE_ROLE: {
    let roles = state.roles.filter(function (role) {
      return role.id !== Number(action.id)
    })
    return ({
      ...state,
      roles
    })
  }

  case actions.ROLES_ADD_ROLE:
    return ({ ...state, roles: [...state.roles, action.role] })

  case actions.ROLES_UPDATE_ROLE: {
    let roles = state.roles.map(function (role) {
      if (role.id === action.role.id) {
        return action.role
      }
      return role
    })
    return ({
      ...state,
      roles
    })
  }

  default:
    return { ...state }

  }
}
