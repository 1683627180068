import { connect } from 'react-redux'
import { getRoles, deleteRole } from '../../../../../store/roles'
import { withRouter } from 'react-router'
/*  This is a container component. Notice it does not contain any JSX,
 nor does it import React. This component is **only** responsible for
 wiring in the actions and state necessary to render a presentational
 component - in this case, the roles:   */

import Roles from '../components/Roles'

/*  Object of action creators (can also be function that returns object).
 Keys will be passed as props to presentational components. Here we are
 implementing our wrapper around increment; the component doesn't care   */

const mapDispatchToProps = {
  getRoles,
  deleteRole
}

const mapStateToProps = (state) => ({
  roles : state.roles.roles
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Roles))
