import PropTypes from 'prop-types'
import React from 'react'
import { Button as RaisedButton } from '@material-ui/core'
import { DoveGray, Gray } from '../../constants/colors'
import GoToIcon from "../../assets/icons/go-to.svg"

export const Button = ({ type, value, disabled, label, onClick }) => {

  return (
    <React.Fragment>
      <RaisedButton
        fullWidth
        onClick={onClick || ''}
        type={type || 'submit'}
        value={value || 'submit'}
        disabled={disabled}
        disabledBackgroundColor={DoveGray}
        disabledLabelColor={Gray}
        variant={"text"}
        color={'default'}
        style={{ display: "flex", justifyContent: "flex-end" }}
      >{label}
        <span className='icon'>
          <img src={GoToIcon} alt={'right arrow to log in'} width={10} height={8} />
        </span>
      </RaisedButton>
    </React.Fragment>
  )
}

Button.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.any.isRequired,
  type: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ])
}

export default Button
