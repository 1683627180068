import Screen from '../components/Screen'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { getScreen, callSenderBoxSwitch } from '../../../../../store/screen'

/*  Object of action creators (can also be function that returns object).
 Keys will be passed as props to presentational components. Here we are
 implementing our wrapper around increment; the component doesn't care   */
const mapDispatchToProps = {
  getScreen,
  callSenderBoxSwitch
}

const mapStateToProps = (state) => {
  return ({
    random: Math.random(0, 999),
    screens: state.screens.screens,
    screen: state.screen.screen,
    senderBoxLoading: state.screen.senderBoxLoading
  })
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Screen))
