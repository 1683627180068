import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { TextField } from '@material-ui/core'
import LoginButton from '../../../containers/LoginButtonContainer'
import messages from '../../../components/messages'
import { FormattedMessage } from 'react-intl'
import * as Styles from '../../../constants/styles'
import { validateEmail, validatePassword } from '../../../../../utils/validation'
import ForgotPasswordButton from "../../../components/ForgotPasswordButton"
import * as routes from "../../../../../constants/routes"

export class LoginForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      password: '',
      isLoginButtonDisabled: true,
      emailValidationMessage: ''
    }
    this.handleUsernameChange = this.handleUsernameChange.bind(this)
    this.handlePasswordChange = this.handlePasswordChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentWillUnmount () {
    this.props.clearLoginError()
  }

  handlePasswordChange (evt) {
    const { value } = evt.target

    this.setState({ password: value })
    this.preemptiveFormValidation(this.props.username, value)
  }

  handleUsernameChange (evt) {
    const { value } = evt.target
    this.props.usernameChange(value)
    this.handleEmailValidation(value)
    this.preemptiveFormValidation(value, this.state.password)
  }

  handleEmailValidation (email) {
    this.setState({
      emailValidationMessage: validateEmail(email)
        ? ''
        : <FormattedMessage {...messages.invalidEmail} />
    })
  }

  preemptiveFormValidation (email = '', password = '') {
    const isFormValid = validateEmail(email) && validatePassword(password)
    this.setState({
      isLoginButtonDisabled: !isFormValid
    })
  }

  handleSubmit (evt) {
    evt.preventDefault()
    if (!this.props.fetching) this.props.login(this.props.username.trim(), this.state.password.trim())
  }

  render () {
    const currentLocation = this.props.history.location.pathname || ''
    const isLogin = currentLocation === routes.LOGIN
    const { error } = this.props
    const errorMessage = (typeof error === 'string') ? error : <FormattedMessage {...error} />
    const { emailValidationMessage, password, isLoginButtonDisabled } = this.state
    const { username } = this.props
    const buttonLink = isLogin ? routes.FORGOT : routes.LOGIN
    const buttonLinkText = isLogin ? messages.forgotPassword : messages.backToLogin

    return (
      <form onSubmit={this.handleSubmit}>
        <TextField
          label={<FormattedMessage {...messages.username} />}
          fullWidth
          name='username'
          value={username} onChange={this.handleUsernameChange}
          errorText={emailValidationMessage}
          errorStyle={Styles.warningLabelStyle}
          variant='filled'
        />
        <TextField
          label={<FormattedMessage {...messages.password} />}
          fullWidth
          name='password'
          type='password'
          value={password}
          onChange={this.handlePasswordChange}
          variant='filled'
          errorText={errorMessage}
        />
        <div style={{ display: "flex", marginTop: "15px" }}>
          <div style={{ flex: "1", display: "flex", alignItems: "center" }}>
            <ForgotPasswordButton
              onClick={() => this.props.history.push(buttonLink)}
              label={<FormattedMessage {...buttonLinkText} />}
            />
          </div>
          <div>
            <LoginButton disabled={this.props.fetching || isLoginButtonDisabled} />
          </div>
        </div>

      </form>
    )
  }
}

LoginForm.propTypes = {
  username: PropTypes.string.isRequired,
  fetching: PropTypes.any.isRequired,
  login: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  usernameChange: PropTypes.func.isRequired,
  clearLoginError: PropTypes.func.isRequired,
  history: PropTypes.object
}

export default LoginForm
