import { connect } from 'react-redux'
import { withRouter } from "react-router"
import ACL from '../components/ACL'

const mapStateToProps = (state) => ({

  permissions: state.me.permissions,
  isMobile: state.isMobile

})

export default withRouter(connect(mapStateToProps, null)(ACL))
