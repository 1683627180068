import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import React from 'react'
import { Background, TextBlack } from '../constants/colors'

export const Container = ({ isOnACL, loggedIn, children }) => {
  const backgroundColor = isOnACL ? Background : loggedIn ? Background : TextBlack
  return (
    <div className='container text-center' style={{ minHeight: '100vh', height: '100%', backgroundColor }}>
      {children}
    </div>
  )
}

Container.propTypes = {
  isOnACL: PropTypes.any.isRequired,
  loggedIn: PropTypes.any.isRequired,
  children: PropTypes.element.isRequired
}

const mapStateToProps = (state) => ({
  loggedIn: state.auth.authenticated
})

export default withRouter(connect(mapStateToProps, {})(Container))
