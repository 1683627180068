import { connect } from 'react-redux'
import { getStatsWithDates, setDateTimes, getRealTimeAudience } from '../../../../../store/screen'
import { withRouter } from 'react-router'

import DateTimeSelection from '../components/DateTime/DateTimeSelection'

const mapDispatchToProps = {
  getStatsWithDates,
  setDateTimes,
  getRealTimeAudience
}

const mapStateToProps = (state) => {
  return ({
    startDate: state.screen.dateTime.startDate,
    endDate: state.screen.dateTime.endDate,
    startTime: state.screen.dateTime.startTime,
    endTime: state.screen.dateTime.endTime
  })
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DateTimeSelection))
