import { defineMessages } from 'react-intl'

export default defineMessages({
  date: {
    id: 'Screens.component.date',
    defaultMessage: 'Date'
  },
  brightness: {
    id: 'Screens.component.brightness',
    defaultMessage: 'Brightness'
  },
  light: {
    id: 'Screens.component.light',
    defaultMessage: 'Light'
  },
  senderbox: {
    id: 'Screens.component.senderbox',
    defaultMessage: 'Usb senderbox switcher'
  },
  brightnessUnits: {
    id: 'Screens.component.brightness.units',
    defaultMessage: 'cd/m²'
  },
  peopleCount: {
    id: 'Screens.component.people.count',
    defaultMessage: 'Aantal mensen'
  },
  peopleAverageDuration: {
    id: 'Screens.component.people.average.duration',
    defaultMessage: 'Gemiddelde tijd stilstand'
  },
  lightUnits: {
    id: 'Screens.component.light.units',
    defaultMessage: 'klx'
  },
  brightnessLegend: {
    id: 'Screens.component.brightness.legend',
    defaultMessage: 'candela per m² (cd/m²)'
  },
  peopleCountUnits: {
    id: 'Screens.component.people.count.units',
    defaultMessage: 'people'
  },
  peopleAverageDurationUnits: {
    id: 'Screens.component.people.average.duration.units',
    defaultMessage: 'seconds'
  },
  lightLegend: {
    id: 'Screens.component.light.legend',
    defaultMessage: 'kilolux (klx)'
  },
  peopleCountLegend: {
    id: 'Screens.component.people.count.legend',
    defaultMessage: 'Amount of people'
  },
  peopleAverageDurationLegend: {
    id: 'Screens.component.people.average.duration.legend',
    defaultMessage: 'Average time idle'
  },
  filterLabel: {
    id: 'Screens.component.filter.label',
    defaultMessage: 'Filter last...'
  },
  cardTitleStats: {
    id: 'Screens.component.title.stats',
    defaultMessage: 'Light & Brightness'
  },
  cardTitleInfo: {
    id: 'Screens.component.title.info',
    defaultMessage: 'Screen Information'
  },
  cardTitleRealTimeAudience: {
    id: 'Screens.component.title.realtime.audience',
    defaultMessage: 'Real time audience count & average duration in front of screen'
  },
  cardTitleStatus: {
    id: 'Screens.component.title.status',
    defaultMessage: `Screen status: {count} {count, plural,
      one {notification}
      other {notifications}
    }`
  },
  cardTitleExtra: {
    id: 'Screens.component.title.extra',
    defaultMessage: 'Screen details'
  },
  cardTitleStream: {
    id: 'Screens.component.title.stream',
    defaultMessage: 'Screen stream'
  },
  notifications: {
    id: 'Screens.component.notifications',
    defaultMessage: `{count} {count, plural,
                      one {notification}
                      other {notifications}
                    }`
  },
  contactPerson: {
    id: 'Screens.component.contactPerson',
    defaultMessage: 'Contact'
  },
  document: {
    id: 'Screens.component.document',
    defaultMessage: 'Document'
  },
  id: {
    id: 'Screens.component.id',
    defaultMessage: 'ID'
  },
  city: {
    id: 'Screens.component.city',
    defaultMessage: 'City'
  },
  locationDescription: {
    id: 'Screens.component.locationDescription',
    defaultMessage: 'Location description'
  },
  realEstate: {
    id: 'Screens.component.realEstate',
    defaultMessage: 'RealEstate'
  },
  temp: {
    id: 'Screens.component.temp',
    defaultMessage: 'Temperature'
  },
  videoInput: {
    id: 'Screens.component.videoInput',
    defaultMessage: 'Video Input'
  },
  videoOutput: {
    id: 'Screens.component.videoOutput',
    defaultMessage: 'Video Output'
  },
  videoInputConnected: {
    id: 'Screens.component.videoInputConnected',
    defaultMessage: 'Video Input Connected'
  },
  videoOutputConnected: {
    id: 'Screens.component.videoOutputConnected',
    defaultMessage: 'Video Output Connected'
  },
  incidentLevel: {
    id: 'Screens.component.incidentLevel',
    defaultMessage: 'Level'
  },
  incidentRule: {
    id: 'Screens.component.incidentRule',
    defaultMessage: 'Rule'
  },
  incidentCount: {
    id: 'Screens.component.incidentCount',
    defaultMessage: 'Count'
  },
  incidentOccurrences: {
    id: 'Screens.component.occurrences',
    defaultMessage: 'Occurrences'
  },
  incidentFirstOccurrence: {
    id: 'Screens.component.firstOccurrence',
    defaultMessage: 'First occurrence'
  },
  incidentLastOccurrence: {
    id: 'Screens.component.lastOccurrence',
    defaultMessage: 'Last occurrence'
  },
  incidentAcknowledged: {
    id: 'Screens.component.incidentAcknowledged',
    defaultMessage: 'Acknowledged'
  },
  incidentAcknowledgedDate: {
    id: 'Screens.component.incidentAcknowledgedDate',
    defaultMessage: 'Acknowledged Date'
  },
  incidentAcknowledgedUser: {
    id: 'Screens.component.incidentAcknowledgedUser',
    defaultMessage: 'Acknowledged User'
  },
  frameRate: {
    id: 'Screens.component.framerate',
    defaultMessage: 'Framerate'
  },
  ledPitch: {
    id: 'Screens.component.led.pitch',
    defaultMessage: 'LED Pitch'
  },
  ledType: {
    id: 'Screens.component.led.type',
    defaultMessage: 'LED Type'
  },
  ledVendor: {
    id: 'Screens.component.led.vendor',
    defaultMessage: 'LED Vendor'
  },
  Resolution: {
    id: 'Screens.component.resolution',
    defaultMessage: 'Resolution'
  },
  resolutionHeight: {
    id: 'Screens.component.height',
    defaultMessage: 'Height'
  },
  resolutionWidth: {
    id: 'Screens.component.width',
    defaultMessage: 'Width'
  },
  brightnessPercentage: {
    id: 'Screens.component.brightnessPercentage',
    defaultMessage: 'Brightness Percentage'
  },
  cardTitleWattage: {
    id: 'Screens.component.title.cardTitleWattage',
    defaultMessage: 'Energy usage'
  },
  usagePerSquareMetres: {
    id: 'Screens.component.subtitle.usagePerSquareMetres',
    defaultMessage: 'Current usage per square metres:'
  },
  currentScreenUsage: {
    id: 'Screens.component.subtitle.currentScreenUsage',
    defaultMessage: 'Current screen usage:'
  },
  totalPowerLastDay: {
    id: 'Screens.component.subtitle.powerLastDay',
    defaultMessage: 'Power last 24 hours:'
  }
})
