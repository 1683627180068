import { defineMessages } from 'react-intl'

export default defineMessages({
  veryWeak: {
    id: 'PasswordStrength.Component.veryWeak',
    defaultMessage: 'very weak'
  },
  weak: {
    id: 'PasswordStrength.Component.weak',
    defaultMessage: 'weak'
  },
  ok: {
    id: 'PasswordStrength.Component.ok',
    defaultMessage: 'ok'
  },
  strong: {
    id: 'PasswordStrength.Component.strong',
    defaultMessage: 'strong'
  },
  veryStrong: {
    id: 'PasswordStrength.Component.veryStrong',
    defaultMessage: 'very strong'
  }
})
