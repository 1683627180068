import PropTypes from 'prop-types'
import React from 'react'
import User from './User'
import { Card, CardText } from 'material-ui/Card'
import CardTitle from '../CardTitle'

const styles = {
  chip: {
    margin: 4
  },
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '16px'
  }
}

export const ViewUsers = ({ title, users, className }) => (
  <Card className={className}>
    <CardTitle title={title} />
    <CardText>
      <div style={styles.wrapper}>
        {users.map((user, i) => <User style={styles.chip} key={i} id={user.id} username={user.username} />)}
      </div>
    </CardText>
  </Card>
)

ViewUsers.propTypes = {
  users: PropTypes.array.isRequired,
  className: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element])
}

export default ViewUsers
