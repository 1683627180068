
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { List } from 'material-ui/List'

export class ScrollList extends Component {
  constructor (props) {
    super(props)
    this.state = { width: '0', height: '0' }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  updateWindowDimensions () {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }
  componentDidMount () {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }
  componentWillUnmount () {
    // perform necessary cleanup
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  render () {
    const standAlonePadding = window.navigator.standalone === true ? 20 : 0
    const extraPadding = standAlonePadding + 120
    const calculatedHeight = this.state.height - extraPadding

    const styles = {
      list: {
        height: calculatedHeight,
        paddingTop: 0,
        overflowY: 'scroll', // makes scroll faster on iOS
        WebkitOverflowScrolling: 'touch', // makes scroll faster on iOS
        marginTop: 1 // required to avoid overflowing the bar
      }
    }
    return <List style={styles.list}>{this.props.children}</List>
  }
}

ScrollList.propTypes = {
  children: PropTypes.array
}

export default ScrollList
