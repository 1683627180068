/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 */
import { addLocaleData } from 'react-intl'
import nlLocaleData from 'react-intl/locale-data/nl'

import { DEFAULT_LOCALE } from './constants/intl'

import nlTranslationMessages from './translations/nl.js'

addLocaleData(nlLocaleData)

export const appLocales = [
  'nl'
]

export const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages = locale !== DEFAULT_LOCALE
    ? formatTranslationMessages(DEFAULT_LOCALE, nlTranslationMessages)
    : {}
  return Object.keys(messages).reduce((formattedMessages, key) => {
    const formattedMessage = !messages[key] && locale !== DEFAULT_LOCALE
      ? defaultFormattedMessages[key]
      : messages[key]
    return Object.assign(formattedMessages, { [key]: formattedMessage })
  }, {})
}

export const translationMessages = {
  nl: formatTranslationMessages('nl', nlTranslationMessages)
}
