import React from 'react'
import { Switch } from 'react-router-dom'
import AuthRoute from '../../../components/AuthRoute'
import Users from '../routes/Users'
import Groups from '../routes/Groups'
import Roles from '../routes/Roles'
import ACL from '../containers/ACLContainer'
import * as permissions from "../../../constants/acl"

export const AppLayout = () => {
  return(
    <ACL>
      <Switch>
        <AuthRoute path={'/management/users'} component={Users} permission={permissions.VIEW_USER} />
        <AuthRoute path={'/management/roles'} component={Roles} permission={permissions.VIEW_ROLE} />
        <AuthRoute path={'/management/groups'} component={Groups} permission={permissions.VIEW_GROUP} />
      </Switch>
    </ACL>
  )
}
export default AppLayout
