import PropTypes from 'prop-types'
import React from 'react'
import Divider from 'material-ui/Divider'
import { ListItem } from 'material-ui/List'
import { Candela, White } from '../../../../../constants/colors'

const Styles = {
  listItem: {
    display: 'inline-block',
    maxWidth: '100%',
    width: '100%',
    textOverflow: 'ellipsis',
    whiteSpace: 'no-wrap',
    overflow: 'hidden',
    fontFamily: 'Didot'
  }
}

export const User = (props) => {
  return (
    <div style={{ backgroundColor:  White }}>
      <ListItem
        onClick={e => {
          e.preventDefault()
          props.history.push(`${props.history.location.pathname}/${props.id}`)
        }}
        insetChildren={false}
        style={Styles.listItem}
      >
        <div className='users-flex-container'>
          <div className='users-username'>{props.name}</div>
          <div className='users-role'>{props.role}</div>
          <div className='users-title-company'>{props.title} bij {props.company}</div>
        </div>
      </ListItem>
      <Divider style={{ backgroundColor: Candela }} />
    </div>
  )
}

User.propTypes = {
  id: PropTypes.number.isRequired,
  company: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  role: PropTypes.string,
  history: PropTypes.object
}

export default User
