import PropTypes from 'prop-types'
import React from 'react'
import EditGroup from './EditGroup'
import EditableTable from '../../../../../../../components/EditableTable'
import messages from '../constants/messages.js'
import { FormattedMessage } from 'react-intl'

export const Edit = ({
  match,
  name,
  screens,
  users,
  editGroup,
  deleteGroup,
  removeScreenFromGroup,
  addScreenToGroup,
  removeUserFromGroup,
  addUserToGroup
}) => {
  const id = Number(match.params.id)
  const handleScreenClick = (screenId, active) => {
    if (active) {
      removeScreenFromGroup(id, screenId)
    } else {
      addScreenToGroup(id, screenId)
    }
  }

  const handleUserClick = (userId, active) => {
    if (active) {
      removeUserFromGroup(id, userId)
    } else {
      addUserToGroup(id, userId)
    }
  }

  const screenItems = screens.map((screen) => {
    return {
      id: screen.id,
      label: screen.screenName,
      active: screen.active,
      fields: [screen.screenName, screen.location.city, screen.location.description]
    }
  })

  const userItems = users.map((user) => {
    return {
      id: user.id,
      label: user.username,
      active: user.active,
      fields: [user.username, user.name, user.company, user.title]
    }
  })

  return (
    <div className='group-container'>
      <div className='group-item-header group-card-wrapper'>
        <EditGroup id={id} name={name} deleteGroup={deleteGroup} editGroup={editGroup} messages={messages} />
      </div>
      <div className='group-item group-card-wrapper group-screens'>
        <EditableTable
          items={screenItems}
          handleClick={handleScreenClick}
          title={<FormattedMessage {...messages.screens} />}
          columnHeaders={[
            <FormattedMessage {...messages.screensName} key={0} />,
            <FormattedMessage {...messages.city}  key={1} />,
            <FormattedMessage {...messages.description} key={2} />
          ]}
        />
      </div>
      <div className='group-item group-card-wrapper group-users'>
        <EditableTable
          items={userItems}
          columnHeaders={[
            <FormattedMessage {...messages.username} key={0} />,
            <FormattedMessage {...messages.name} key={1} />,
            <FormattedMessage {...messages.company} key={2} />,
            <FormattedMessage {...messages.title} key={3} />
          ]}
          handleClick={handleUserClick}
          title={<FormattedMessage {...messages.usersInGroup} />}
        />
      </div>
    </div>
  )
}

Edit.propTypes = {
  match: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  screens: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  editGroup: PropTypes.func.isRequired,
  addUserToGroup: PropTypes.func.isRequired,
  removeUserFromGroup: PropTypes.func.isRequired,
  addScreenToGroup: PropTypes.func.isRequired,
  removeScreenFromGroup: PropTypes.func.isRequired,
  deleteGroup: PropTypes.func.isRequired
}

export default Edit
