import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Alert from 'material-ui/svg-icons/alert/warning'
import { Panic } from "../../../../../../constants/colors"

const Wrapper = styled.div`
  background-color: ${Panic};
  color: white;
  cursor: ${props => props.isClickable ? 'pointer' : 'default'};

  &:hover {
    background-color: #ce0000;
  }

  transition: background-color 0.5s;
  user-select: none;
  border-radius: 26.5px;
  padding: 2px 6px 10px;
  margin: auto;
  text-align: center;
`
const StyledAlert = styled(Alert)`
  position: relative;
  top: 6px;
  left: -6px;
  color: white !important;
`
const PanicButtonText = styled.span`
  font-size: 16px;
  font-weight: bold;
`
export const Input = styled.input`
  height: 100%;
  box-sizing: border-box;
  width: calc((100% / 3 ) * 2);
  position: relative;
  text-align: center;
  top: -1px;
  left: 0;
`
const ConfirmButton = styled.button`
  height: 100%;
  box-sizing: border-box;
  width: calc((100% / 3));
  background-color: #d12900;
  border: 0;
  outline: none;
  color: white;
  text-transform: uppercase;
  font-size: 18px;
`

export class PanicButton extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isActive: false,
      isLoading: false,
      panicKey: '',
      panicButtonDone: false
    }
  }

    startPanicProcedure = () => {
      this.setState({
        isLoading: true
      })
      this.props.getUser().then(e => {
        this.props.callPanicButton(this.props.screenId, e.filter(e => e.username === this.props.username)[0].name)
          .then(e => {
            if (e.status === true) {
              this.setState({
                isActive: true,
                isLoading: false
              })
            } else {
              this.setState({
                isLoading: false,
                isActive: 'permError'
              })
            }
          }).catch(() => {
            this.setState({
              isLoading: false,
              isActive: 'permError'
            })
          })
      })
    }
    cancelPanicProcedure = () => {
      this.props.cancelPanicButton()
    }
    submitPanicKey = () => {
      this.props.submitPanicButton(this.props.screenId, this.state.panicKey).then(e => {
        if (e.status === true) {
          this.setState({
            panicButtonDone: true
          })
        }
      }).catch(() => {
        this.setState({
          panicButtonDone: 'codeError'
        })
        window.setTimeout(() => {
          this.setState({
            panicButtonDone: false
          })
        }, 2000)
      })
    }
    changeKey = (val) => {
      this.setState({
        panicKey: val
      })
    }

    render () {
      const { isActive, isLoading, panicButtonDone } = this.state
      if (this.props.screenStatus === 'disconnected') {
        return null
      }
      return (
        <React.Fragment>
          <div style={{ textAlign: 'center' }}>
            <h5> Scherm Stopzetten</h5>
            <p>Hiermee wordt de brightness van het scherm naar 0% gezet</p>
          </div>
          <Wrapper
            isClickable={!isActive && !isLoading}
            onClick={() => {
              if (!isActive && !isLoading) {
                this.startPanicProcedure()
              }
            }}
          >
            {panicButtonDone === 'codeError'
              ? <span>Verkeerde code</span>
              : panicButtonDone === true
                ? <span>Dit scherm is gedeactiveerd</span>
                : isActive === 'permError'
                  ? <span>Toegang geweigerd</span>
                  : isActive === true
                    ? (
                      <span>
                        <Input value={this.state.panicKey} onChange={e => this.changeKey(e.target.value)} />
                        <ConfirmButton onClick={() => this.submitPanicKey()}>Bevestig</ConfirmButton>
                      </span>
                    )
                    : (
                      <span>
                        <StyledAlert />
                        <PanicButtonText>{isLoading ? 'Loading...' : 'Panic Button'}</PanicButtonText>
                      </span>
                    )
            }
          </Wrapper>
        </React.Fragment>
      )
    }
}

PanicButton.propTypes = {
  callPanicButton: PropTypes.func,
  cancelPanicButton: PropTypes.func,
  submitPanicButton: PropTypes.func,
  username: PropTypes.string,
  screenId: PropTypes.any,
  getUser: PropTypes.func,
  screenStatus: PropTypes.func
}
export default PanicButton
