import { fetchFromSlimAPI } from '../libs/api'
import auth, { resetPasswordRequest, updatePassword } from '../utils/auth'
import { history } from './index'
import messages from '../constants/messages'
import { setErrorMessage, setSuccessMessage, setNotice } from './notice'
// ------------------------------------
// Constants
// ------------------------------------
export const AUTH_CHANGE = 'AUTH_CHANGE'
export const SENDING_REQUEST = 'SENDING_REQUEST'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const USERNAME_CHANGE = 'USERNAME_CHANGE'
// ------------------------------------
// Actions
// ------------------------------------

export function authChange (authenticated = false) {
  return {
    type: AUTH_CHANGE,
    authenticated
  }
}

export function usernameChange (username = '') {
  return {
    type: USERNAME_CHANGE,
    username
  }
}

export function setLoginError (error) {
  return {
    type: LOGIN_ERROR,
    error
  }
}

export function clearLoginError () {
  return {
    type: LOGIN_ERROR,
    error: ''
  }
}

// ------------------------------------
// API calls
// ------------------------------------
/**
 * Sets the requestSending state, which displays a loading indicator during requests
 * @param  {boolean} sending The new state the app should have
 * @return {object}          Formatted action for the reducer to handle
 */
export function sendingRequest (sending) {
  return { type: SENDING_REQUEST, sending }
}

export const loginToSlim = () => {
  return (dispatch) => {
    dispatch(fetchFromSlimAPI(
      `auth`,
      'get',
      null,
      (success, err) => {})
    )
      .then(() => {
        console.log('something I guess')
        // dispatch(getScreen(screenId))
      })
  }
}

export const login = (username = '', password = '') => {
  return (dispatch) => {
    // check if all fields are filled
    if (!username.length || !password.length) {
      dispatch(setErrorMessage(messages.fieldMissing))
      return
    }

    // Show the loading indicator, hide any errors
    dispatch(sendingRequest(true))
    usernameChange(username)

    return auth.login(username, password, (success, err) => {
      dispatch(sendingRequest(false))
      if (success !== false) {
        dispatch(clearLoginError())
        // dispatch(loginToSlim())
        dispatch(authChange(true))
        redirectAfterLogin()
      } else {
        dispatch(setLoginError(err))
      }
    })
  }
}
/**
 * Logs the current user out
 */
export const logout = (errorMessage = false) => {
  return (dispatch) => {
    dispatch(sendingRequest(true))
    return auth.logout((success, err) => {
      if (success === true) {
        dispatch(authChange(false))
        redirectToLogin()
        if (typeof errorMessage === 'string') dispatch(setErrorMessage(errorMessage))
      } else {
        dispatch(setErrorMessage(err))
      }
    })
  }
}

export const requestResetPassword = (email = '') => {
  return (dispatch) => {
    // check if all fields are filled
    if (!email.length) {
      dispatch(setErrorMessage(messages.fieldMissing))
      return
    }
    dispatch(sendingRequest(true))

    return resetPasswordRequest(email).then(() => {
      redirectToLogin()
      dispatch(setNotice(messages.passwordResetRequested, 'info'))
      return true
    }).catch(error => {
      dispatch(setErrorMessage(error))
    }).then(function () {
      dispatch(sendingRequest(false))
    })
  }
}

export const changePassword = (password, confirm, token) => {
  return (dispatch) => {
    // check if all fields are filled
    if (!password.length || !confirm.length) {
      dispatch(setErrorMessage(messages.fieldMissing))
      return
    }

    if (!password === confirm) {
      dispatch(setErrorMessage(messages.passwordsNotIdentical))
      return
    }

    return updatePassword(password, confirm, token).then(() => {
      dispatch(authChange(true))
      redirectAfterLogin()
      dispatch(setSuccessMessage(messages.passwordUpdated))
    }).catch(error => {
      dispatch(setErrorMessage(error))
    })
  }
}

export const redirectIfLoggedIn = () => {
  if (auth.loggedIn()) redirectAfterLogin()
}

export const redirectToLogin = () => {
  history.push("/")
}

export const redirectAfterLogin = () => {
  history.push("/screens")
}

export const getUserToken = () => {
  return localStorage.token || false
}
// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  authenticated: auth.loggedIn(),
  username: '',
  fetching: false,
  error: ''
}

export const authReducer = (state = initialState, action) => {

  switch (action.type) {

  case AUTH_CHANGE:
    return ({
      ...state,
      authenticated: action.authenticated,
      fetching: false
    })

  case SENDING_REQUEST:
    return { ...state, fetching: action.sending }

  case USERNAME_CHANGE:
    return { ...state, username: action.username }

  case LOGIN_ERROR:
    return { ...state, error: state.error }

  default: return state
  }

}

export default authReducer
