import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Card, CardText } from 'material-ui/Card'
import CardTitle from '../../../../../../../components/CardTitle'
import { TextBlack } from '../../../../../../../constants/colors'
import { container } from '../../../../../../../styles/layout'
import { FormattedMessage } from 'react-intl'
import Button from '../../../../../../../components/ResponsiveButton'
import messages from './messages'
import TextField from 'material-ui/TextField'
import './createGroup.scss'

export class Create extends Component {
  constructor (props) {
    super(props)
    this.state = {
      groupName: '',
      isSubmitButtonDisabled: true
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleInputChange (evt) {
    const target = evt.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
      isSubmitButtonDisabled: !value.trim().length
    })
  }

  handleSubmit (evt) {
    evt.preventDefault()
    this.props.createGroup(this.state.groupName.trim())
  }

  render () {
    const formBackgroundColor = this.props.isMobile ? '#fff' : null

    const form =
      <form
        onSubmit={this.handleSubmit}
        style={{ backgroundColor: formBackgroundColor }}
        autoComplete='off'
      >
        <div className='create-group-textfield-wrapper'>
          <TextField type='text' name='groupName' hintText={<FormattedMessage {...messages.groupName} />}
            fullWidth
            autoComplete='off'
            style={{ fontFamily: 'Interface' }}
            value={this.state.groupName} onChange={this.handleInputChange}
          />
        </div>
        <Button
          type='submit'
          value='submit'
          isMobile={this.props.isMobile}
          label={<FormattedMessage {...messages.submit} />}
          disabled={this.state.isSubmitButtonDisabled}
        />
      </form>

    const content = this.props.isMobile ? form : (
      <div className='padding-box'>
        <Card style={container} >
          <CardTitle
            titleStyle={{ color: TextBlack, fontWeight: 300, fontFamily: 'Interface' }}
            title={<FormattedMessage {...messages.title} />}
          />
          <CardText style={{ paddingTop: '0px', paddingBottom: '8px' }} >
            {form}
          </CardText>
        </Card>
      </div>)

    return (
      <div className={this.props.isMobile ? null : 'create-group-card-wrapper card-wrapper'}>
        {content}
      </div>
    )
  }
}

Create.propTypes = {
  isMobile: PropTypes.any.isRequired,
  createGroup : PropTypes.func.isRequired
}

export default Create
