import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Role from './Role'
import List from '../../../../../components/ScrollList'
import FloatingActionButton from '../../../../../components/FABwithPermissions'
import { Background } from '../../../../../constants/colors'
import { CREATE } from '../../../../../constants/routes'
import { CREATE_ROLE } from '../../../../../constants/acl'
import './Roles.scss'

const styles = {
  root: {
    backgroundColor: Background
  }
}

export class Roles extends Component {

  componentWillMount () {
    this.props.getRoles()
  }

  toCreateRole () {
    const pathname = this.props.history.location.pathname
    this.props.history.push(`${pathname}/${CREATE}`)
  }

  render () {
    const allRoles = (
      <div style={styles.root}>
        <List>
          {this.props.roles.map((role, i) =>
            <Role key={role.id} {...this.props} {...role} onDeleteRole={() => this.props.deleteRole(role.id)} />
          )}
        </List>
        <FloatingActionButton permission={CREATE_ROLE} onClick={() => this.toCreateRole()} />
      </div>
    )
    return (this.props.children) || allRoles
  }
}

Roles.propTypes = {
  roles: PropTypes.array.isRequired,
  getRoles: PropTypes.func.isRequired,
  children: PropTypes.element,
  deleteRole: PropTypes.func.isRequired,
  history: PropTypes.object
}

export default Roles
