import PropTypes from 'prop-types'
import React from 'react'
import messages from '../constants/messages.js'
import { FormattedMessage } from 'react-intl'
import ViewPermissions from './ViewRolePermissions'
import ViewUsers from '../../../../../../../components/ViewUsers'
import { filterActiveObjects } from '../utils/helper'
import TitleButtonCard from '../../../../../../../components/TitleButtonCard'

export const View = ({ name, permissions, users }) => {
  const activeUsers = filterActiveObjects(users)
  const activePermissions = filterActiveObjects(permissions)

  return (

    <div className='role-container'>
      <div className='role-item-header role-card-wrapper'>
        <TitleButtonCard title={name} />
      </div>
      {activeUsers.length ? (
        <div className='role-item role-card-wrapper role-users'>
          <ViewUsers users={activeUsers} title={<FormattedMessage {...messages.viewRoleUsersTitle} />} />
        </div>) : null }
      {activePermissions.length ? (
        <div className='role-item role-card-wrapper role-permissions'>
          <ViewPermissions permissions={activePermissions} messages={messages} />
        </div>) : null}
    </div>
  )
}

View.propTypes = {
  name: PropTypes.string.isRequired,
  permissions: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired
}

export default View
