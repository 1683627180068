import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ScreenView from '../../../components/ScreenContent'
import DataVis from '../containers/DataVisContainer'
import Status from '../containers/StatusContainer'
import Extra from '../containers/ExtraContainer'
import PanicButton from "./status/PanicButton"
import ScreenVis from './screen-vis/ScreenVis'
import ScreenCard from './ScreenCard'
import { FormattedMessage } from 'react-intl'
import DateTimeSelection from '../containers/DateTimeSelectionContainer'
import messages from '../constants/messages'
import * as dimensions from '../../../../../constants/dimensions'
import Stream from '../containers/StreamContainer'
import { Background500 } from "../../../../../constants/colors"
import SenderBox from './SenderBox'

const styles = {
  alternateRow: {
    backgroundColor: Background500,
  },
}

const powerToFixed = (data) => {
  if (!data) {
    return "0.00"
  }
  return data.toFixed(2)
}
const Wattage = props => {
  const {
    func,
    power,
    powerLast24Hours,
    windowWidth,
    label1,
    label2,
    label3
  } = props
  if (!power) {
    return null
  }
  return (
    <ScreenCard
      title={<FormattedMessage {...messages.cardTitleWattage} />}
      expandable={func(windowWidth)}
    >
      <div className='table'>
        <div className='table-row'>
          <div style={styles.alternateRow}>
            {label1}
            <b>{`${powerToFixed(power.powerUsagePerSquareMeter)}W/m2`}</b>
          </div>
          <div style={styles.alternateRow}>
            {label2}
            <b>{`${powerToFixed(power.totalPowerUsage)}W`}</b>
          </div>
          <div>
            {label3}
            <b>{`${powerToFixed((powerLast24Hours / 1000))}kWh`}</b>
          </div>
        </div>
      </div>
    </ScreenCard>
  )
}
Wattage.propTypes = {
  power: PropTypes.object,
  powerLast24Hours: PropTypes.any,
  func: PropTypes.func,
  windowWidth: PropTypes.any,
  label1: PropTypes.string,
  label2: PropTypes.string,
  label3: PropTypes.string
}

export class Screen extends Component {
  constructor (props) {
    super(props)
    this.state = { windowWidth: '0' }
    this._updateWindowDimensions = this._updateWindowDimensions.bind(this)
    this.senderBoxHandleClick = this.senderBoxHandleClick.bind(this)
  }

  componentDidMount () {
    this._updateWindowDimensions()
    window.addEventListener('resize', this._updateWindowDimensions)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this._updateWindowDimensions)
  }

  componentWillMount () {
    this.props.getScreen(this.props.match.params.screen)
  }

  _updateWindowDimensions () {
    this.setState({ windowWidth: window.innerWidth })
  }

  _numberOfColumns (width) {
    if (width >= dimensions.MEDIUM_VIEWPORT) {
      return 3
    } else if (width >= dimensions.SMALL_VIEWPORT) {
      return 2
    }
    return 1
  }

  _expandable (width) {
    return width < dimensions.SMALL_VIEWPORT
  }

  senderBoxHandleClick () {
    const { match, callSenderBoxSwitch } = this.props
    callSenderBoxSwitch(match.params.screen)
  }

  displaySenderbox (switcherStatus) {
    return switcherStatus === 'Screen Control' || switcherStatus === 'External Device'
  }

  render () {
    const { screen, senderBoxLoading } = this.props
    const { db } = screen
    const { power, powerLast24Hours } = screen
    let screenVisual = null

    if (screen.document) {
      const { height, width } = screen.document.resolution
      const pitch = screen.document.led.pitch
      screenVisual = height && width && pitch ? <ScreenVis {...{ height, width, pitch }} /> : null
    }

    const { windowWidth } = this.state
    const incidentsCount = screen.status.incidents.length
    const isMobile = windowWidth <= dimensions.MOBILE_WIDTH

    const screenStatus = screen.status.statusString
    return (
      <main className='screen'>
        <div className='screen-detail'>
          <div className='screen-detail-inner'>
            <div className='column'>
              {/* Name and location */}
              <ScreenCard>
                <ScreenView {...screen} altInfo={this.props.screens.filter(c => c.id === screen.id)} />
              </ScreenCard>
              {/* Live screen */}
              <Stream />
              {/* Stop screen and panic button  */}
              <ScreenCard>
                <PanicButton screenId={screen.id} screenStatus={screenStatus} />
              </ScreenCard>
            </div>
            <div className='column'>
              {/* Light intensity */}
              <ScreenCard
                title={<FormattedMessage {...messages.cardTitleStats} />}
                expandable={this._expandable(windowWidth)}
              >
                <DataVis id={Number(this.props.match.params.screen)} isMobile={isMobile} />
                <DateTimeSelection db={db} id={Number(this.props.match.params.screen)} />
              </ScreenCard>
              {/* Energy use*/}
              <Wattage
                func={this._expandable}
                windowWidth={windowWidth}
                power={power}
                powerLast24Hours={powerLast24Hours}
                label1={<FormattedMessage {...messages.usagePerSquareMetres} />}
                label2={<FormattedMessage {...messages.currentScreenUsage} />}
                label3={<FormattedMessage {...messages.totalPowerLastDay} />}
              />
              {/* Screen info */}
              <ScreenCard
                title={<FormattedMessage {...messages.cardTitleInfo} />}
              >
                {screenVisual}
              </ScreenCard>
            </div>
            <div className='column'>
              {/* Screen details */}
              <ScreenCard
                title={<FormattedMessage {...messages.cardTitleExtra} />}
                expandable={this._expandable(windowWidth)}
                noPadding
              >
                <Extra brightness={this.props.screen.brightness} />
              </ScreenCard>
            </div>

            {this.displaySenderbox(screen.switcherOutput) ?
              <div className='column'>
                <SenderBox
                  switcherOutput={screen.switcherOutput}
                  senderBoxHandleClick={this.senderBoxHandleClick}
                  senderBoxLoading={senderBoxLoading}
                />
              </div> : null
            }
          </div>
          {/* Screen status with amount of incidents */}
          {incidentsCount
            ? (
              <ScreenCard
                visible={!!incidentsCount}
                title={
                  <FormattedMessage {...{
                    ...messages.cardTitleStatus,
                    values: { count: incidentsCount || '0' }
                  }}
                  />
                }
                expandable={!!incidentsCount && this._expandable(windowWidth)}
              >
                {incidentsCount && <Status screenId={this.props.match.params.screen} />}
              </ScreenCard>
            )
            :
            []
          }
        </div>
      </main>
    )
  }
}

Screen.propTypes = {
  match: PropTypes.object,
  screen: PropTypes.any,
  screens: PropTypes.any,
  senderBoxLoading: PropTypes.bool,
  getScreen: PropTypes.func.isRequired,
  callSenderBoxSwitch: PropTypes.func.isRequired
}

export default Screen
