import { fetchFromSlimAPI } from '../libs/api'
import { setErrorMessage, setSuccessMessage } from './notice'
import messages from '../constants/messages'
import * as actions from '../constants/actions'
import { history } from './index.js'
import {
  updateGroupInGroups,
  removeGroupFromGroups,
  addGroupToGroups
} from './groups'
import mapActiveObjects from "../routes/ACL/routes/Groups/routes/Group/utils/helper"
import * as routes from "../constants/routes"

// ------------------------------------
// Actions Async
// ------------------------------------

export const getGroup = (groupID) => {
  return (dispatch) => {
    dispatch(fetchFromSlimAPI(`group/${groupID}`, 'get', undefined, (success) => {
      if (success && !success.code) {
        // should return { id:string, name:string, screens:array, users:array }
        let { id, name, screens, users } = success
        dispatch(updateGroup({ id: Number(id), name }))
        dispatch(mapActiveUsers([...users]))
        dispatch(mapActiveScreens([...screens]))
      }
    }))
  }
}

export const editGroup = (groupID, groupname) => {
  return (dispatch) => {
    // check if all fields are filled
    if (!groupname.length) {
      dispatch(setErrorMessage(messages.fieldMissing))
      return
    }
    let body = JSON.stringify({ id: groupID, name: groupname })
    dispatch(fetchFromSlimAPI(`group/${groupID}`, 'PATCH', body, (success) => {
      if (success && !success.code) {
        // should return { id:string, name:string }
        let { id, name } = success
        let group = { id: Number(id), name }
        dispatch(updateGroup(group))
        dispatch(updateGroupInGroups(group))
        dispatch(setSuccessMessage(messages.didEditGroupName))
      }
    }))
  }
}

export const deleteGroup = (groupID) => {
  return (dispatch) => {
    // check if all fields are filled
    if (!groupID) {
      dispatch(setErrorMessage(messages.fieldMissing))
      return
    }
    dispatch(fetchFromSlimAPI(`group/${groupID}`, 'DELETE', undefined, (success) => {
      if (success && !success.code) {
        dispatch(removeGroupFromGroups(groupID))
        dispatch(setSuccessMessage(messages.didRemoveGroup))
        history.push('/groups')
      }
    }))
  }
}

/**
 * Get all screens
 * @returns {function(*)}
 */
export const getAllScreens = () => {
  return (dispatch) => {
    return dispatch(fetchFromSlimAPI(`screen/screens/group`, 'get', undefined, (success) => {
      if (success && !success.code) {
        // should return [{ id:number, location:number, screenName:string, status:object, techName:string }]
      }
    }))
  }
}

/**
 * Combines active screens with all screens and sets an 'active' property
 * @param activeScreens {array} with screen ids eg. [1, 2, 3]
 * @returns {function(*)}
 */
export const mapActiveScreens = (activeScreens) => {
  return (dispatch) => {
    let activeScreensObjectsArr = activeScreens.map(function (id) {
      return { id }
    })
    let fetchAllScreens = dispatch(getAllScreens())
    fetchAllScreens.then(allScreens => {
      // should return [{ id:number, location:number, screenName:string, status:object, techName:string }]
      let mapped = mapActiveObjects(allScreens, activeScreensObjectsArr)
      dispatch(updateGroupScreens(mapped))
    })
  }
}

export const addScreenToGroup = (groupID, screenId) => {
  return (dispatch) => {
    // check if all fields are filled
    if (!screenId || !groupID) {
      return
    }
    let body = JSON.stringify({ screenId })
    dispatch(fetchFromSlimAPI(`group/${groupID}/screen`, 'post', body, (success) => {
      if (success && !success.code) {
        // should return empty response on success
        dispatch(addGroupScreen(Number(screenId)))
        dispatch(setSuccessMessage(messages.didAddScreenToGroup))
      }
    }))
  }
}

export const removeScreenFromGroup = (groupID, screenId) => {
  return (dispatch) => {
    // check if all fields are filled
    if (!screenId || !groupID) {
      return
    }
    let body = JSON.stringify({ screenId })
    dispatch(fetchFromSlimAPI(`group/${groupID}/screen`, 'delete', body, (success) => {
      if (success && !success.code) {
        // should return empty response on success
        dispatch(removeGroupScreen(Number(screenId)))
        dispatch(setSuccessMessage(messages.didRemoveScreenFromGroup))
      }
    }))
  }
}

export const createGroup = (groupName) => {
  return (dispatch) => {
    // check if all fields are filled
    if (!groupName.length) {
      dispatch(setErrorMessage(messages.fieldMissing))
      return
    }
    let body = JSON.stringify({ name: groupName })
    dispatch(fetchFromSlimAPI('group/create', 'post', body, (success) => {
      if (success.id) {
        // redirect to groups
        dispatch(addGroupToGroups({ id: success.id, name: groupName }))
        history.push(`/${routes.ACL}/${routes.GROUPS}`)
        dispatch(setSuccessMessage(messages.didCreateGroup))
      } else {
        if (success.code === 400) dispatch(setErrorMessage(messages.groupNameTaken))
        else dispatch(setErrorMessage(messages.generalError))
      }
    }))
  }
}

/**
 * Combines active users with all users and sets an 'active' property on each user object
 * @param activeUsers {array} with users {object}s eg. [{id: 1, username: 'admin'}]
 * @returns {function(*)}
 */
export const mapActiveUsers = (activeUsers) => {
  return (dispatch) => {
    let fetchAllUsers = dispatch(getUsers())
    fetchAllUsers.then(allUsers => {
      // should return [{ id:number, location:number, screenName:string, status:object, techName:string }]
      let mapped = mapActiveObjects(allUsers, activeUsers)
      dispatch(updateGroupUsers(mapped))
    })
  }
}

/**
 * Fetch all users
 * @returns {function(*)}
 */
export const getUsers = () => {
  return (dispatch) => {
    return dispatch(fetchFromSlimAPI('user/all', 'get', undefined, (success, err) => {
      if (!success) {
        console.log(err)
      }
    }))
  }
}

export const addUserToGroup = (groupID, userId) => {
  return (dispatch) => {
    // check empty values
    if (!userId || !groupID) return
    let body = JSON.stringify({ userId })
    dispatch(fetchFromSlimAPI(`group/${groupID}/user`, 'post', body, (success) => {
      if (success && !success.code) {
        // should return empty response on success
        dispatch(addGroupUsers(Number(userId)))
        dispatch(setSuccessMessage(messages.didAddUserToGroup))
      }
    }))
  }
}

export const removeUserFromGroup = (groupID, userId) => {
  return (dispatch) => {
    // check empty values
    if (!userId || !groupID) return
    let body = JSON.stringify({ userId })
    dispatch(fetchFromSlimAPI(`group/${groupID}/user`, 'delete', body, (success) => {
      if (success && !success.code) {
        // should return empty response on success
        dispatch(removeGroupUsers(Number(userId)))
        dispatch(setSuccessMessage(messages.didRemoveUserFromGroup))
      }
    }))
  }
}
// ------------------------------------
// Actions
// ------------------------------------

export function updateGroup (group) {
  return {
    type: actions.UPDATE_GROUP,
    group
  }
}

export function updateGroupScreens (screens) {
  return {
    type  : actions.UPDATE_GROUP_SCREENS,
    screens
  }
}

export function addGroupScreen (id) {
  return {
    type  : actions.ADD_GROUP_SCREEN,
    id
  }
}

export function removeGroupScreen (id) {
  return {
    type  : actions.REMOVE_GROUP_SCREEN,
    id
  }
}

export function updateGroupUsers (users) {
  return {
    type  : actions.UPDATE_GROUP_USERS,
    users
  }
}

export function addGroupUsers (id) {
  return {
    type  : actions.ADD_GROUP_USER,
    id
  }
}

export function removeGroupUsers (id) {
  return {
    type  : actions.REMOVE_GROUP_USER,
    id
  }
}

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  group: {
    name: "",
    id: 0
  },
  screens: [],
  users: []
}
export default function groupReducer (state = initialState, action) {
  switch (action.type){
  case actions.UPDATE_GROUP:
    return { ...state, group: action.group }

  //Screens actions
  case actions.UPDATE_GROUP_SCREENS:
    return { ...state, screens: action.screens }

  case actions.ADD_GROUP_SCREEN:
    return {
      ...state,
      screens: state.screens.map(function (screen) {

        if (screen.id !== Number(this)) return screen
        return { ...screen, active: true }
      }, action.id)
    }

  case actions.REMOVE_GROUP_SCREEN:
    return {
      ...state,
      screens: state.screens.map(function (screen) {
        if (screen.id !== Number(this)) return screen
        return { ...screen, active: false }
      }, action.id)
    }

  //Users actions
  case actions.UPDATE_GROUP_USERS:
    return { ...state, users: action.users }

  case actions.ADD_GROUP_USER:
    return {
      ...state,
      users: state.users.map(function (user) {
        if (user.id !== Number(this)) return user
        return { ...user, active: true }
      }, action.id)
    }

  case actions.REMOVE_GROUP_USER:
    return {
      ...state,
      users: state.users.map(function (user) {
        if (user.id !== Number(this)) return user
        return { ...user, active: false }
      }, action.id)
    }

  default:
    return { ...state }
  }
}
