import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Card, CardText } from 'material-ui/Card'
import CardTitle from '../CardTitle'
import TextField from 'material-ui/TextField'
import { Table, TableBody, TableRow, TableRowColumn } from 'material-ui/Table'
import './edit.scss'
import Header from './Header'

const styles = {
  idColumn: { width: 20 }
}

export class Edit extends Component {

  constructor (props) {
    super(props)
    this.state = {
      filterText: ''
    }
  }

  handleUpdateInput = (event) => {
    this.setState({
      filterText: event.target.value.toLowerCase()
    })
  }

  filterWithFilterText = (element) => {
    return this.state.filterText.length ? element.label.toLowerCase().includes(this.state.filterText) : true
  }

  handleClick = (rowId) => {
    const items = this.props.items.filter(this.filterWithFilterText)
    const element = items[rowId]
    this.props.handleClick(element.id, element.active)
  }

  /*
   * wrapping TableRow or other Table components in a custom component breaks table functionality,
   * ie: it breaks the toggle box
   * suggestions in https://github.com/callemall/material-ui/issues/4524 not working
   */

  render () {
    return (
      <Card>
        <CardTitle title={this.props.title} style={{ fontFamily: 'Interface' }} />
        <CardText>
          <TextField
            style={{ fontFamily: 'Interface' }}
            fullWidth
            hintText={this.props.columnHeader}
            onChange={this.handleUpdateInput}
          />
          <Table multiSelectable onCellClick={((rowId) => this.handleClick(rowId))}>
            <Header {...{ ...this.props, ...styles.idColumn }} />
            <TableBody displayRowCheckbox deselectOnClickaway={false} stripedRows={false}>
              {this.props.items
                .filter(this.filterWithFilterText)
                .map((element, i) =>
                  (
                    <TableRow
                      key={i}
                      id={element.id}
                      selected={element.active}
                      style={{ fontFamily: 'Interface' }}
                    >
                      <TableRowColumn style={styles.idColumn} className='edit-table-id-column'>
                        {element.id}
                      </TableRowColumn>
                      <TableRowColumn>{element.label}</TableRowColumn>
                      {this.props.secondColumnEnabled
                        ? (
                          <TableRowColumn className={`edit-table-secondary-column edit-table-column-${i}`}>
                            {element.second}
                          </TableRowColumn>
                        )
                        : null}
                    </TableRow>
                  )
                )}
            </TableBody>
          </Table>
        </CardText>
      </Card>
    )
  }

}

// handleClick returns an id and active state from the items array when row is toggled
Edit.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
      active: PropTypes.any.isRequired,
      second: PropTypes.string,
      secondColumnEnabled: PropTypes.any
    })
  ),
  secondColumnEnabled: PropTypes.any,
  handleClick: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  columnHeader: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
}

export default Edit
