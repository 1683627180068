import PropTypes from 'prop-types'
import React from 'react'
import ViewScreens from './ViewGroupScreens'
import ViewUsers from '../../../../../../../components/ViewUsers'
import { filterActiveObjects } from '../utils/helper'
import { FormattedMessage } from 'react-intl'
import { Card } from 'material-ui/Card'
import CardTitle from '../../../../../../../components/CardTitle'

export const View = ({ name, screens, users, messages }) => {
  const activeUsers = filterActiveObjects(users)
  const activeScreens = filterActiveObjects(screens)

  return (
    <div className='group-container'>
      <div className='group-item-header group-card-wrapper'>
        <Card>
          <CardTitle title={name} />
        </Card>
      </div>
      {activeUsers.length ? (
        <div className='group-item group-card-wrapper'>
          <ViewUsers users={activeUsers} title={<FormattedMessage {...messages.usersInGroup} />} />
        </div>
      ) : null }
      {activeScreens.length ? (
        <div className='group-item-header group-card-wrapper'>
          <ViewScreens
            screens={activeScreens}
            title={<FormattedMessage {...messages.screens} />}
            nameLabel={<FormattedMessage {...messages.screensName} />}
            techNameLabel={<FormattedMessage {...messages.techName} />}
          />
        </div>) : null }

    </div>
  )
}

View.propTypes = {
  name: PropTypes.string.isRequired,
  screens: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  messages: PropTypes.object.isRequired
}

export default View
