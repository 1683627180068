import React from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'material-ui/DatePicker'
import TimePicker from 'material-ui/TimePicker'
import areIntlLocalesSupported from 'intl-locales-supported'
import moment from 'moment'

let DateTimeFormat

/**
 * Use the native Intl.DateTimeFormat if available, or a polyfill if not.
 */
if (areIntlLocalesSupported(['fr', 'fa-IR'])) {
  DateTimeFormat = global.Intl.DateTimeFormat
} else {
  const IntlPolyfill = require('intl')
  DateTimeFormat = IntlPolyfill.DateTimeFormat
  require('intl/locale-data/jsonp/nl')
}

const DateTimePicker = (props) => {
  const date = moment(props.date, 'YYYY-MM-DD').toDate()
  const time = moment(`${props.date} ${props.time}`, 'YYYY-MM-DD HH-mm-ss').toDate()
  return (
    <React.Fragment>
      <DatePicker
        className='date'
        textFieldStyle={{ fontFamily: 'InterFace' }}
        defaultDate={date}
        maxDate={new Date()}
        DateTimeFormat={DateTimeFormat}
        locale='nl'
        floatingLabelText={props.dateFloatingLabelText}
        onChange={(_, date) => {
          const aMoment = moment(date)
          const value = aMoment.format('YYYY-MM-DD')
          props.dateDidChange(value)
        }}
      />
      <TimePicker
        className='time'
        textFieldStyle={{ fontFamily: 'InterFace' }}
        floatingLabelText={props.timeFloatingLabelText}
        defaultTime={time}
        onChange={(_, date) => {
          const aMoment = moment(date)
          const value = aMoment.format('HH-mm-ss')
          props.timeDidChange(value)
        }}
        format='24hr'
      />
    </React.Fragment>)
}

export default DateTimePicker

DateTimePicker.propTypes = {
  dateFloatingLabelText: PropTypes.string.isRequired,
  timeFloatingLabelText: PropTypes.string.isRequired,
  dateDidChange: PropTypes.func.isRequired,
  timeDidChange: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired
}
