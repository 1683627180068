import { connect } from 'react-redux'
import { editUser, getUser, clearUser, deleteUser } from '../../../../../../../store/user'
import { withRouter } from "react-router"
/*  This is a container component. Notice it does not contain any JSX,
 nor does it import React. This component is **only** responsible for
 wiring in the actions and state necessary to render a presentational
 component - in this case, the user:   */

import User from '../components/User'

/*  Object of action creators (can also be function that returns object).
 Keys will be passed as props to presentational components. Here we are
 implementing our wrapper around increment; the component doesn't care   */

const mapDispatchToProps = {
  editUser,
  getUser,
  clearUser,
  deleteUser
}

const mapStateToProps = (state) => ({
  users: state.users.users,
  user: state.user,
  myPermissions: state.me.permissions,
  isMobile: state.isMobile
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(User))
