import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import Me from '../components/Me/Me'
import { getMe } from '../store/me'

const mapActionCreators = {
  getMe
}

const mapStateToProps = (state) => ({
  permissions: state.me.permissions,
  loggedIn: state.auth.authenticated,
  myRole: state.auth.role
})

export default withRouter(connect(mapStateToProps, mapActionCreators)(Me))
