/**
 * Check if a string contains another string (case insensitive compare)
 * @param str
 * @param query
 * @returns {boolean}
 */
export const strIncludes = (str, query = '') => {
  if (typeof str !== 'string') return false
  return str.toLowerCase().indexOf(query.toLowerCase()) !== -1
}
