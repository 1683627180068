import { API_REQUEST_OBJECT, SLIM_BASE_URL } from '../constants/api'
import messages from '../constants/messages'

let localStorage = global.window.localStorage

/**
 * Authentication lib
 * @type {Object}
 */
const auth = {
  /**
   * Logs a user in
   * @param  {string}   username The username of the user
   * @param  {string}   password The password of the user
   * @param  {Function} callback Called after a user was logged in on the remote server
   */
  login (username, password, callback) {
    // If there is a token in the localStorage, the user already is
    // authenticated
    if (this.loggedIn()) {
      callback(true)
      return
    }
    // Post request
    return fetch(`${SLIM_BASE_URL}auth/login`, {
      ...API_REQUEST_OBJECT,
      body: JSON.stringify({ username: username, password: password }),
      method: 'post'
    })
      .then(data => data.json().then(json => {
        if (json.token) {
          // If login was successful, set the token in local storage
          localStorage.token = json.token
          callback(json)
        } else {
          // If there was a problem authenticating the user, show an error on the form
          callback(false, messages.userOrPasswordInvalid)
        }
      })).catch(error => {
        console.error(error)
        callback(false, messages.generalError)
      })
  },
  /**
   * Logs the current user out
   */
  logout (callback) {
    localStorage.removeItem('token')
    return callback(true)
  },
  /**
   * Checks if anybody is logged in
   * @return {boolean} True if there is a logged in user, false if there isn't
   */
  loggedIn () {
    return !!localStorage.token
  },
  /**
   * Retrieve token to send it back to the server as the HTTP authentication header in any authenticated requests
   * @returns {*|string|boolean}
   */
  getUserToken () {
    return localStorage.token || false
  }
}

export const resetPasswordRequest = (username) => {
  return fetch(`${SLIM_BASE_URL}auth/password/request`, {
    ...API_REQUEST_OBJECT,
    method: 'post',
    body: JSON.stringify({ username })
  }).then(() => {
    // Valid username/email or not, the server will always respond with a 204
    return true
  }).catch(() => {
    return messages.generalError
  })
}

export const updatePassword = (password, confirmPassword, token) => {
  return fetch(`${SLIM_BASE_URL}auth/password/reset`, {
    ...API_REQUEST_OBJECT,
    method: 'post',
    body: JSON.stringify({ password, confirmPassword, token })
  }).then(data => data.json()
  ).then(json => {
    if (json.token) {
      // If login was successful, set the token in local storage
      localStorage.token = json.token
      return json.token
    } else {
      // todo: are there any other types of errors?
      return Promise.reject(messages.resetExpired)
    }
  }).catch((reason) => {
    return reason || messages.generalError
  })
}

export default auth
