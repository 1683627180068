import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'Users.components.Createuser.title',
    defaultMessage: 'Create new user'
  },
  usernameFloatingLabelText: {
    id: 'Users.components.Createuser.usernameFloatingLabelText',
    defaultMessage: 'username'
  },
  passwordFloatingLabelText: {
    id: 'Users.components.Createuser.passwordFloatingLabelText',
    defaultMessage: 'password'
  },
  companyFloatingLabelText: {
    id: 'Users.components.Createuser.company',
    defaultMessage: 'company'
  },
  apiTokenFloatingLabelText: {
    id: 'Users.components.Createuser.api_token',
    defaultMessage: 'api token'
  },
  titleFloatingLabelText: {
    id: 'Users.components.Createuser.titleFloatingLabelText',
    defaultMessage: 'title'
  },
  nameFloatingLabelText: {
    id: 'Users.components.Createuser.name',
    defaultMessage: 'name'
  },
  submit: {
    id: 'Users.components.Createuser.submit',
    defaultMessage: 'Create user'
  },
  invalidEmail: {
    id: 'Users.components.invalidEmail',
    defaultMessage: 'Email is invalid'
  }

})
