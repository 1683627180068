import PropTypes from 'prop-types'
import React from 'react'
import { TableHeader, TableHeaderColumn, TableRow } from 'material-ui/Table'

class Header extends TableHeader {
  render () {
    return (
      <TableHeader displaySelectAll enableSelectAll={false} >
        <TableRow style={{ fontFamily: 'Interface' }}>
          <TableHeaderColumn style={this.props.styles} className='edit-table-id-column'>ID</TableHeaderColumn>
          <TableHeaderColumn>{this.props.columnHeader}</TableHeaderColumn>
          {this.props.secondColumnEnabled
            ? <TableHeaderColumn className='edit-table-secondary-column'>{this.props.secondHeader}</TableHeaderColumn>
            : null}
        </TableRow>
      </TableHeader>
    )
  }
}

Header.propTypes = {

  styles: PropTypes.object,
  secondColumnEnabled: PropTypes.any,
  title: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  columnHeader: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  secondHeader: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ])
}

export default Header
