import styled from 'styled-components'
import { GridTile } from 'material-ui/GridList'
import { ListItem } from 'material-ui/List'
import { Card, CardText } from 'material-ui/Card'
import Divider from 'material-ui/Divider'
import { StatusColorMap } from '../constants/status'
import { Candela, Gray, TextBlack } from '../../../constants/colors'

export const FlexLeft = styled.div`
  flex: 3 60%;
  max-width: 60%;
`
export const FlexRight = styled.div`
  flex: 2 0;
  text-align: right;
`
export const ScreenGridTile = styled(GridTile)`
  padding: 4px;
  height: 80%;
`
export const ListItemWrapper = styled.div`
  background-color: white;
`
export const MobileListItem = styled(ListItem)`
  display: 'inline-block';
  max-width: '100%';
  width: '100%';
  text-overflow: 'ellipsis';
  white-space: 'no-wrap';
  overflow: 'hidden';
`
export const ScreenDivider = styled(Divider)`
  background-color: ${Candela} !important;
`
export const ScreenCard = styled(Card)`
  background-color: ${(
    StatusColorMap.default.background || StatusColorMap.default.background
  )} !important;
`
export const ScreenCardText = styled(CardText)`
  margin-bottom: 0;
  padding-bottom: 6px;
`
export const ScreenFlexContainer = styled.div`
  display: flex;
  flex-flow: wrap;
  align-items: start;
`
export const ScreenName = styled(FlexLeft)`
  color: ${TextBlack};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  margin-bottom: 1em;
`
export const ScreenDescription = styled(FlexLeft)`
  color: ${Gray};
  font-family: 'Interface', sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 30px;
`
export const ScreenTemp = styled(FlexRight)`
  color: ${TextBlack};
  font-size: 32px;
  font-family: 'InterFace', sans-serif;
  font-weight: 700;
  line-height: 32px;
`
export const ScreenStateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
export const ScreenVideoStatus = styled.span`
  font-weight: 300;
  font-size: small;
  float: right;
  line-height: 20px;
`
export const ScreenVideoState = styled.span`
  color:
    ${props => {
    let color = StatusColorMap.default.color
    if(StatusColorMap[props.status.value]){
      color = StatusColorMap[props.status.value].color
    }
    return color
  }};
  font-family: 'Interface', sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
`
export const ScreensCount = styled.div`
  display: inline-block;
  font-family: 'Interface', sans-serif;
  font-weight: 300;
`
export const AutoSlide = styled.div`
  display: flex;
`
export const SliderHeader = styled.div`
  margin-top: 15px;
  margin-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
`
export const ScrollLabel = styled.div`
  margin-right: 5px;
  font-family: 'Interface', sans-serif;
  font-weight: 300;
`
export const RelevantScreens = styled.div`
  margin-top: 5px;
  margin-left: 20px;
`
export const RelevantScreensWrapper = styled.div`
  background-color: #e8e9e8;
  margin-top: -4px;
  padding-bottom: 25px;
`
