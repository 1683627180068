import App from '../layouts/AppLayout'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

function mapStateToProps (state) {
  return {
    routing: state.routing
  }
}

const actions = {

}

export default withRouter(connect(mapStateToProps, actions)(App))
