import { fetchFromVaporAPI, fetchFromSlimAPI } from '../libs/api'
import initialDateTime from '../libs/initialDateTime'
import { mapDataRealTimeAudience, mapDataStats } from '../libs/screenStats'
import * as actions from '../constants/actions'
import { setSuccessMessage } from './notice'
// ------------------------------------
// Actions
// ------------------------------------

export function screenChange (payload = {}) {
  return {
    type  : actions.UPDATE_SCREEN,
    payload
  }
}
export function videoStreamChange (payload = {}) {
  return {
    type  : actions.UPDATE_STREAM,
    payload
  }
}

export function clearIncident (id) {
  return {
    type: actions.CLEAR_INCIDENT,
    id
  }
}

export function updateDateTimes (payload) {
  return {
    type: actions.UPDATE_DATE_TIME,
    payload
  }
}
export function updateDateTimesAudience (payload) {
  return {
    type: actions.UPDATE_DATE_TIME_AUDIENCE,
    payload
  }
}

export function updateStats (payload) {
  return {
    type: actions.UPDATE_STATS,
    payload
  }
}

export function updateRealTimeAudience (payload) {
  return {
    type: actions.UPDATE_REALTIME_AUDIENCE,
    payload
  }
}

export function senderBoxLoading (payload) {
  return {
    type: actions.UPDATE_SENDER_BOX_LOADING,
    payload
  }
}
export function updateSenderBox (payload) {
  return {
    type: actions.UPDATE_SENDER_BOX,
    payload
  }
}

// ------------------------------------
// Async actions
// ------------------------------------

export const getScreen = (id) => {
  return (dispatch) => {
    dispatch(fetchFromSlimAPI(`screen/${id}`, 'get', undefined, () => {
    })).then(success => {
      if (success && !success.code) {
        dispatch(screenChange(success))
      }
    })
  }
}
export const getStream = (id) => {
  return (dispatch) => {
    dispatch(fetchFromSlimAPI(`screen/getScreenStream/${id}`, 'get', undefined, () => {
    })).then(success => {
      if (success && !success.code) {
        dispatch(videoStreamChange(success))
      }
    })
  }
}
export const acknowledgeIncident = (incidentId, screenId) => {
  return (dispatch) => {
    dispatch(clearIncident(incidentId)) // optimistically clear incident
    dispatch(fetchFromSlimAPI(
      `incident/${incidentId}/acknowledge`,
      'post',
      JSON.stringify({ screenId }),
      () => {})
    ).then(() => {
      dispatch(getScreen(screenId))
    })
  }
}

export const solveIncident = (incidentId, screenId, username) => {
  return (dispatch) => {
    const data = JSON.stringify({ screenId, username })
    return dispatch(fetchFromSlimAPI(`incident/${incidentId}/solve`, 'post', data, (success, err) => {
      if (success && !success.hasOwnProperty('code')) {
        dispatch(clearIncident(incidentId))
        dispatch(getScreen(screenId))
        return Promise.resolve(success)
      } else {
        return Promise.reject(err)
      }
    }))
  }
}

export const callPanicButton = (screenId, username) => {
  return (dispatch) => {
    const data = JSON.stringify({ screenId, username })
    return dispatch(fetchFromSlimAPI('panicbutton/call', 'post', data, (success, err) => {
      if (success && !success.hasOwnProperty('code')) {
        return Promise.resolve(success)
      } else {
        return Promise.reject(err)
      }
    }))
  }
}

export const getUser = () => {
  return (dispatch) => {
    return dispatch(fetchFromVaporAPI('users', 'get', undefined, () => {

    }))
  }
}

export const submitPanicButton = (screenId, code) => {
  return (dispatch) => {
    const data = JSON.stringify({ key: parseInt(code), screenId })
    return dispatch(fetchFromSlimAPI('panicbutton/submit', 'post', data, (success, err) => {
      if (success && !success.hasOwnProperty('code')) {
        return Promise.resolve(success)
      } else {
        return Promise.reject(err)
      }
    }))
  }
}

export const getStatsWithDates = (id, startDate, startTime, endDate, endTime, db) => {
  const formattedStartDate = `${startDate} ${startTime}`
  const formattedEndDate = `${endDate} ${endTime}`
  const body = JSON.stringify({
    startDate: formattedStartDate,
    endDate: formattedEndDate,
    db: db
  })
  return (dispatch) => {

    const url = `screen/${id}/stats`
    return dispatch(fetchFromSlimAPI(url, 'post', body, () => { }))
      .then(success => {
        if (Array.isArray(success)) {
          let data = mapDataStats(success)
          dispatch(updateStats(data))
        }
      })
  }
}

export const getRealTimeAudience = (id, startDate, startTime, endDate, endTime) => {
  const formattedStartDate = `${startDate} ${startTime}`
  const formattedEndDate = `${endDate} ${endTime}`
  const body = JSON.stringify({
    beginDate: formattedStartDate,
    endDate: formattedEndDate,
    screenId: id
  })
  return (dispatch) => {

    const url = `realtime/audience`
    return dispatch(fetchFromSlimAPI(url, 'post', body, () => { }))
      .then(success => {
        if(success){
          let data = mapDataRealTimeAudience(success)
          dispatch(updateRealTimeAudience(data))
        }
      })
  }
}

export const callSenderBoxSwitch = (id) => {
  return (dispatch) => {
    dispatch(senderBoxLoading(true))
    const url = `screen/senderbox/${id}`
    return dispatch(fetchFromSlimAPI(url, 'get', null, () => {}))
      .then(success => {
        dispatch(senderBoxLoading(false))
        if(success){
          dispatch(setSuccessMessage(success.message))
          dispatch(updateSenderBox('External Device'))
        }
      })
  }
}

export const setDateTimes = (startDate, startTime, endDate, endTime) => {
  return (dispatch) => {
    dispatch(updateDateTimes({ startDate, startTime, endDate, endTime }))
  }
}

export const setDateTimesAudience = (startDate, startTime, endDate, endTime) => {
  return (dispatch) => {
    dispatch(updateDateTimesAudience({ startDate, startTime, endDate, endTime }))
  }
}
// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  screen: {
    brightness: 0,
    contactPerson: null,
    document: null,
    db:"",
    id: 1,
    light: 0,
    location: {
      city: '',
      description: '',
      id: 1
    },
    realEstate: null,
    screenName: '',
    status: {
      incidents: [],
      status: 'disconnected',
      value: 20,
      statusString: 'disconnected'
    },
    techName: '',
    temp: 0,
    videoState: null,
    brightnessPercentage: '',
    power: null,
    powerLast24Hours: null,
    switcherOutput: "Screen Control"
  },
  dateTime: initialDateTime(),
  dateTimeAudience: initialDateTime(),
  stats: {
    brightness: [],
    light: [],
    temperature: []
  },
  power: null,
  powerLast24Hours: null,
  streamUrl: {
    streamUrl: ""
  },
  realTimeAudience:{
    peopleCount: [],
    peopleAverageDuration: []
  },
  senderBoxLoading: false,
}

export const screenReducer = (state = initialState, action) => {

  switch (action.type) {
  case actions.UPDATE_SCREEN:
    return { ...state, screen: action.payload }
  case actions.UPDATE_SENDER_BOX_LOADING:
    return { ...state, senderBoxLoading: action.payload }
  case actions.UPDATE_SENDER_BOX:
    return { ...state, screen: { ...state.screen, switcherOutput: action.payload } }
  case actions.UPDATE_DATE_TIME:
    return { ...state, dateTime: action.payload }

  case actions.UPDATE_STATS:
    return { ...state, stats: action.payload }
  case actions.UPDATE_STREAM:
    return { ...state, streamUrl: action.payload }
  case actions.UPDATE_REALTIME_AUDIENCE:
    return { ...state, realTimeAudience: action.payload }
  case actions.UPDATE_DATE_TIME_AUDIENCE:
    return { ...state, dateTimeAudience: action.payload }
  default: return state
  }
}

export default screenReducer
