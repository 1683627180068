import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import messages from '../../constants/messages'
import Check from 'material-ui/svg-icons/navigation/check'
import Close from 'material-ui/svg-icons/navigation/close'
import { Background500 } from "../../../../../../constants/colors"

const styles = {
  alternateRow: {
    backgroundColor: Background500,
  },
  row: {
    fontFamily: 'InterFace',
  }
}

export const Extra = ({
  document,
  id,
  light,
  city,
  locationDescription,
  temp,
  brightness,
  videoState,
  brightnessPercentage
}) => {
  if (brightnessPercentage === '') {
    brightnessPercentage = '-'
  } else {
    brightnessPercentage += '%'
  }
  return (
    <div className='table'>
      <div className='table-row'>
        <div style={styles.alternateRow}>
          <FormattedMessage {...messages.id} />
          <b>{id || '-'}</b>
        </div>
        <div style={styles.alternateRow}>
          <FormattedMessage {...messages.city} />
          <b>{city || '-'}</b>
        </div>
        <div>
          <FormattedMessage {...messages.locationDescription} />
          <b>{locationDescription || '-'}</b>
        </div>
        {document !== null &&
                [
                  <div key={0}>
                    <FormattedMessage {...messages.resolutionWidth} />
                    <b>{document.resolution.width || '-'}</b>
                  </div>,
                  <div style={styles.alternateRow} key={1}>
                    <FormattedMessage {...messages.resolutionHeight} />
                    <b>{document.resolution.height || '-'}</b>
                  </div>,
                  <div style={styles.alternateRow} key={2}>
                    <FormattedMessage {...messages.frameRate} />
                    <b>{document.frameRate || '-'}</b>
                  </div>,
                  <div key={3}>
                    <FormattedMessage {...messages.ledPitch} />
                    <b>{document.led.pitch || '-'}</b>
                  </div>,
                  <div key={4}>
                    <FormattedMessage {...messages.ledType} />
                    <b>{document.led.type || '-'}</b>
                  </div>,
                  <div style={styles.alternateRow} key={5}>
                    <FormattedMessage {...messages.ledVendor} />
                    <b>{document.led.vendor || '-'}</b>
                  </div>
                ].map((row, i) => {
                  return { ...row, key: i }
                })
        }
        <div style={styles.alternateRow}>
          <FormattedMessage {...messages.light} />
          <b>{light ? `${light} klx` : '-'}</b>
        </div>
        <div>
          <FormattedMessage {...messages.temp} />
          <b>{temp ? `${temp}˚C` : '-'}</b>
        </div>
        <div>
          <FormattedMessage {...messages.brightness} />
          <b>{brightness ? `${brightness} cd/m²` : '-'}</b>
        </div>
        {videoState !== null && [
          <div style={styles.alternateRow} key={0}>
            <FormattedMessage {...messages.videoInput} />
            <b>{videoState.input || '-'}</b>
          </div>,
          <div style={styles.alternateRow} key={1}>
            <FormattedMessage {...messages.videoInputConnected} />
            <b>{videoState.isInputConnected ? <Check /> : <Close />}</b>
          </div>,
          <div key={2}>
            <FormattedMessage {...messages.videoOutput} />
            <b>{videoState.output || '-'}</b>
          </div>,
          <div key={3}>
            <FormattedMessage {...messages.videoOutputConnected} />
            <b>{videoState.isOutputConnected ? <Check /> : <Close />}</b>
          </div>
        ].map((row, i) => {
          return { ...row, key: i * 100 }
        })
        }
        <div style={styles.alternateRow}>
          <FormattedMessage {...messages.brightnessPercentage} />
          <b>{brightnessPercentage || '-'}</b>
        </div>
      </div>
    </div>
  )
}

Extra.propTypes = {
  contactPerson: PropTypes.string,
  document: PropTypes.shape({
    frameRate: PropTypes.number,
    led: PropTypes.shape({
      pitch: PropTypes.string,
      type: PropTypes.string,
      vendor: PropTypes.string
    }),
    resolution: PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number
    })
  }),
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  light: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  brightness: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  city: PropTypes.string,
  locationDescription: PropTypes.string,
  realEstate: PropTypes.string,
  temp: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  videoState: PropTypes.object,
  brightnessPercentage: PropTypes.any
}

export default Extra
