import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Role from '../routes/Role'
import Roles from '../containers/RolesContainer'
import CreateRole from '../routes/CreateRole'
export const AppLayout = props => {
  return(
    <div>
      <Switch>
        <Route path={'/management/roles/create'} component={CreateRole} />
        <Route path={'/management/roles/:id'} component={Role} />
        <Route path={'/management/roles'} component={Roles} />
      </Switch>
    </div>
  )
}
export default AppLayout