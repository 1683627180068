import FlatButton from 'material-ui/FlatButton'
import PropTypes from 'prop-types'
import React from 'react'
import * as Styles from '../constants/styles'

export const ForgotPasswordButton = (props) => (
  <div className='login-forgot-password-wrapper'>
    <FlatButton {... props} labelStyle={Styles.forgotLabelStyle} className='forgot-password' />
  </div>
)

ForgotPasswordButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label : PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]).isRequired
}

export default ForgotPasswordButton
