import PropTypes from 'prop-types'
import React from 'react'
import Avatar from 'material-ui/Avatar'
import Chip from 'material-ui/Chip'

export const User = (props) => {
  const firstCharacter = props.username.charAt(0).toUpperCase()
  return (
    <Chip id={props.id} style={props.style} onRequestDelete={props.onRequestDelete} onTouchTap={props.onTouchTap} >
      <Avatar size={32}>{firstCharacter}</Avatar>
      <div className='role-user-chip'>{props.username}</div>
    </Chip>
  )
}

User.propTypes = {
  id: PropTypes.number.isRequired,
  username: PropTypes.string.isRequired,
  style: PropTypes.object,
  onRequestDelete: PropTypes.func,
  onTouchTap: PropTypes.func
}

export default User
