import PropTypes from 'prop-types'
import React from 'react'
import { TableHeader, TableHeaderColumn, TableRow } from 'material-ui/Table'

class Header extends TableHeader {
  render () {
    return (
      <TableHeader displaySelectAll enableSelectAll={false} >
        <TableRow style={{ fontFamily: 'Interface' }}>
          <TableHeaderColumn key={0} style={this.props.styles} className='edit-table-id-column'>ID</TableHeaderColumn>
          {this.props.columnHeaders.map((element, i) =>
            <TableHeaderColumn
              key={i + 1}
              className={i > 0 ? `edit-table-secondary-column edit-table-column-${i + 1}` : null}
            >
              {element}
            </TableHeaderColumn>
          )}
        </TableRow>
      </TableHeader>
    )
  }
}

Header.propTypes = {

  styles: PropTypes.object,
  title: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  columnHeader: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  columnHeaders: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string
    ])
  )
}

export default Header
