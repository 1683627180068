import PropTypes from 'prop-types'
import * as React from 'react'

import { ScreensMap } from "./ScreensMap"
import MapFilterButton from "../../../components/MapFilterButton"

class Map extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      popupInfo: null,
      statusStrings: ["ok", "error", "disconnected", "acknowledged"]
    }
    this.popupOnClick = this.popupOnClick.bind(this)
    this.popupOnClose = this.popupOnClose.bind(this)
    this.updateStatusStrings = this.updateStatusStrings.bind(this)
  }

  popupOnClick (screen) {
    this.setState({
      popupInfo: screen
    })
  }

  popupOnClose () {
    this.setState({
      popupInfo: null
    })
  }

  componentDidMount () {
    const { getScreens } = this.props
    getScreens()
  }

  updateStatusStrings (selection) {
    const statusStrings = []
    if (selection.Ok === true) {
      statusStrings.push("ok")
    }
    if (selection.Disconnected === true) {
      statusStrings.push("disconnected")
    }
    if (selection.Error === true) {
      statusStrings.push("error")
    }
    if (selection.Acknowledged === true) {
      statusStrings.push("acknowledged")
    }

    this.setState({
      statusStrings: statusStrings
    })
  }

  render () {
    const { screens } = this.props
    const { statusStrings } = this.state

    const filteredScreens = screens.filter(function (screen) {
      return statusStrings.includes(screen.status.statusString)
    })
    return (
            <>
              <ScreensMap
                popupOnClick={this.popupOnClick}
                popupOnClose={this.popupOnClose}
                popupInfo={this.state.popupInfo}
                history={this.props.history}
                screens={filteredScreens}
              />
              <MapFilterButton
                updateStatusStrings={this.updateStatusStrings}
              />
            </>
    )
  }
}

Map.propTypes = {
  getScreens: PropTypes.number.isRequired,
  screens: PropTypes.any.isRequired,
  history: PropTypes.any
}

export default Map
