import React from 'react'
import { Switch, Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import Container from '../containers/CoreLayoutContainer'
import MeContainer from '../containers/MeContainer'
import NoticeContainer from '../containers/NoticeContainer'
import HeaderContainer from '../containers/HeaderContainer'
import IsMobile from '../containers/IsMobileContainer'
import Screens from '../routes/Screens'
import Login from '../routes/Login'
import ACL from '../routes/ACL'
import * as routes from '../constants/routes'
import './CoreLayout.scss'
import '../styles/core.scss'
import Map from '../routes/Screens/containers/MapContainer'

export class AppLayout extends React.Component {
  componentWillReceiveProps (nextProps, nextContext) {
    if(nextProps.history.key !== this.props.history.key){
      this.forceUpdate()
    }
  }

  render (){
    const currentLocation = this.props.history.location.pathname || ''
    const isOnACL = currentLocation.indexOf(routes.ACL) !== -1
    return(
      <Container>
        <MeContainer />
        <HeaderContainer isOnACL={isOnACL} />
        <NoticeContainer />
        <IsMobile />
        <Switch>
          <Route path={'/screens'} component={Screens} />
          <Route path={'/management'} component={ACL} />
          <Route path={'/home'} component={Map} />
          <Route path={'/'} component={Login} />
        </Switch>
      </Container>
    )
  }
}
AppLayout.propTypes = {
  history: PropTypes.object
}
export default AppLayout
