export const DarkBlue = '#135efa'
export const Gray = 'rgba(37, 36, 36, 0.7)'
export const Background = '#eaeaea'
export const Background500 = 'rgba(234, 234, 234, 0.5)'

export const White = '#FFFFFF'
export const TextBlack = '#101010'
export const DoveGray = '#707070'
export const FairPink = '#FFEEEE'
export const WildSand = '#F6F6F6'
export const Yellow = '#FDE541'

export const CaribbeanGreen = '#00C98D'

export const Success = '#04ae05'
export const Warning = '#f44336'
export const Panic = '#d50001'

// Light intensity colors
export const Candela = '#295dff'
export const Green = '#08d203'
