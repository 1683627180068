import React from 'react'
import App from './containers/AppContainer'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'react-router-redux'
import { IntlProvider } from 'react-intl'
import store, { history } from './store'
import { translationMessages as messages } from './i18n'
import registerServiceWorker from './libs/registerServiceWorker'
import { AppContainer } from 'react-hot-loader'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import muiTheme from './constants/MaterialUITheme'
import './index.css'
import 'semantic-ui-css/semantic.min.css'
import 'react-vis/dist/style.css'

const render = Component => {
  console.log(history)
  ReactDOM.render(
    <AppContainer>
      <IntlProvider locale={'nl'} key={'nl'} messages={messages['nl']}>
        <MuiThemeProvider muiTheme={getMuiTheme(muiTheme)}>
          <Provider store={store}>
            <ConnectedRouter history={history}>
              <Component />
            </ConnectedRouter>
          </Provider>
        </MuiThemeProvider>
      </IntlProvider>
    </AppContainer>

    , document.getElementById('root'))
}

render(App)

if (module.hot) {
  module.hot.accept('./containers/AppContainer', () => {
    const next = require('./containers/AppContainer').default
    render(next)
  })
}

registerServiceWorker()
