import { defineMessages } from 'react-intl'

export default defineMessages({
  groupNameTaken: {
    id: 'Groups.error.name.taken',
    defaultMessage: 'Sorry this name is already taken, please try another name.'
  },
  title: {
    id: 'Groups.components.create.title',
    defaultMessage: 'Create group'
  },
  submit: {
    id: 'Groups.components.create.submit',
    defaultMessage: 'submit'
  },
  groupName: {
    id: 'Groups.components.create.groupName',
    defaultMessage: 'Group name'
  }

})
