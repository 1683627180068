import { createSelector } from 'reselect'
import { strIncludes } from '../../../utils/filtering'
import { sortScreens } from '../../../utils/screenSorting'

// Input Selectors
const getScreens = state => state.screens.screens
const getSearchFilter = state => state.screens.searchFilter

// Reselect Selectors
export const getSortedScreens = createSelector(
  [ getScreens ],
  (screens) => sortScreens(screens)
)

export const getVisibleSortedScreens = createSelector(
  [ getSortedScreens, getSearchFilter ],
  (screens, searchFilter) => {
    // don't filter if search query is empty
    if (!searchFilter.length) return screens

    return screens.filter(function (screen) {
      return [
        screen.screenName,
        screen.location.city,
        screen.location.description,
        screen.techName,
        screen.status.statusString
      ].some(str => strIncludes(str, searchFilter))
    })
  }
)

export default getVisibleSortedScreens
