export const pxToPercentage = (min, max) => Number(min) / Number(max)

/**
 * Calculate the resolution in percentages
 * @param width
 * @param height
 * @returns {*} returns the width and height in values between 0 and 1
 */
export const resolutionToPercentages = (width, height) => {
  if (width < height) return { width: pxToPercentage(width, height), height: 1 }
  return { width: 1, height: pxToPercentage(height, width) }
}

export const pxToMeter = (px, pitch) => Number(px) * Number(pitch) / 1000

/**
 * Calculate resolution in meters
 * @param width in pixels
 * @param height in pixels
 * @param pitch in mm
 * @returns {{width, height}} width & height in meters
 */
export const resolutionToMeter = (width, height, pitch) => {
  return { width: pxToMeter(width, pitch), height: pxToMeter(height, pitch) }
}

/**
 * Calculate the size of the person relative to the screen
 * @param height in meters
 * @returns {int} return the persons size in percentages between 0 and 100
 */
export const scalePerson = (height) => {
  const person = 1.75 // 175cm
  return Math.round(person / height * 100)
}
