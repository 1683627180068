import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Login from '../containers/LoginContainer'

export const AppLayout = () => {
  return(
    <div>
      <Switch>
        <Route path={'/'} component={Login} />
      </Switch>
    </div>
  )
}

export default AppLayout
