// ------------------------------------
// Constants
// ------------------------------------
// export const API_BASE_URL = 'http://localhost:9090/api/0.1/'
export const getEnv = () => {
  if (window.location.href.indexOf('localhost') > -1 || window.location.href.indexOf(':300') > -1) {
    console.log('the current environment is: development')
    return 'development'
  } else if (window.location.href.indexOf('dharma') > -1) {
    console.log('the current environment is: staging')
    return 'staging'
  }
  console.log('the current environment is: production')
  return 'production'
}
export const getApiBaseUrl = (env) => {
  switch (env) {
  case 'development':
    return {
      vapor: 'http://127.0.0.1:9090/api/0.1/',
      slim: 'http://localhost:8000/api/'
    }
  case 'staging':
    return {
      vapor: 'https://ngage.dharma.nl/vapor/api/0.1/',
      slim: 'https://screen-control.dharma.nl/laravel/api/'
    }
  case 'production':
  default:
    return {
      vapor: 'https://ngagemedia-04.hosting.in2ip.nl/api/0.1/',
      slim: 'https://dashboard.oceanoutdoor.nl/laravel/api/'
    }
  }
}

export const API_BASE_URL = getApiBaseUrl("production").vapor
export const SLIM_BASE_URL = getApiBaseUrl("production").slim
export const MAPBOX_API_ACCESS_TOKEN =
  "pk.eyJ1IjoiZGhhcm1hYnYiLCJhIjoiY2twbzN6MDcwMjZiMTJ2bzg1NnFkcXZkOCJ9.66AgdDQwbTqnUeu9x-tZ-A"

export const API_REQUEST_HEADERS = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
}
export const API_REQUEST_OBJECT = {
  mode: 'cors',
  headers: API_REQUEST_HEADERS
}

/**
 * Use these values as params in API /stats/ requests
 * @type {{light: string, brightness: string, temp: string}}
 */
export const STATS_TYPES = {
  light: 'Light',
  brightness: 'Brightness',
  temp: 'Temperature'
}

export const GRANULARITY = {
  minute: 'minute',
  hour: 'hour',
  day: 'day',
  week: 'week',
  month: 'month',
  quarter: 'quarter',
  halfYear: 'halfYear',
  year: 'year',
  decade: 'decade'
}

