import React from 'react'
import PropTypes from 'prop-types'
import { FormattedNumber } from 'react-intl'

const NumberFormatOptions = {
  style: 'decimal',
  maximumFractionDigits: 1
}

export const Formatted = ({ number }) => (
  <FormattedNumber
    value={number} {...NumberFormatOptions}
  />
)

Formatted.propTypes = {
  number: PropTypes.number.isRequired
}

export default Formatted
