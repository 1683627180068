import { FormattedMessage, defineMessages } from 'react-intl'
import React from 'react'
export const CREATE_GROUP = 'createGroup'
export const CREATE_ROLE = 'createRole'
export const CREATE_USER = 'createUser'
export const DELETE_GROUP = 'deleteGroup'
export const DELETE_ROLE = 'deleteRole'
export const DELETE_USER = 'deleteUser'
export const EDIT_GROUP = 'editGroup'
export const EDIT_ROLE = 'editRole'
export const EDIT_USER = 'editUser'
export const VIEW_GROUP = 'viewGroup'
export const VIEW_ROLE = 'viewRole'
export const VIEW_USER = 'viewUser'
export const ACKNOWLEDGE_INCIDENTS = 'acknowledgeIncidents'
export const INCIDENT_LVL_1 = 'incidentLevel1'
export const INCIDENT_LVL_2 = 'incidentLevel2'
export const INCIDENT_LVL_3 = 'incidentLevel3'
export const INCIDENT_LVL_4 = 'incidentLevel4'
export const INCIDENT_LVL_5 = 'incidentLevel5'

const messages = defineMessages({
  editRole: {
    id: 'acl.edit.role',
    defaultMessage: 'edit role'
  },
  editGroup: {
    id: 'acl.edit.group',
    defaultMessage: 'edit group'
  },
  editUser: {
    id: 'acl.edit.user',
    defaultMessage: 'edit user'
  },
  viewUser: {
    id: 'acl.view.user',
    defaultMessage: 'view user'
  },
  viewGroup: {
    id: 'acl.view.group',
    defaultMessage: 'view group'
  },
  viewRole: {
    id: 'acl.view.role',
    defaultMessage: 'view role'
  },
  createUser: {
    id: 'acl.create.user',
    defaultMessage: 'create user'
  },
  createGroup: {
    id: 'acl.create.group',
    defaultMessage: 'create group'
  },
  createRole: {
    id: 'acl.create.role',
    defaultMessage: 'create role'
  },
  deleteUser: {
    id: 'acl.delete.user',
    defaultMessage: 'delete user'
  },
  deleteGroup: {
    id: 'acl.delete.group',
    defaultMessage: 'delete group'
  },
  deleteRole: {
    id: 'acl.delete.role',
    defaultMessage: 'delete role'
  },
  acknowledgeIncidents: {
    id: 'acl.acknowledgeIncidents',
    defaultMessage: 'acknowledge incidents'
  },
  incidentLevel1: {
    id: 'acl.incidentLevel1',
    defaultMessage: 'incident level 1'
  },
  incidentLevel2: {
    id: 'acl.incidentLevel2',
    defaultMessage: 'incident level 2'
  },
  incidentLevel3: {
    id: 'acl.incidentLevel3',
    defaultMessage: 'incident level 3'
  },
  incidentLevel4: {
    id: 'acl.incidentLevel4',
    defaultMessage: 'incident level 4'
  },
  incidentLevel5: {
    id: 'acl.incidentLevel5',
    defaultMessage: 'incident level 5'
  }

})

export const PERMISSION_LABELS = {
  [EDIT_ROLE]: <FormattedMessage {...messages.editRole} />,
  [EDIT_GROUP]: <FormattedMessage {...messages.editGroup} />,
  [EDIT_USER]: <FormattedMessage {...messages.editUser} />,
  [VIEW_USER]: <FormattedMessage {...messages.viewUser} />,
  [VIEW_GROUP]: <FormattedMessage {...messages.viewGroup} />,
  [VIEW_ROLE]: <FormattedMessage {...messages.viewRole} />,
  [CREATE_USER]: <FormattedMessage {...messages.createUser} />,
  [CREATE_GROUP]: <FormattedMessage {...messages.createGroup} />,
  [CREATE_ROLE]: <FormattedMessage {...messages.createRole} />,
  [DELETE_USER]: <FormattedMessage {...messages.deleteUser} />,
  [DELETE_GROUP]: <FormattedMessage {...messages.deleteGroup} />,
  [DELETE_ROLE]: <FormattedMessage {...messages.deleteRole} />,
  [ACKNOWLEDGE_INCIDENTS]: <FormattedMessage {...messages.acknowledgeIncidents} />,
  [INCIDENT_LVL_1]: <FormattedMessage {...messages.incidentLevel1} />,
  [INCIDENT_LVL_2]: <FormattedMessage {...messages.incidentLevel2} />,
  [INCIDENT_LVL_3]: <FormattedMessage {...messages.incidentLevel3} />,
  [INCIDENT_LVL_4]: <FormattedMessage {...messages.incidentLevel4} />,
  [INCIDENT_LVL_5]: <FormattedMessage {...messages.incidentLevel5} />
}
