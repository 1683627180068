import PropTypes from 'prop-types'
import React, { Component } from 'react'
import messages from './messages'
import LogInButton from '../../../components/ResponsiveButton'
import { FormattedMessage } from 'react-intl'
import GoToIcon from "../../../assets/icons/go-to.svg"

export class LoginButton extends Component {

  render () {
    const label = this.props.label ? this.props.label : <FormattedMessage {...messages.login} />
    return (
      <React.Fragment>
        <LogInButton label={label} {...this.props} style={{ backgroundImage: `url(${GoToIcon})` }} />
      </React.Fragment>

    )
  }

}

LoginButton.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ])
}

export default LoginButton
