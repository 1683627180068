import { chunk } from 'lodash'

export const getDate = (d) => new Date(d)

export const average = arr => arr.reduce((p, c) => p + c, 0) / arr.length

export const chunkData = (array, modulo) => chunk(array, modulo).reduce((lhs, rhs) => {
  if (rhs.length === 0) {
    return lhs
  }
  const x = rhs[0].x
  const y = average(rhs.map(e => e.y))
  const e = { x, y }
  return [...lhs, e]
}, [])

export const mapDataStats = (array) => {
  const maxDataPoints = 500
  const numberOfPoints = array.length
  const shouldModulo = numberOfPoints > maxDataPoints
  const modulo = Math.round(numberOfPoints / maxDataPoints)

  const temperature = array.map((obj) => ({ x: getDate(obj.date), y: obj.temperature }))
  const brightness = array.map((obj) => ({ x: getDate(obj.date), y: obj.brightness }))
  const light = array.map((obj) => ({ x: getDate(obj.date), y: obj.light }))

  if (!shouldModulo) {
    return { light, brightness, temperature }
  }

  return {
    light: chunkData(light, modulo),
    brightness: chunkData(brightness, modulo),
    temperature: chunkData(temperature, modulo)
  }
}

export const mapDataRealTimeAudience = (array) => {
  const maxDataPoints = 500
  const numberOfPoints = (array.peopleCount.length < array.peopleAverageDuration.length) ?
    array.peopleAverageDuration.length : array.peopleCount.length
  const shouldModulo = numberOfPoints > maxDataPoints
  const modulo = Math.round(numberOfPoints / maxDataPoints)
  const peopleCount = array.peopleCount.map((obj) => ({ x: getDate(obj.date), y: obj.count }))
  const peopleAverageDuration = array.peopleAverageDuration.map(
    (obj) => ({ x: getDate(obj.date), y: obj.averageDuration })
  )
  if (!shouldModulo) {
    return { peopleCount, peopleAverageDuration }
  }

  return {
    peopleCount: chunkData(peopleCount, modulo),
    peopleAverageDuration: chunkData(peopleAverageDuration, modulo),
  }
}