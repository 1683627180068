import { defineMessages } from 'react-intl'

export default defineMessages({
  groupNameTaken: {
    id: 'Groups.error.name.taken',
    defaultMessage: 'Sorry this name is already taken, please try another name.'
  },
  removedGroup: {
    id: 'Groups.removed.group',
    defaultMessage: 'Removed group'
  }
})
