import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import Login from '../components/Login'

const mapActionCreators = {}

const mapStateToProps = (state) => ({
  isMobile: state.isMobile,
  fetching: state.auth.fetching
})

export default withRouter(connect(mapStateToProps, mapActionCreators)(Login))
