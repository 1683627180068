export const nl = {
  "EditableTable.components.search.all.fields": "Zoek in alle velden",
  "Group.compoents.editGroupNameFloatingLabelText": "Naam van de groep",
  "Group.compoents.editGroupNameHintText": "Groep naam",
  "Group.compoents.editGroupNameTitle": "Wijzig naam van groep",
  "Group.compoents.usersInGroup": "Gebruikers in groep",
  "Group.components.edit": "BEWERK",
  "Group.components.submit": "WIJZIG",
  "Group.components.view.city": "city",
  "Group.components.view.description": "omschrijving",
  "Group.components.view.screens": "Schermen",
  "Group.components.view.screensName": "Scherm naam",
  "Group.components.view.techName": "Technische naam",
  "Groups.components.create.groupName": "Groep naam",
  "Groups.components.create.submit": "Voeg groep toe",
  "Groups.components.create.title": "Nieuwe groep toevoegen",
  "Groups.error.name.taken": "Sorry deze naam bestaat al, kies een andere naam",
  "Groups.removed.group": "Groep verwijderd",
  "Header.components.management": "Management",
  "Login.components.backToLogin": "Terug naar login",
  "Login.components.forgotPassword": "Wachtwoord vergeten?",
  "Login.components.invalidEmail": "Ongeldig email adres",
  "Login.components.login": "Log in",
  "Login.components.password": "Wachtwoord",
  "Login.components.requestPasswordReset": "Stuur mij een mail",
  "Login.components.username": "Gebruikersnaam",
  "Login.error.login.failed": "Gebruikersnaam of wachtwoord is onbekend",
  "PasswordStrength.Component.ok": "bijna sterk :)",
  "PasswordStrength.Component.strong": "sterk",
  "PasswordStrength.Component.veryStrong": "erg sterk",
  "PasswordStrength.Component.veryWeak": "erg zwak",
  "PasswordStrength.Component.weak": "zwak",
  "Reset.component.password": "wachtwoord",
  "Reset.component.repeatPassword": "herhaal wachtwoord",
  "Reset.component.resetPassword": "reset wachtwoord",
  "Role.components.EditRole.floatingLabelText": "Naam van de rol",
  "Role.components.EditRole.hintText": "Naam",
  "Role.components.EditRole.submit": "WIJZIG",
  "Role.components.EditRole.title": "Bewerk naam van de rol",
  "Role.components.EditRolePermissions.title": "Wijzig bevoegdheden",
  "Role.components.EditRoleUsers.explaination": "Druk op het kruisje om een gebruiker te verwijderen uit deze rol",
  "Role.components.EditRoleUsers.usersNotInRoleExplaination": "Druk op een gebruik om deze toetevoegen aan deze rol",
  "Role.components.EditRoleUsers.usersNotInRoleTitle": "Gebruikers niet in deze rol",
  "Role.components.ViewRoleUsers.title": "Gebruikers in rol",
  "Role.components.edit": "BEWERKEN",
  "Role.components.noPermissionsFoundForRole": "Deze rol bevat geen bevoegdheden",
  "Role.components.noUsersFoundForRole": "Deze rol bevat geen gebruikers.",
  "Role.components.permission": "Bevoegdheid",
  "Role.components.permissions": "Bevoegdheden",
  "Role.components.role": "Rol",
  "Role.components.username": "Gebruikersnaam",
  "Role.components.view": "KLAAR",
  "Roles.components.create.roleName": "Naam van rol",
  "Roles.components.create.submit": "Toevoegen",
  "Roles.components.create.title": "Maak een nieuwe rol aan",
  "Screens.component.brightness": "Helderheid",
  "Screens.component.brightness.legend": "Candela per m² (cd/m²)",
  "Screens.component.brightness.units": "cd/m²",
  "Screens.component.people.count": "Aantal mensen",
  "Screens.component.people.count.legend": "aantal mensen",
  "Screens.component.people.count.units": "per minuut",
  "Screens.component.people.average.duration": "Gemiddelde stilstand",
  "Screens.component.people.average.duration.legend": "gemiddelde stilstand",
  "Screens.component.people.average.duration.units": "seconden",
  "Screens.component.title.realtime.audience" : "Aantal mensen & gemiddelde stilstand",
  "Screens.component.city": "Locatie",
  "Screens.component.contactPerson": "Contactpersoon",
  "Screens.component.date": "Datum",
  "Screens.component.document": "Document",
  "Screens.component.filter.label": "Filter afgelopen...",
  "Screens.component.firstOccurrence": "Eerste incident",
  "Screens.component.framerate": "Framerate",
  "Screens.component.height": "Hoogte",
  "Screens.component.id": "ID",
  "Screens.component.incidentAcknowledged": "Bevestigd",
  "Screens.component.incidentAcknowledgedDate": "Bevestigd op",
  "Screens.component.incidentAcknowledgedUser": "Bevestigd door",
  "Screens.component.incidentCount": "Aantal",
  "Screens.component.incidentLevel": "Level",
  "Screens.component.incidentRule": "Rule",
  "Screens.component.lastOccurrence": "Laatste incident",
  "Screens.component.led.pitch": "LED Pitch",
  "Screens.component.led.type": "LED Type",
  "Screens.component.led.vendor": "LED Vendor",
  "Screens.component.light": "Lichtsterkte",
  "Screens.component.light.legend": "Lichtsterkte in kilolux (klx)",
  "Screens.component.light.units": "klx",
  "Screens.component.locationDescription": "Locatie omschrijving",
  "Screens.component.none.found": "Geen schermen gevonden",
  "Screens.component.notifications": "{count} {count, plural, one {melding} other {meldingen}}",
  "Screens.component.occurrences": "Incidenten",
  "Screens.component.realEstate": "Onroerend goed",
  "Screens.component.resolution": "Resolution",
  "Screens.component.search.hint": "Locatie zoeken",
  "Screens.component.temp": "Temperatuur",
  "Screens.component.title.extra": "Scherm details",
  "Screens.component.title.stream": "Live scherm",
  "Screens.component.title.stats": "Lichtintensiteit & Helderheid",
  "Screens.component.title.info": "Scherm info",
  "Screens.component.title.status": "Schermstatus: {count} {count, plural, one {melding} other {meldingen}}",
  "Screens.component.videoInput": "Video Input",
  "Screens.component.videoInputConnected": "Video Input Connected",
  "Screens.component.videoOutput": "Video Output",
  "Screens.component.videoOutputConnected": "Video Output Connected",
  "Screens.component.width": "Breedte",
  "Users.components.Createuser.company": "Bedrijf",
  "Users.components.Createuser.name": "Naam",
  "Users.components.Createuser.passwordFloatingLabelText": "Wachtwoord",
  "Users.components.Createuser.submit": "Voeg toe",
  "Users.components.Createuser.title": "Maak een nieuwe gebruiker aan",
  "Users.components.Createuser.titleFloatingLabelText": "Functie",
  "Users.components.Createuser.usernameFloatingLabelText": "Email",
  "Users.components.User.company": "Bedrijf",
  "Users.components.User.edit.success": "Gebruiker {username} is aangepast",
  "Users.components.User.delete.success": "Gebruiker is verwijderd",
  "Users.components.User.name": "Naam",
  "Users.components.User.passwordFloatingLabelText": "wachtwoord",
  "Users.components.User.submit": "Opslaan",
  "Users.components.User.delete": "Verwijderen",
  "Role.components.Role.delete": "Verwijderen",
  "Group.components.Group.delete": "Verwijderen",
  "Users.components.User.title": "Gebruiker {user} bewerken",
  "Users.components.User.titleFloatingLabelText": "Functie",
  "Users.components.User.usernameFloatingLabelText": "Email",
  "Users.components.invalidEmail": "Ongeldig email adres",
  "acl.acknowledgeIncidents": "Bevestig incidenten",
  "acl.create.group": "Creëer groep",
  "acl.create.role": "Creëer rol",
  "acl.create.user": "Creëer gebruiker",
  "acl.delete.group": "Verwijder groep",
  "acl.delete.role": "Verwijder rol",
  "acl.delete.user": "Verwijder gebruiker",
  "acl.edit.group": "Bewerk groep",
  "acl.edit.role": "Bewerk rol",
  "acl.edit.user": "Bewerk gebruiker",
  "acl.incidentLevel1": "Incident level 1",
  "acl.incidentLevel2": "Incident level 2",
  "acl.incidentLevel3": "Incident level 3",
  "acl.incidentLevel4": "Incident level 4",
  "acl.incidentLevel5": "Incident level 5",
  "acl.view.group": "Bekijk groep",
  "acl.view.role": "Bekijk rol",
  "acl.view.user": "Bekijk gebruiker",
  "application.company": "NGAGE MEDIA",
  "application.name": "Extended Screencontrol",
  "date.day.view": "Dagweergave",
  "date.month.view": "Maandweergave",
  "date.week.view": "Weekweergave",
  "date.year": "Jaar",
  "date.year.view": "Jaarweergave",
  "error.field.missing": "Vul alle velden in",
  "error.forbidden": "Sorry, je hebt niet voldoende rechten",
  "error.forbidden.route": "Sorry, je hebt niet voldoende rechten om deze pagina te bekijken",
  "error.general": "Er ging iets mis, probeer het nog een keer",
  "error.login.expired": "Login is niet meer geldig, log opnieuw in",
  "error.login.may.be.expired": "Je bent niet ingelogd of je login is verlopen, log opnieuw in",
  "error.passwords.do.not.match": "Wachtwoorden komen niet overeen.",
  "error.reset.expired": "De reset link is verlopen.",
  "error.role.name.taken": "Sorry, deze rol naam bestaat al. Kies een andere naam",
  "error.unauthorized": "Kon niet authenticeren, log in",
  "error.user.or.password.invalid": "Gebruikersnaam of wachtwoord is onbekend.",
  "error.username.taken": "Sorry, deze gebruikersnaam is al bezet, kies een andere gebruikersnaam",
  "succes.password.reset.requested": "Controleer je email voor een wachtwoord reset link",
  "success.add.permission.to.role": "Bevoegdheid toegevoegd aan rol",
  "success.add.screenToGroup": "Scherm toegvoegd aan groep",
  "success.add.user.to.role": "Gebruiker toegevoegd aan rol",
  "success.add.userToGroup": "Gebruiker toegevoegd aan groep",
  "success.create.group": "Groep is aangemaakt",
  "success.create.role": "Role is aangemaakt",
  "success.create.user": "Gebruiker is aangemaakt",
  "success.delete.group": "Groep is verwijderd",
  "success.edit.group.name": "Groepsnaam is aangepast",
  "success.password.changed": "Wachtwoord aanpassen gelukt!",
  "success.remove.permission.from.role": "Bevoegdheid is verwijderd van rol",
  "success.remove.role": "Rol is verwijderd",
  "success.remove.screenFromGroup": "Scherm is verwijderd van groep",
  "success.remove.user.from.role": "Gebruiker is verwijderd van rol",
  "success.remove.userFromGroup": "Gebruiker is verwijderd van groep",
  "success.update.role.name": "Naam van rol is aangepast",
  "Screens.component.title.cardTitleWattage": "Energie verbruik",
  "Screens.component.brightnessPercentage": "Helderheids percentage",
  "Screens.component.subtitle.usagePerSquareMetres" : "Huidig vermogen per m2:",
  "Screens.component.subtitle.currentScreenUsage" : "Huidig vermogen scherm:",
  "Screens.component.subtitle.powerLastDay" : "Totaal verbruik afgelopen 24 uur:"
}

export default nl
