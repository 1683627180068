import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Card, CardText } from 'material-ui/Card'
import CardTitle from '../../../../../../../components/CardTitle'
import { container } from '../../../../../../../styles/layout'
import List from 'material-ui/List/List'
import ListItem from 'material-ui/List/ListItem'
import Divider from 'material-ui/Divider'
import Avatar from 'material-ui/Avatar'
import Group from 'material-ui/svg-icons/action/aspect-ratio'
import Role from 'material-ui/svg-icons/action/verified-user'
import './User.scss'

import {
  Candela,
  TextBlack
} from '../../../../../../../constants/colors'

export class Edit extends Component {
  render () {
    let { name, username, role, title, company, groups } = this.props.user
    return (
      <div className={this.props.isMobile ? null : 'View-user-card-wrapper card-wrapper'}>
        <div className='padding-box'>
          <Card style={container}>
            <CardTitle
              titleStyle={{ color: TextBlack, fontWeight: 300 }}
              title={name}
            />
            <CardText style={{ paddingTop: '0px', paddingBottom: '8px' }} >
              <List>
                <ListItem
                  disabled
                  primaryText={name}
                  secondaryText={username}
                  leftAvatar={
                    <Avatar
                      color={Candela}
                      backgroundColor={TextBlack}
                      size={30}
                      style={{ margin: '5px 0' }}
                    >
                      {name.substring(0, 1)}
                    </Avatar>
                  }
                />
                <ListItem
                  disabled
                  insetChildren
                  primaryText={title}
                  secondaryText={company}
                />
                <Divider inset />
                <ListItem
                  disabled
                  leftIcon={<Group />}
                  primaryText={role || '-'}
                />
                <ListItem
                  disabled
                  leftIcon={<Role />}
                  primaryText={groups.join(', ') || '-'}
                />
              </List>
            </CardText>
          </Card>
        </div>
      </div>
    )
  }
}

Edit.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
    groups: PropTypes.array.isRequired
  }),
  isMobile: PropTypes.any.isRequired
}

export default Edit
