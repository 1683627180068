import PropTypes from 'prop-types'
import React, { Component } from 'react'
import search_dark from '../../assets/icons/search-dark.svg'
import search from '../../assets/icons/search.svg'
import { Toolbar, InputBase, IconButton, SvgIcon } from '@material-ui/core'
import styles from '../../routes/Screens/components/styles'
import { MOBILE_WIDTH } from "../../constants/dimensions"
import { Link } from "react-router-dom"
import CloseIcon from "../../assets/icons/close.svg"

export default class ScreensToolbar extends Component {
  constructor (props) {
    super(props)
    this.state = {
      history: '',
      url: '',
      dark: false,
    }
  }

    handleSearchChange = (event) => this.props.setSearchFilter(event.target.value)

    handleRouteChanged = (event) => {
      this.setState({ 'url': event.pathname })
      this.forceUpdate()
    }

    render () {
      const buttonColour = (page) => {
        let stroke = "#AEAEAD"
        // When watching screen view
        if (page === 'screens') {
          if (this.props.url === '/screens') {
            stroke = "white"
            if (this.props.dark) {
              stroke = "black"
            }
          }
          return stroke
          // When watching map view
        } else if (page === 'home') {
          if (this.props.url === '/home') {
            stroke = "white"
            if (this.props.dark) {
              stroke = "black"
            }
          }
          return stroke
        }
      }
      const isMobile = window.innerWidth <= MOBILE_WIDTH
      return (
        <div className={this.props.dark ? 'search--bar dark' : 'search--bar'}>
          <Toolbar style={isMobile ? styles.MobileToolbar : styles.Toolbar}>
            <div style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
            >
              <Link
                style={!this.props.dark ? styles.DarkViewLeft : styles.DarkViewLeft}
                className={`card-btn ${this.props.dark ? "light" : "dark"}`}
                to={'/home'}
              >
                <SvgIcon>
                  <polygon fill={buttonColour('home')} points='0,21.3 6.9,19.5 6.9,0 0,1.8 ' />
                  <polygon fill={buttonColour('home')} points='16.9,21.6 23.8,19.8 23.8,0.3 16.9,2.1 ' />
                  <polygon fill={buttonColour('home')} points='8.4,19.5 15.3,21.6 15.3,2.1 8.4,0 ' />
                </SvgIcon>
              </Link>
              <Link
                style={!this.props.dark ? styles.DarkViewRight : styles.DarkViewRight}
                className={`card-btn ${this.props.dark ? "light" : "dark"}`}
                to={'/screens'}
              >
                <SvgIcon>
                  <g fill={buttonColour('screens')}>
                    <g>
                      <path
                        d='M0,9.9h9.9V0H0V9.9z M0,21.7h9.9v-9.9H0V21.7z
                        M11.8,9.9h9.9V0h-9.9V9.9z M11.8,21.7h9.9v-9.9h-9.9V21.7z'
                      />
                    </g>
                  </g>
                </SvgIcon>
              </Link>
              <img alt='search' src={this.props.dark ? search_dark : search} style={styles.SearchIcon} />
              <InputBase
                type='search'
                className={this.props.dark ? "light" : "dark"}
                value={this.props.searchFilter}
                onChange={this.handleSearchChange}
                placeholder={"Locatie zoeken"}
              />
              <IconButton
                onClick={this.props.clearSearchFilter}
                style={styles.ClearBtn}
                iconStyle={styles.ClearBtnIcon}
              >
                {this.props.searchFilter.length ? <img src={CloseIcon} width={18} height={19} /> : null}
              </IconButton>
            </div>
          </Toolbar>
        </div>
      )
    }
}

ScreensToolbar.propTypes = {
  setSearchFilter: PropTypes.func.isRequired,
  searchFilter: PropTypes.string.isRequired,
  clearSearchFilter: PropTypes.func.isRequired,
  url: PropTypes.any,
  dark: PropTypes.bool,
}
