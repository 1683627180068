import { connect } from 'react-redux'
import { requestResetPassword, usernameChange } from '../../../../../store/authentication'

import { withRouter } from 'react-router'
import Forgot from '../components/Forgot'

const mapActionCreators = {
  requestResetPassword,
  usernameChange
}

const mapStateToProps = (state) => ({
  isMobile: state.isMobile,
  fetching: state.auth.fetching,
  username: state.auth.username
})

export default withRouter(connect(mapStateToProps, mapActionCreators)(Forgot))
