import PropTypes from 'prop-types'
// Layout HOC
import React from 'react'
import {
  Card,
  CardHeader,
  CardContent
}
  from '@material-ui/core'
import styled from 'styled-components'

export const Header = styled.div`
  display: block;
  font-size: x-large;
  font-weight: 300;
  line-height: 32px;
  color: 'red';
`

export const ScreenCard = ({
  children,
  title = null,
  subtitle = null,
  onClick = null,
  initiallyExpanded = false,
  expandable = false,
  avatar = null,
  isMobile = false,
  noPadding,
}) => {
  return (
    <div className='column-inner'>
      <Card
        elevation={0}
        square
        onClick={onClick}
        initiallyExpanded={initiallyExpanded}
      >
        {(title || subtitle) && (
          <CardHeader
            title={title}
            subtitle={subtitle}
            actAsExpander={expandable}
            showExpandableButton={expandable}
            avatar={avatar}
          />
        )}
        {children
          ? (
            <CardContent >
              {children}
            </CardContent>
          )
          : null}
      </Card>
    </div>
  )
}

ScreenCard.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  subtitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]),
  initiallyExpanded: PropTypes.any,
  expandable: PropTypes.any,
  avatar: PropTypes.element,
  noPadding: PropTypes.any,
  isMobile: PropTypes.any,
}

export default ScreenCard
