import PropTypes from 'prop-types'
import React from 'react'

const Styles = {
  wrapper: {
    position: 'relative',
    height: '100vh'

  },
  container: {
    margin: '0',
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)' }
}

export const CenteredLabel = ({ label }) => {
  return (
    <div style={Styles.wrapper}>
      <div style={Styles.container}>
        {label}
      </div>
    </div>)
}

export default CenteredLabel

CenteredLabel.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element
  ]).isRequired

}
