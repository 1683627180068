import { connect } from 'react-redux'
import {
  getRole,
  editRole,
  addPermissionToRole,
  removePermissionFromRole,
  getRoleUsers,
  addUserToRole,
  deleteRole,
  removeUserFromRole
} from '../../../../../../../store/role'

/*  This is a container component. Notice it does not contain any JSX,
 nor does it import React. This component is **only** responsible for
 wiring in the actions and state necessary to render a presentational
 component - in this case, the role:   */

import Role from '../components/Role'

/*  Object of action creators (can also be function that returns object).
 Keys will be passed as props to presentational components. Here we are
 implementing our wrapper around increment; the component doesn't care   */

const mapDispatchToProps = {
  getRole,
  editRole,
  addPermissionToRole,
  removePermissionFromRole,
  getRoleUsers,
  addUserToRole,
  deleteRole,
  removeUserFromRole
}

const mapStateToProps = (state) => ({
  name : state.role.role.name,
  id : state.role.role.id,
  permissions : state.role.permissions,
  myPermissions: state.me.permissions,
  users : state.role.users
})

export default connect(mapStateToProps, mapDispatchToProps)(Role)
