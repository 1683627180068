import PropTypes from 'prop-types'
import React from 'react'
import { Card, CardText } from 'material-ui/Card'
import CardTitle from '../../../../../../../components/CardTitle'
import ScreenTable from './ScreenTable'

export const ViewScreens = (props) => (
  <Card className={props.className}>
    <CardTitle title={props.title} />
    <CardText>
      {props.screens.length ? <ScreenTable {...props} /> : 'this group has no screens'}
    </CardText>
  </Card>
)

ViewScreens.propTypes = {
  screens: PropTypes.arrayOf(
    PropTypes.shape({
      screenName: PropTypes.string.isRequired,
      techName:   PropTypes.string
    })
  ).isRequired,
  className: PropTypes.string,
  title:  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ])
}

export default ViewScreens
