import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ScreenCard from './ScreenCard'
import Switch from '@material-ui/core/Switch'
import './styles.scss'
import messages from '../constants/messages'
import { FormattedMessage } from 'react-intl'

export class SenderBox extends Component {

  switchedToScreenControl (switcherStatus) {
    return switcherStatus === 'Screen Control'
  }

  render () {
    const { switcherOutput, senderBoxHandleClick, senderBoxLoading } = this.props
    return(
      <ScreenCard
      >
        <div className='sender-box-wrapper'>
          <div>
            <h5><FormattedMessage {...messages.senderbox} /></h5>
          </div>
          <div className='usb-switcher-indicator'>
            <h4>External Device</h4>
            <Switch
              checked={this.switchedToScreenControl(switcherOutput)}
              disabled
              color='primary'
            />
            <h4>Screen Control</h4>
          </div>
          <div className='sender-box-button-wrapper'>
            <div
              className='switcher-button'
              onClick={() => senderBoxHandleClick()}
            >
              {
                senderBoxLoading ?
                  'Loading...'
                  : 'Trigger usb switch'
              }
            </div>
          </div>
        </div>
      </ScreenCard>
    )
  }
}

SenderBox.propTypes = {
  senderBoxHandleClick: PropTypes.func.isRequired,
  senderBoxLoading: PropTypes.bool,
  switcherOutput: PropTypes.string
}

export default SenderBox
