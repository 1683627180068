import { connect } from 'react-redux'
import { history } from '../store'
import { withRouter } from 'react-router'

import Notices from '../components/Notice/Notices'
import { removeAllMessages, popMessage } from '../store/notice'

const mapActionCreators = {
  removeAllMessages,
  popMessage
}

const mapStateToProps = (state) => ({
  notices: state.notice,
  location: history.location.pathname || ''
})

export default withRouter(connect(mapStateToProps, mapActionCreators)(Notices))
