import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Edit from './Edit'
import View from './View'
import messages from '../constants/messages.js'
import { Background } from '../../../../../../../constants/colors'
import { EDIT_GROUP, VIEW_GROUP } from '../../../../../../../constants/acl'
import './Group.scss'

export class Group extends Component {

  componentWillMount () {
    const id = this.props.match.params.id
    this.props.getGroup(id)
  }

  render () {
    const { permissions } = this.props
    return (
      <div style={{ backgroundColor: Background }}>
        {permissions.includes(EDIT_GROUP)
          ? <Edit {...this.props} />
          : permissions.includes(VIEW_GROUP)
            ? <View messages={messages} {...this.props} />
            : null
        }
      </div>
    )
  }
}

Group.propTypes = {
  match: PropTypes.object.isRequired,
  permissions: PropTypes.array.isRequired,
  getGroup: PropTypes.func.isRequired
}

export default Group
