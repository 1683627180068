import React from 'react'
import PropTypes from 'prop-types'
import RaisedButton from 'material-ui/RaisedButton'
import withPermissions from '../../../../../../containers/withPermissions'
import { ACKNOWLEDGE_INCIDENTS } from '../../../../../../constants/acl'

export const AcknowledgeButton = ({ onClick }) => (
  <RaisedButton
    onClick={onClick}
    style={{ marginTop: 20 }}
    primary
    className={'ackButton'}
    label='Acknowledge'
    fullWidth
    permission={[ACKNOWLEDGE_INCIDENTS]}
  />
)

AcknowledgeButton.propTypes = {
  onClick: PropTypes.func.isRequired
}

export const AcknowledgeButtonWithPermissions = withPermissions(AcknowledgeButton)

export default AcknowledgeButtonWithPermissions
