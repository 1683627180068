import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Card, CardActions, CardText } from 'material-ui/Card'
import { FormattedMessage } from 'react-intl'
import Button from 'material-ui/RaisedButton'
import TextField from 'material-ui/TextField'
import * as colors from "../../../../../../../constants/colors"

export class EditGroup extends Component {
  constructor (props) {
    super(props)
    this.state = {
      name: props.name
    }
  }

  componentWillReceiveProps (nextProps) {
    this.setState({
      name: nextProps.name
    })
  }

  handleInputChange = (evt) => {
    const target = evt.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value
    })
  }

  handleSubmit = (evt) => {
    evt.preventDefault()
    this.props.editGroup(this.props.id, this.state.name.trim())
  }

  render () {
    return (
      <Card >
        <CardText style={{ paddingTop: '0', paddingBottom: '0' }}>
          <form onSubmit={this.handleSubmit}>
            <TextField fullWidth type='text' name='name' value={this.state.name} onChange={this.handleInputChange}
              style={{ fontFamily: 'Interface' }}
              hintText={<FormattedMessage {...this.props.messages.editGroupNameHintText} />}
              floatingLabelText={<FormattedMessage {...this.props.messages.editGroupNameFloatingLabelText} />}
            />
            <CardActions>
              <Button
                type='submit'
                value='submit'
                label={<FormattedMessage {...this.props.messages.submit} />}
                disabled={this.state.name === 0}
              />
              <Button
                type='button'
                value='delete'
                onClick={() => {this.props.deleteGroup(this.props.id)}}
                buttonStyle={{ backgroundColor:colors.Panic }}
                labelColor={'white'}
                label={<FormattedMessage {...this.props.messages.delete} />}
              />
            </CardActions>
          </form>
        </CardText>
      </Card>
    )
  }
}

EditGroup.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  editGroup: PropTypes.func.isRequired,
  deleteGroup: PropTypes.func.isRequired,
  messages: PropTypes.object.isRequired
}

export default EditGroup
