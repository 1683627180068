import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Group from '../routes/Group'
import Groups from '../containers/GroupsContainer'
import CreateGroup from '../routes/CreateGroup'
export const AppLayout = props => {
  return(
    <div>
      <Switch>
        <Route path={'/management/groups/create'} component={CreateGroup} />
        <Route path={'/management/groups/:id'} component={Group} />
        <Route path={'/management/groups'} component={Groups} />
      </Switch>
    </div>
  )
}
export default AppLayout