import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'
/* eslint-disable */
const Icon = (props) => (
  <SvgIcon {...props}>
    <g>
      <g>
        <path fill='none'
          stroke={props.color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit='10'
          d='M19.504,14.291 h-0.007c-0.784,1-2.249,1.43-3.997,0.209v5.924c0,0,0.58,2.119,4,3.076c3.42-0.957,4-3.076,4-3.076V14.5 C21.777,15.705,20.298,15.306,19.504,14.291z' />
        <line fill='none'
          stroke={props.color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit='10'
          x1='19.5' y1='20.5' x2='19.5' y2='16.5' />
        <line fill='none'
          stroke={props.color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit='10'
          x1='17.5'
          y1='18.5'
          x2='21.5'
          y2='18.5' />
      </g>
      <path fill='none'
        stroke={props.color}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        d='M13.966,13.354 c-0.777-0.256-1.615-0.533-2.466-0.854V10c0,0,1.5-0.619,1.5-3c0.782,0,0.782-2.07,0-2.07c0-0.235,0.828-1.608,0.5-2.93 C13.025,0.096,7.474,0.096,7,2C4.631,1.523,6,4.681,6,5C5.217,5,5.217,7,6,7c0,2.381,1.5,3,1.5,3v2.5 c-2.778,1.057-6.088,1.756-6.5,3c-0.475,1.43-0.5,4-0.5,4H11' />
    </g>

  </SvgIcon>
)
/* eslint-enable */
export default Icon
