import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Tabs, Tab } from 'material-ui/Tabs'
import { VIEW_GROUP, VIEW_USER, VIEW_ROLE } from '../../../constants/acl'
import * as routes from '../../../constants/routes'
import PermissionsIcon from '../../../assets/icons/permissions'
import ScreensIcon from '../../../assets/icons/screens'
import UserManagentIcon from '../../../assets/icons/userManagement'

export class ACL extends Component {

  handleActive = (tab) => {
    const route = tab.props['data-route']
    this.props.history.push(`/${routes.ACL}` + route)
  }

  getSelectedIndex = () => {
    const pathname = this.props.history.location.pathname
    if (pathname.includes(routes.USERS)) {
      return 0
    } else if (pathname.includes(routes.GROUPS)) {
      return 1
    } else if (pathname.includes(routes.ROLES)) {
      return 2
    } else {
      return 0
    }
  }

  render () {
    const { children, permissions, history } = this.props
    const pathname = history.location.pathname
    const numberOfPathComponents = pathname.split('/').length
    const isTabsHidden = numberOfPathComponents >= 4  // first slash adds an extra component/array element

    if(!permissions){
      return null
    }
    const tabs = isTabsHidden ?
      null
      : (
        <Tabs initialSelectedIndex={this.getSelectedIndex()}>
          {permissions.includes(VIEW_USER)
            ? (
              <Tab icon={<UserManagentIcon color='#fff' />}
                data-route={`/${routes.USERS}`}
                onActive={this.handleActive}
              />
            )
            : null
          }
          {permissions.includes(VIEW_GROUP)
            ? (
              <Tab icon={<ScreensIcon color='#fff' />}
                data-route={`/${routes.GROUPS}`}
                onActive={this.handleActive}
              />
            )
            : null
          }
          {permissions.includes(VIEW_ROLE)
            ? (
              <Tab
                icon={<PermissionsIcon color='#fff' />}
                data-route={`/${routes.ROLES}`}
                onActive={this.handleActive}
              />
            )
            : null
          }
        </Tabs>
      )

    return (
      <div>
        <div>
          {tabs}
        </div>
        {children}
      </div>

    )
  }
}

ACL.propTypes = {
  children: PropTypes.element,
  permissions: PropTypes.array.isRequired,
  history: PropTypes.object
}

export default ACL
