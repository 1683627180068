import PropTypes from 'prop-types'
import React from 'react'
import { List } from 'material-ui/List'
import Slider from 'react-slick'
import { ScreensCount, SliderHeader } from './StyledComponents'

const Styles = {
  list: {
    paddingTop: 0,
    overflowY: 'none', // makes scroll faster on iOS
    WebkitOverflowScrolling: 'touch', // makes scroll faster on iOS
    marginTop: 1 // required to avoid overflowing the bar
  },
  grid: {
    padding: '0 16px 0px 16px',
    overflowY: 'none', // makes scroll faster on iOS
    WebkitOverflowScrolling: 'touch', // makes scroll faster on iOS
    marginTop: 1 // required to avoid overflowing the bar
  }

}

export class SliderRecyclerView extends React.Component {

  render () {
    const { content, numberOfColumns, isMobile, height, sliderSettings } = this.props
    const standAlonePadding = window.navigator.standalone === true ? 20 : 0
    const listPadding = isMobile ? 10 : 0
    const extraPadding = standAlonePadding + 120
    const calculatedHeight = height - extraPadding + listPadding
    let countedItems = 0
    content.map((grouped, key) => {
      countedItems = countedItems + grouped.length
    })
    return (isMobile
      ? (
        <div>
          <SliderHeader>
            <ScreensCount>
              All (0 - {countedItems})
            </ScreensCount>
          </SliderHeader>
          <List style={{ ...Styles.list, height: calculatedHeight }}>
            {content}
          </List>
        </div>
      )
      : (
        <div>
          <Slider ref={this.props.setRef} {...sliderSettings} style={{ marginLeft: '15px', marginRight: '15px' }}>
            {
              content.map((groupedItems, i) =>
                <div key={i}>
                  <List
                    style={{ ...Styles.grid, height: 'auto', 'width': '100%', 'padding':'0' }}
                    cellHeight={'auto'}
                    cols={numberOfColumns}
                    padding={2}
                  >
                    {groupedItems.map((item) => item)}
                  </List>
                </div>
              )
            }
          </Slider>
        </div>
      )

    )
  }
}

SliderRecyclerView.propTypes = {
  height: PropTypes.number.isRequired,
  isMobile: PropTypes.any.isRequired,
  content: PropTypes.array.isRequired,
  numberOfColumns: PropTypes.number.isRequired,
  sliderSettings: PropTypes.any,
  setRef: PropTypes.func
}

export default SliderRecyclerView

