import { defineMessages } from 'react-intl'

export default defineMessages({
  searchPlaceholder: {
    id: 'Screens.component.search.placeholder',
    defaultMessage: 'Search locations'
  },
  searchHint: {
    id: 'Screens.component.search.hint',
    defaultMessage: 'Search Locations'
  },
  noScreensFound: {
    id: 'Screens.component.none.found',
    defaultMessage: 'No screens found'
  }
})
