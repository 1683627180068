import Map from '../components/Map'
import { connect } from 'react-redux'
import { getScreens } from '../../../store/screens'
import { getVisibleSortedScreens } from '../selectors/screenSelectors'
import { withRouter } from 'react-router'

const mapDispatchToProps = {
  getScreens
}

const mapStateToProps = (state) => ({
  screens: getVisibleSortedScreens(state),
  isMobile: state.isMobile,
  loading: state.screens.fetching
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Map))
