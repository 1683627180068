import { White } from '../../../constants/colors'

export const styles = {
  ClearBtnIcon: {
    color: White,
    opacity: 0.6,
    padding: 2
  },
  ClearBtn: {
    marginRight: '0px',
    padding: '14px !important'
  },
  Toolbar: {
    backgroundColor: 'transparent',
    padding: '0',
  },
  MobileToolbar: {
    backgroundColor: 'transparent',
    padding: '0',
    marginLeft: 15
  },
  ToolbarGroup: {
    width: '100%'
  },
  SearchIcon: {
    opacity: 0.6,
    height: 23,
    marginLeft: -2,
    marginRight: 8,
    marginBottom: -2
  },
  DarkViewRight: {
    marginRight: 20,
    border: 0,
    cursor: 'pointer'
  },
  DarkViewLeft: {
    marginRight: 2,
    border: 0,
    cursor: 'pointer'
  }

}

export default styles
