import { connect } from 'react-redux'
import { setSearchFilter, clearSearchFilter } from '../../store/screens'

/*  This is a container component. Notice it does not contain any JSX,
 nor does it import React. This component is **only** responsible for
 wiring in the actions and state necessary to render a presentational
 component - in this case, the screens:   */

import Toolbar from './Toolbar'

/*  Object of action creators (can also be function that returns object).
 Keys will be passed as props to presentational components. Here we are
 implementing our wrapper around increment; the component doesn't care   */

const mapDispatchToProps = {
  setSearchFilter,
  clearSearchFilter
}

const mapStateToProps = (state) => ({
  searchFilter : state.screens.searchFilter
})

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar)
