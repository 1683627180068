import PropTypes from 'prop-types'
import React from 'react'
import Divider from 'material-ui/Divider'
import { ListItem } from 'material-ui/List'
import { Candela } from '../../../../../constants/colors'

const Styles = {
  listItem: {
    display: 'inline-block',
    maxWidth: '100%',
    width: '100%',
    textOverflow: 'ellipsis',
    whiteSpace: 'no-wrap',
    overflow: 'hidden',
    fontFamily: 'Didot'
  }
}

export const Group = ({ id, name, history }) => {
  return (
    <div style={{ backgroundColor: 'white' }}>
      <ListItem
        insetChildren={false}
        style={Styles.listItem}
        onClick={() => {
          const pathname = history.location.pathname
          history.push(`${pathname}/${id}`)
        }}
      >
        <div className='group-wrapper'>
          <div className='groups-name'>{name}</div>
        </div>
      </ListItem>

      <Divider inset={false} style={{ backgroundColor: Candela }} />
    </div>
  )
}

Group.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  history: PropTypes.any
}

export default Group
