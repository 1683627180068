import { connect } from 'react-redux'
import {
  getGroup,
  editGroup,
  deleteGroup,
  addScreenToGroup,
  removeScreenFromGroup,
  addUserToGroup,
  removeUserFromGroup
} from '../../../../../../../store/group'

import { getSortedScreens } from '../selectors/selectors'

/*  This is a container component. Notice it does not contain any JSX,
 nor does it import React. This component is **only** responsible for
 wiring in the actions and state necessary to render a presentational
 component - in this case, the group:   */

import Group from '../components/Group'

/*  Object of action creators (can also be function that returns object).
 Keys will be passed as props to presentational components. Here we are
 implementing our wrapper around increment; the component doesn't care   */

const mapDispatchToProps = {
  getGroup,
  editGroup,
  deleteGroup,
  addScreenToGroup,
  removeScreenFromGroup,
  addUserToGroup,
  removeUserFromGroup
}

const mapStateToProps = (state) => ({
  name : state.group.group.name,
  id : state.group.group.id,
  screens : getSortedScreens(state),
  users : state.group.users,
  permissions: state.me.permissions
})

export default connect(mapStateToProps, mapDispatchToProps)(Group)
