import { fetchFromSlimAPI } from '../libs/api'
import { startFetching, stopFetching } from './fetching'
import * as actions from '../constants/actions'

// ------------------------------------
// Actions
// ------------------------------------

export const screensChange = payload => ({
  type : actions.UPDATE_SCREENS,
  payload
})

export const setSearchFilter = (payload) => {
  return {
    type: actions.SET_SEARCH_FILTER,
    payload
  }
}

export const clearSearchFilter = () => {
  return {
    type: actions.CLEAR_SEARCH_FILTER
  }
}

// ------------------------------------
// Async Actions
// ------------------------------------

export const getScreens = (reloading = false) => {
  return (dispatch) => {
    if (!reloading) dispatch(startFetching())
    dispatch(fetchFromSlimAPI('screen/getscreens', 'get', undefined, () => {
    })).then((success) => {
      if (!reloading) dispatch(stopFetching())
      if (success && !success.code) {
        dispatch(screensChange([...success]))
      }
    })
  }
}

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  screens: [],
  searchFilter: '',
  fetching: false
}

export const screensReducer = (state = initialState, action) => {

  switch (action.type) {

  case actions.UPDATE_SCREENS:
    return { ...state, screens: action.payload }

  case actions.SET_SEARCH_FILTER:
    return { ...state, searchFilter: action.payload }

  case actions.CLEAR_SEARCH_FILTER:
    return { ...state, searchFilter: '' }

  default: return state
  }

}

export default screensReducer
