import { connect } from 'react-redux'
import { changePassword } from '../../../../../store/authentication'
import { withRouter } from 'react-router'

import Reset from '../components/Reset'

const mapActionCreators = {
  changePassword
}

const mapStateToProps = (state) => ({

})

export default withRouter(connect(mapStateToProps, mapActionCreators)(Reset))
