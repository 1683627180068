import PropTypes from 'prop-types'
import React from 'react'
import { Table, TableBody, TableHeaderColumn, TableRow, TableRowColumn } from 'material-ui/Table'

export const ScreenTable = ({ screens, techNameLabel, nameLabel }) => (
  <Table selectable={false}>
    <TableBody displayRowCheckbox={false}>
      <TableRow>
        <TableHeaderColumn>{nameLabel}</TableHeaderColumn>
        <TableHeaderColumn className='group-screens-secondary-column'>{techNameLabel}</TableHeaderColumn>
      </TableRow>
      {screens
        .sort((lhs, rhs) => lhs.screenName - rhs.screenName)
        .map((screen, i) =>
          <TableRow key={i}>
            <TableRowColumn>{screen.screenName}</TableRowColumn>
            <TableRowColumn className='group-screens-secondary-column'>{screen.techName}</TableRowColumn>
          </TableRow>
        )}
    </TableBody>
  </Table>
)

ScreenTable.propTypes = {
  screens: PropTypes.arrayOf(
    PropTypes.shape({
      screenName: PropTypes.string.isRequired,
      techName:   PropTypes.string
    })
  ).isRequired,
  nameLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  techNameLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ])
}

export default ScreenTable
