import PropTypes from 'prop-types'
import React, { Component } from 'react'
import List from '../../../../../components/ScrollList'
import { CREATE } from '../../../../../constants/routes'
import { Background } from '../../../../../constants/colors'
import FloatingActionButton from '../../../../../components/FABwithPermissions'
import Group from './Group'
import { CREATE_GROUP } from '../../../../../constants/acl'
import './groups.scss'

const styles = {
  root: {
    backgroundColor: Background
  }
}

export class Groups extends Component {

  componentWillMount () {
    this.props.getGroups()
  }

  toCreateGroup () {
    const pathname = this.props.history.location.pathname
    this.props.history.push(`${pathname}/${CREATE}`)
  }

  render () {
    const allGroups = (
      <div style={styles.root}>
        <List>
          {this.props.groups.map((group, i) => <Group key={group.id} {...this.props} {...group} />)}
        </List>
        <FloatingActionButton permission={CREATE_GROUP} onClick={() => this.toCreateGroup()} />
      </div>
    )
    return (this.props.children) || allGroups
  }
}

Groups.propTypes = {
  groups: PropTypes.array.isRequired,
  getGroups: PropTypes.func.isRequired,
  children: PropTypes.element,
  history: PropTypes.object
}

export default Groups
