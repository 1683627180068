import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TextField from 'material-ui/TextField'
import LoginButton from '../../../containers/LoginButtonContainer'
import PasswordStrength from '../../../../../components/PasswordStrength'
import { FormattedMessage } from 'react-intl'
import messages from './messages'

export class Reset extends Component {
  constructor (props) {
    super(props)
    const pathname = this.props.location.pathname
    let token = pathname.slice(pathname.lastIndexOf('/') + 1)

    this.state = {
      password: '',
      confirmPassword: '',
      errorText: '',
      isSubmitButtonDisabled: true,
      token
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleInputChange (evt) {
    const { value, name } = evt.target

    this.setState({
      [name]: value
    })

    if (name === 'password') {
      this.validatePasswordEquality(value, this.state.confirmPassword)
    } else {
      this.validatePasswordEquality(this.state.password, value)
    }
  }

  validatePasswordEquality (password, confirmPassword) {
    const didPasswordConfirmationStart = confirmPassword.length > 0
    const isPasswordConfirmationEqual = password === confirmPassword

    if (!didPasswordConfirmationStart || isPasswordConfirmationEqual) {
      const isSubmitButtonEnabled = didPasswordConfirmationStart && isPasswordConfirmationEqual
      this.setState({
        errorText: '',
        isSubmitButtonDisabled: !isSubmitButtonEnabled
      })
    } else if (didPasswordConfirmationStart && !isPasswordConfirmationEqual) {
      this.setState({
        errorText: <FormattedMessage {...messages.passwordsNotIdentical} />,
        isSubmitButtonDisabled: true
      })
    }
  }

  handleSubmit (evt) {
    evt.preventDefault()
    this.props.changePassword(this.state.password.trim(), this.state.confirmPassword.trim(), this.state.token)
  }

  render () {
    return (
      <form onSubmit={this.handleSubmit} className='login-form'>
        <div className='login-textfield-wrapper'>
          <TextField
            fullWidth
            name='password'
            type='password'
            hintText={<FormattedMessage {...messages.password} />}
            value={this.state.password}
            autoFocus
            style={{ fontFamily: 'Interface' }}
            onChange={this.handleInputChange}
          />
          <TextField
            fullWidth
            type='password'
            hintText={<FormattedMessage {...messages.repeatPassword} />}
            name='confirmPassword'
            errorText={this.state.errorText}
            value={this.state.confirmPassword}
            style={{ fontFamily: 'Interface' }}
            onChange={this.handleInputChange}
          />
          <PasswordStrength password={this.state.password} />
        </div>
        <LoginButton
          label={<FormattedMessage {...messages.resetPassword} />}
          disabled={this.state.isSubmitButtonDisabled}
        />
      </form>
    )
  }
}

Reset.propTypes = {
  changePassword: PropTypes.func.isRequired,
  history: PropTypes.object,
  location: PropTypes.object
}

export default Reset
