import PropTypes from 'prop-types'
import React from 'react'
import LinearProgress from 'material-ui/LinearProgress'
import { scorePassword } from '../../utils/passwordStrength'
import messages from './messages'
import { FormattedMessage } from 'react-intl'
import {
  redA700,
  deepOrangeA700,
  orangeA700,
  amberA700,
  yellowA700,
  limeA700,
  lightGreenA700,
  greenA700 } from 'material-ui/styles/colors'

export const strengthColor = (score) => {
  // very weak
  if (score < 10) return redA700
  else if (score < 20) return deepOrangeA700
  else if (score < 30) return orangeA700
  // weak
  else if (score < 50) return amberA700
  else if (score < 70) return yellowA700
  // ok
  else if (score < 100) return limeA700
  // strong
  else if (score < 140) return lightGreenA700
  // very strong
  return greenA700
}

export const strengthText = (score) => {
  if (score < 40) return messages.veryWeak
  else if (score < 70) return messages.weak
  else if (score < 100) return messages.ok
  else if (score < 140) return messages.strong
  return messages.veryStrong
}

const PasswordStrength = ({ password }) => {
  const score = scorePassword(password)
  const color = strengthColor(score)
  return (
    <div style={{ margin: '5px 0' }}>
      <LinearProgress
        mode='determinate'
        value={score}
        max={170}
        color={color}
      />
      <div style={{ padding: '5px 0', color: color }}>
        {score
          ? (
            <div>
              <FormattedMessage {...strengthText(score)} />
            </div>
          )
          : ''}
      </div>
    </div>
  )
}

PasswordStrength.propTypes = {
  password: PropTypes.string.isRequired
}

export default PasswordStrength
