import * as actions from '../constants/actions'
// ------------------------------------
// Actions
// ------------------------------------

/**
 * Sets the errorMessage state, which displays the ErrorMessage component when it is not empty
 * @param message
 */
export function setErrorMessage (message) {
  return setNotice(message, 'error')
}

export function setSuccessMessage (message) {
  return setNotice(message, 'success')
}

export function removeAllMessages () {
  return {
    type: actions.CLEAR_ALL_NOTICES
  }
}

export function popMessage () {
  return {
    type: actions.CLEAR_NOTICE
  }
}

export function setNotice (message, messageType) {
  return {
    type: actions.SET_NOTICE,
    message,
    messageType
  }
}

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = []

export const noticeReducer = (state = initialState, action) => {

  switch (action.type) {

  case actions.SET_NOTICE:
    return ([
      {
        message: action.message,
        type: action.messageType
      },
      ...state
    ])

  case actions.CLEAR_NOTICE:
    return (
      Array.isArray(state) && state.length ? state.slice(0, -1) : state
    )

  case actions.CLEAR_ALL_NOTICES:
    return ([])

  default: return state
  }

}

export default noticeReducer
