import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'Users.components.User.title',
    defaultMessage: `Edit user {user}`
  },
  usernameFloatingLabelText: {
    id: 'Users.components.User.usernameFloatingLabelText',
    defaultMessage: 'username'
  },
  passwordFloatingLabelText: {
    id: 'Users.components.User.passwordFloatingLabelText',
    defaultMessage: 'password'
  },
  companyFloatingLabelText: {
    id: 'Users.components.User.company',
    defaultMessage: 'company'
  },
  apiTokenFloatingLabelText: {
    id: 'Users.components.Createuser.api_token',
    defaultMessage: 'api token'
  },
  titleFloatingLabelText: {
    id: 'Users.components.User.titleFloatingLabelText',
    defaultMessage: 'title'
  },
  nameFloatingLabelText: {
    id: 'Users.components.User.name',
    defaultMessage: 'email'
  },
  submit: {
    id: 'Users.components.User.submit',
    defaultMessage: 'Edit user'
  },
  delete: {
    id: 'Users.components.User.delete',
    defaultMessage: 'Delete user'
  },
  invalidEmail: {
    id: 'Users.components.invalidEmail',
    defaultMessage: 'Email address is invalid'
  },
  editUserSuccess: {
    id: 'Users.components.User.edit.success',
    defaultMessage: `User {username} was successfully updated`
  },
  deleteUserSuccess: {
    id: 'Users.components.User.delete.success',
    defaultMessage: `User was successfully deleted`
  }
})
