import moment from 'moment'

function initialDateTime () {
  const endMoment = moment()
  const startMoment = moment().subtract(1, 'days').subtract(1, 'hours')
  const dateFormat = 'YYYY-MM-DD'
  const timeFormat = 'HH-mm-ss'

  const data = {
    startDate: startMoment.format(dateFormat),
    startTime:  startMoment.format(timeFormat),
    endDate:  endMoment.format(dateFormat),
    endTime:  endMoment.format(timeFormat)
  }
  return data
}
export default initialDateTime