import React from 'react'
import { Switch, Route } from 'react-router-dom'
import User from '../routes/User'
import Users from '../containers/UsersContainer'
import CreateUser from '../routes/CreateUser'
export const AppLayout = props => {
  return(
    <div>
      <Switch>
        <Route path={'/management/users/create'} component={CreateUser} />
        <Route path={'/management/users/:id'} component={User} />
        <Route path={'/management/users/'} component={Users} />
      </Switch>
    </div>
  )
}
export default AppLayout