import PropTypes from 'prop-types'
import React, { Component } from 'react'
import LoginForm from '../layouts/FormLayout'
import messages from './messages'
import { FormattedMessage } from 'react-intl'
import './Login.scss'
import { redirectIfLoggedIn } from '../../../store/auth'
import styled from 'styled-components'
import logoBlack from '../../../assets/logos/logo-dark.svg'
import Background from '../../../assets/images/login-bg@3x.png'

const Logo = styled.img`
  width: 100%;
  max-width: 58px;
  max-height: 61px;
  height: auto;
  margin: auto;
  display: block;
  object-fit: contain;
  margin-bottom: 20px;
`

export class Login extends Component {
  componentWillMount () {
    redirectIfLoggedIn()
  }

  render () {
    return (
      <div className='login-wrapper' style={{
        background: `url(${Background}) 100% 100% no-repeat fixed`
      }}>
        <div className='login-wrapper-inner'>
          <Logo src={logoBlack} alt={'Logo'} />
          <h1><FormattedMessage {...messages.applicationName} /></h1>
          <LoginForm />

        </div>
      </div>
    )
  }
}

Login.propTypes = {
  isMobile: PropTypes.any.isRequired,
  children: PropTypes.element.isRequired,
  history: PropTypes.object
}

export default Login
