import { timeSecond, timeMinute, timeHour, timeDay, timeWeek, timeMonth, timeYear } from 'd3-time'

/**
 * Intl.DateTimeFormat() options
 * see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
 * @type {{second: string}}
 */
const formatMillisecond = { second: 'numeric' }
const formatSecond = { minute: '2-digit', second: '2-digit' }
const formatMinute = { hour: 'numeric', minute: 'numeric' }
const formatHour = { hour: 'numeric', minute: 'numeric' }
const formatDay = { weekday: 'short', day: 'numeric' }
const formatWeek = { month: 'short', day: 'numeric' }
const formatMonth = { month: 'long' }
const formatYear = { year: 'numeric' }

/**
 * Conditional time formats
 * see https://github.com/d3/d3-time-format#d3-time-format
 * @param date
 * @returns {{second: string}} an object containing options for Intl.DateTimeFormat()
 */
export default function multiFormat (date) {
  return timeSecond(date) < date
    ? formatMillisecond
    : timeMinute(date) < date
      ? formatSecond
      : timeHour(date) < date
        ? formatMinute
        : timeDay(date) < date
          ? formatHour
          : timeMonth(date) < date
            ? timeWeek(date) < date
              ? formatDay
              : formatWeek
            : timeYear(date) < date
              ? formatMonth
              : formatYear
}
