import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Screens from '../containers/ScreensContainer'
import Screen from '../routes/Screen'
import Map from '../containers/MapContainer'

export const AppLayout = props => {
  return(
    <div>
      <Switch>
        <Route path={'/screens/:screen'} component={Screen} />
        <Route exact path={'/screens'} component={Screens} />
        <Route path={'/home'} component={Map} />
      </Switch>
    </div>
  )
}
export default AppLayout
