import { fetchFromSlimAPI } from '../libs/api'
import { setErrorMessage, setSuccessMessage } from './notice'
import { userAdded, userChanged, userDeleted } from './users'
import { history } from './index'
import messages from '../constants/messages'
import usermessages from '../routes/ACL/routes/Users/routes/User/constants/messages'
import * as routes from '../constants/routes'
import * as actions from '../constants/actions'

// ------------------------------------
// Actions Async
// ------------------------------------

export const getUser = (userID) => {
  return (dispatch) => {
    dispatch(fetchFromSlimAPI(`user/${userID}`, 'get', undefined, (success) => {
      if (success && !success.code) {
        dispatch(userFetched(success))
      }
    }))
  }
}

export const editUser = (userID, userObject) => {
  return (dispatch) => {
    // check if all fields are filled
    if (!userObject) {
      dispatch(setErrorMessage(messages.fieldMissing))
      return
    }
    let body = JSON.stringify(userObject)
    dispatch(fetchFromSlimAPI(`user/${userID}`, 'POST', body, (success) => success)).then(success => {
      if (success && !success.code) {
        history.push(`/${routes.ACL}/${routes.USERS}`)
        dispatch(userChanged({ id: Number(userID), ...userObject }))
        dispatch(setSuccessMessage({
          ...usermessages.editUserSuccess,
          values: { username: userObject.username }
        }))
      }
    })
  }
}

export const createUser = (username, password, name, title, company, api_token) => {
  return (dispatch) => {
    // check if all fields are filled
    if (!username.length || !password.length) {
      dispatch(setErrorMessage(messages.fieldMissing))
      return
    }
    let body = JSON.stringify({
      username: username,
      password: password,
      name: name,
      title: title,
      company: company,
      api_token: api_token
    })
    dispatch(fetchFromSlimAPI('user/create', 'post', body, (success) => {
      if (success && !success.code) {
        // redirect to users
        history.push(`/${routes.ACL}/${routes.USERS}`)
        let { id } = success
        dispatch(userAdded({ id, username, name, title, company, role: '', groups: [] }))
        dispatch(setSuccessMessage(messages.didCreateUser))
      } else {
        if (success.code === 406) dispatch(setErrorMessage(messages.usernameTaken))
        else dispatch(setErrorMessage(messages.generalError))
      }
    }))
  }
}

export const deleteUser = (userID) => {
  return (dispatch) => {
    // check if all fields are filled
    if (!userID) {
      dispatch(setErrorMessage(messages.fieldMissing))
      return
    }

    dispatch(fetchFromSlimAPI(`user/${userID}`, 'DELETE', null, (success) => success)).then(success => {
      if (success && !success.code) {
        history.push(`/${routes.ACL}/${routes.USERS}`)
        dispatch(userDeleted({ id: Number(userID) }))
        dispatch(setSuccessMessage({
          ...usermessages.deleteUserSuccess,
        }))
      }
    })
  }
}

// ------------------------------------
// Actions
// ------------------------------------

export function userFetched (user = initialState) {
  return {
    type: actions.USER_FETCHED,
    user
  }
}
export function clearUser () {
  return {
    type: actions.CLEAR_USER
  }
}

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  id: 0,
  name: '',
  username: '',
  role: '',
  title:  '',
  company: '',
  groups: []
}

export default function userReducer (state = initialState, action) {
  switch (action.type){
  case actions.USER_FETCHED:
    return action.user
  case actions.CLEAR_USER:
    return { ...state, ...initialState }
  default:
    return { ...state }
  }
}
