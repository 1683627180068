import { defineMessages } from 'react-intl'

export default defineMessages({
  fieldMissing: {
    id: 'error.field.missing',
    defaultMessage: 'Please fill out the entire form.'
  },
  userOrPasswordInvalid: {
    id: 'error.user.or.password.invalid',
    defaultMessage: 'Incorrect password or username.'
  },
  passwordsNotIdentical: {
    id: 'error.passwords.do.not.match',
    defaultMessage: 'Passwords should be equal.'
  },
  passwordResetRequested: {
    id: 'succes.password.reset.requested',
    defaultMessage: 'check your email for a password reset link'
  },
  passwordUpdated: {
    id: 'success.password.changed',
    defaultMessage: 'Successfully updated password.'
  },
  usernameTaken: {
    id: 'error.username.taken',
    defaultMessage: 'Sorry, but this username is already taken'
  },
  roleNameTaken: {
    id: 'error.role.name.taken',
    defaultMessage: 'Sorry, but this role-name is already taken'
  },
  generalError: {
    id: 'error.general',
    defaultMessage: 'Something went wrong, please try again'
  },
  loginExpired: {
    id: 'error.login.expired',
    defaultMessage: 'Login expired, please log in again'
  },
  resetExpired: {
    id: 'error.reset.expired',
    defaultMessage: 'Reset link expired, please request a new reset link'
  },
  loginMayBeExpired: {
    id: 'error.login.may.be.expired',
    defaultMessage: 'You are not logged in or your login is expired, please log in again'
  },
  unauthorized: {
    id: 'error.unauthorized',
    defaultMessage: 'Could not authenticate, please log in'
  },
  forbidden: {
    id: 'error.forbidden',
    defaultMessage: 'Sorry, but you have insufficient permissions'
  },
  forbiddenRoute: {
    id: 'error.forbidden.route',
    defaultMessage: 'Sorry, but you have insufficient permissions to view this page'
  },
  dayView: {
    id: 'date.day.view',
    defaultMessage: 'Day view'
  },
  weekView: {
    id: 'date.week.view',
    defaultMessage: 'Week view'
  },
  monthView: {
    id: 'date.month.view',
    defaultMessage: 'Month view'
  },

  yearView: {
    id: 'date.year.view',
    defaultMessage: 'Year view'
  },
  year: {
    id: 'date.year',
    defaultMessage: 'Year'
  },
  didCreateUser: {
    id: 'success.create.user',
    defaultMessage: 'did create user'
  },
  didCreateGroup: {
    id: 'success.create.group',
    defaultMessage: 'did create group'
  },
  didEditGroupName: {
    id: 'success.edit.group.name',
    defaultMessage: 'Changed groupname'
  },
  didRemoveGroup: {
    id: 'success.delete.group',
    defaultMessage: 'Removed group'
  },
  didAddScreenToGroup: {
    id: 'success.add.screenToGroup',
    defaultMessage: 'Added screen to group'
  },
  didRemoveScreenFromGroup: {
    id: 'success.remove.screenFromGroup',
    defaultMessage: 'Removed screen from group'
  },
  didAddUserToGroup: {
    id: 'success.add.userToGroup',
    defaultMessage: 'Added user to group'
  },
  didRemoveUserFromGroup: {
    id: 'success.remove.userFromGroup',
    defaultMessage: 'Removed user from group'
  },
  didRemoveuserFromGroup: {
    id: 'success.remove.userFromGroup',
    defaultMessage: 'Removed screen from group'
  },
  didRemoveRole: {
    id: 'success.remove.role',
    defaultMessage: 'Removed role'
  },
  didCreateRole: {
    id: 'success.create.role',
    defaultMessage: 'Created role'
  },
  didUpdateRoleName: {
    id: 'success.update.role.name',
    defaultMessage: 'Changed rolename'
  },
  didRemovePermissionFromRole: {
    id: 'success.remove.permission.from.role',
    defaultMessage: 'Removed permission from role'
  },
  didAddPermissionToRole: {
    id: 'success.add.permission.to.role',
    defaultMessage: 'Added permision to role'
  },
  didRemoveUserFromRole: {
    id: 'success.remove.user.from.role',
    defaultMessage: 'Removed user from role'
  },
  didAddUserToRole: {
    id: 'success.add.user.to.role',
    defaultMessage: 'Added user to role'
  }

})
