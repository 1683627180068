import { createSelector } from 'reselect'
import { sortScreens } from '../../../../../../../utils/screenSorting'

// Input Selectors
const getScreens = state => state.group.screens

// Reselect Selectors
export const getSortedScreens = createSelector(
  [ getScreens ],
  (screens) => sortScreens(screens)
)

export default getSortedScreens
