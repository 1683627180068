import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import checkPermissions from '../utils/permissions'
import { withRouter } from 'react-router'

/**
 * Higher Order Component to conditionally render components based on permissions
 * ...takes any component with a "permission" prop...
 * @param WrappedComponent
 * @returns {{class}}
 */
export function withPermissions (WrappedComponent) {
  // ...and returns another component...
  class ComponentWithPermissions extends Component {
    render () {
      // use the spread operator to pull variables off props, and put the remaining into a variable
      const { permission, permissions, ...rest } = this.props
      return checkPermissions(permissions, permission) ? (<WrappedComponent {...rest} />) : null
    }

    static propTypes = {
      permission : PropTypes.string.isRequired,
      permissions : PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.any
      ]).isRequired
    }
  }
  return withRouter(connect(mapStateToProps, {})(ComponentWithPermissions))
}

export default withPermissions

const mapStateToProps = (state) => ({
  permissions: state.me.permissions
})
