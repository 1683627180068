import jwtDecode from 'jwt-decode'

let localStorage = global.window.localStorage

const auth = {

  loggedIn () {
    return !!localStorage.token
  },

  logout (callback) {
    localStorage.removeItem('token')
    callback()
  },

  getToken () {
    return localStorage.token
  },

  modules () {

    if (localStorage.token === null || localStorage.token === undefined) { return [] }

    return jwtDecode(localStorage.token).data.modules || []
  }

}

export const updatePassword = (token) => { localStorage.token = token }

export default auth
