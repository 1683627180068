import PropTypes from 'prop-types'
import React, { Component } from 'react'
import View from './View'
import Edit from './Edit'
import './Role.scss'
import { Background } from '../../../../../../../constants/colors'
import { EDIT_ROLE, VIEW_ROLE } from '../../../../../../../constants/acl'

export class Role extends Component {

  componentWillMount () {
    const id = this.props.match.params.id
    this.props.getRole(id)
    this.props.getRoleUsers(id)
  }

  render () {
    const permissions = this.props.myPermissions
    return(
      <div style={{ backgroundColor: Background }}>
        {permissions.includes(EDIT_ROLE)
          ? <Edit {...this.props} />
          : permissions.includes(VIEW_ROLE)
            ? <View {...this.props} />
            : null
        }
      </div>
    )
  }
}

Role.propTypes = {
  match: PropTypes.object.isRequired,
  myPermissions: PropTypes.array.isRequired,
  getRole: PropTypes.func.isRequired,
  getRoleUsers: PropTypes.func.isRequired,
  deleteRole: PropTypes.func.isRequired
}

export default Role
