import React from 'react'
import { Button, Popover } from '@material-ui/core'
import FilterIcon from "../../assets/icons/filter.svg"
import FilterIconWhite from "../../assets/icons/filter-white.svg"
import MapCheckBox from "../MapCheckBox"
import "./popover.scss"
import PropTypes from "prop-types"

function MapFilterButton (props) {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <div style={{ position: 'fixed', left: '3%', bottom: "4.5%" }}>
      <div>
        <Button
          style={{
            borderRadius: '50%',
            height: "64px",
            width: "64px",
            boxShadow: "0 16px 48px 0 rgba(0, 0, 0, 0.5)",
          }}
          aria-describedby={id}
          variant='contained'
          onClick={handleClick}
          color={open ? 'white' : 'primary'}
        >
          <img
            src={open ? FilterIcon : FilterIconWhite}
            alt={"Filter"}
            width={26}
            height={25}
          />
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: -90,
            horizontal: 90,
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          elevation={false}
          className='popover_class'
          style={{ borderRadius: "0" }}
        >
          {/* Map checkboxes */}
          <MapCheckBox
            updateStatusStrings={props.updateStatusStrings}
          />
        </Popover>
      </div>
    </div>
  )
}
MapFilterButton.propTypes = {
  updateStatusStrings: PropTypes.any.isRequired
}
export default MapFilterButton
