import PropTypes from 'prop-types'
import React from 'react'
import { MAPBOX_API_ACCESS_TOKEN } from "../../../constants/api"
import ReactMapGL, { NavigationControl, FullscreenControl, Marker, Popup } from 'react-map-gl'
import PinGreen from "../../../assets/icons/pins-green.svg"
import PinRed from "../../../assets/icons/pins-red.svg"
import PinYellow from "../../../assets/icons/pins-yellow.svg"
import PinGrey from "../../../assets/icons/pins-grey.svg"
import OK from "../../../assets/icons/ok.svg"
import ERROR from "../../../assets/icons/error.svg"
import CardImage from "../../../assets/images/cardimage.png"
import { SCREENS } from "../../../constants/routes"

export function ScreensMap (props) {

  const navControlStyle= {
    right: 20,
    bottom: 20
  }
  const fullscreenControlStyle= {
    right: 20,
    top: 20
  }
  const [viewport, setViewport] = React.useState({
    width: 400,
    height: 400,
    latitude: 52.1129919,
    longitude: 5.2793703,
    zoom: 7
  })

  const { screens } = props
  const toScreen = () => {
    props.history.push(`/${SCREENS}/${props.popupInfo.id}`)
  }

  const getPin = (statusString) => {
    if(statusString === 'ok' || statusString === 'Ok'){
      return PinGreen
    }else if(statusString === 'error' || statusString === 'Error'){
      return PinRed
    }else if(statusString === 'disconnected' || statusString === 'Disconnected'){
      return PinGrey
    }else if(statusString === 'acknowledged' || statusString === 'Acknowledged'){
      return PinYellow
    }else{
      return PinGreen
    }
  }

  return(
    <div style={{ padding: 25, position: 'relative', width: '100%', height: '100%' }}>
      <ReactMapGL
        {...viewport}
        dragPan={'panmove'}
        width='100%'
        mapStyle={'mapbox://styles/dharmabv/ckpo38sbt029017mm9ya6zf7q'}
        height='90vh'
        mapboxApiAccessToken={MAPBOX_API_ACCESS_TOKEN}
        onViewportChange={nextViewport => setViewport(nextViewport)}
      >
        {
          screens.map((val, key) => {
            const { location } = val
            const { coordinates } = location
            const showMarker = coordinates.latitude !== "" && coordinates.longitude !== ""
                && coordinates.latitude !== "NULL" && coordinates.longitude !== "NULL"
            return (
              (showMarker) ?
                <Marker
                  key={key}
                  offsetTop={-30}
                  latitude={parseFloat(val.location.coordinates.latitude.replace(',', '.').replace(' ', ''))}
                  longitude={parseFloat(val.location.coordinates.longitude.replace(',', '.').replace(' ', ''))}
                >
                  <div style={{
                    marginTop:"-15px",
                    marginLeft:"-15px",
                  }}>
                    <img onClick={() => props.popupOnClick(val)}
                      src={getPin(val.status.statusString)}
                      alt={val.status.statusString === ('ok' || "Ok") ? "success" : "error"}
                    />
                  </div>
                </Marker>
                : null
            )
          })
        }
        {
          props.popupInfo && <Popup
            latitude={parseFloat(props.popupInfo.location.coordinates.latitude.replace(',','.').replace(' ',''))}
            longitude={parseFloat(props.popupInfo.location.coordinates.longitude.replace(',','.').replace(' ',''))}
            closeOnClick={false}
            onClose={() => props.popupOnClose()}
            anchor='top'
          >
            <div className={'popup-container'}>
              <img style={{ height: 'auto', width: '100%' }} src={props.popupInfo.screen_image ?
                props.popupInfo.screen_image : CardImage} alt={'card_image'} />
              <div className={'text-container'}>
                <div className={'title-text'} >
                  <h2 className={'name'} >
                    {props.popupInfo.screenName}
                  </h2>
                  <h2 className={'temp'} >{props.popupInfo.temp}°</h2>
                </div>
                <p className={'city'}>{props.popupInfo.location.description}</p>
                <div className={props.popupInfo.status.statusString === ('ok' || "Ok") ? 'status ok' : 'status error'}>
                  <img
                    src={props.popupInfo.status.statusString === ('ok' || "Ok") ? OK : ERROR}
                    alt={props.popupInfo.status.statusString}
                  />
                  {props.popupInfo.status.statusString.toUpperCase()}
                </div>
                <div className={'buttons'}>
                  <button className={'panic'}>Panic button</button>
                  <button className={'location'} onClick={() => toScreen()}>Locatie bekijken</button>
                </div>
              </div>
            </div>
          </Popup>

        }
        <FullscreenControl style={fullscreenControlStyle} />
        <NavigationControl style={navControlStyle} showCompass={false} />
      </ReactMapGL>
    </div>
  )
}
ScreensMap.propTypes = {
  screens: PropTypes.any.isRequired,
  popupOnClick: PropTypes.any.isRequired,
  popupOnClose: PropTypes.any.isRequired,
  popupInfo: PropTypes.any.isRequired,
  history: PropTypes.any
}
export default ScreensMap
