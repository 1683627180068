import PropTypes from 'prop-types'
import React from 'react'
import { Card, CardText } from 'material-ui/Card'
import CardTitle from '../../../../../../../components/CardTitle'
import { Table, TableBody, TableHeaderColumn, TableRow, TableRowColumn } from 'material-ui/Table'
import { FormattedMessage } from 'react-intl'

export const ViewPermissions = (props) => {
  const permissions = props.permissions.length
    ? (
      <Table selectable={false}>
        <TableBody displayRowCheckbox={false}>
          <TableRow>
            <TableHeaderColumn className='role-table-id-column'>
              Id
            </TableHeaderColumn>
            <TableHeaderColumn>
              <div>
                {<FormattedMessage {...props.messages.permission} />}
              </div>
            </TableHeaderColumn>
          </TableRow>
          {props.permissions
            .sort((lhs, rhs) => lhs.id - rhs.id)
            .map((permission, i) =>
              <TableRow key={i}>
                <TableRowColumn className='role-table-id-column'>{permission.id}</TableRowColumn>
                <TableRowColumn>{permission.label}</TableRowColumn>
              </TableRow>
            )}
        </TableBody>
      </Table>
    ) : <FormattedMessage {...props.messages.noPermissionsFoundForRole} />

  return (
    <Card className={props.className}>
      <CardTitle title={<FormattedMessage {...props.messages.permissions} />} />
      <CardText>
        {permissions}
      </CardText>
    </Card>
  )
}

ViewPermissions.propTypes = {
  permissions: PropTypes.array.isRequired,
  messages: PropTypes.object.isRequired,
  className: PropTypes.string
}

export default ViewPermissions

