import PropTypes from 'prop-types'
import React from 'react'
import { GridList } from 'material-ui/GridList'
import { List } from 'material-ui/List'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const Styles = {
  list: {
    paddingTop: 0,
    overflowY: 'none', // makes scroll faster on iOS
    WebkitOverflowScrolling: 'touch', // makes scroll faster on iOS
    marginTop: 1 // required to avoid overflowing the bar
  },
  grid: {
    padding: '0 16px 0px 16px',
    overflowY: 'none', // makes scroll faster on iOS
    WebkitOverflowScrolling: 'touch', // makes scroll faster on iOS
    marginTop: 1 // required to avoid overflowing the bar
  }

}

export const RecylerView = ({ content, isMobile }) => {
  return (isMobile
    ? (
      <List style={{ ...Styles.list, height: 'auto' }}>
        {content}
      </List>
    )
    : (
      <div>
        <GridList
          style={{ ...Styles.grid, height: 'auto' }}
          cellHeight='auto'
          cols={3}
          padding={2}
        >
          {content.map((item) =>
            item.map((item2) => (
              item2
            ))
          )}
        </GridList>
      </div>
    )
  )
}

RecylerView.propTypes = {
  isMobile: PropTypes.any.isRequired,
  content: PropTypes.array.isRequired,
}

export default RecylerView
