import DateTimePicker from './DateTimePicker'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './datetimeselection.scss'

export class DateTimeSelection extends Component {

  componentDidMount () {
    const { id, startDate, startTime, endDate, endTime, db } = this.props
    this.props.getStatsWithDates(id, startDate, startTime, endDate, endTime, db)
  }

  componentWillReceiveProps (nextProps) {
    const { id, startDate, startTime, endDate, endTime, db } = nextProps

    if (
      this.props.startDate === startDate &&
            this.props.startTime === startTime &&
            this.props.endDate === endDate &&
            this.props.endTime === endTime && this.props.db === db) {
      return
    }
    this.props.getStatsWithDates(id, startDate, startTime, endDate, endTime, db)
  }

    updateDateTime = (startDate, startTime, endDate, endTime) => {
      this.props.setDateTimes(startDate, startTime, endDate, endTime)
    }

    startDateDidChange = (startDate) => {
      const { startTime, endDate, endTime } = this.props
      this.updateDateTime(startDate, startTime, endDate, endTime)
    }
    startTimeDidChange = (startTime) => {
      const { startDate, endDate, endTime } = this.props
      this.updateDateTime(startDate, startTime, endDate, endTime)
    }
    endDateDidChange = (endDate) => {
      const { startDate, startTime, endTime } = this.props
      this.updateDateTime(startDate, startTime, endDate, endTime)
    }
    endTimeDidChange = (endTime) => {
      const { startDate, startTime, endDate } = this.props
      this.updateDateTime(startDate, startTime, endDate, endTime)
    }

    render () {
      const { startDate, startTime, endDate, endTime } = this.props
      return (
        <div className='datetime'>
          <div className='datetime-selection'>
            <DateTimePicker
              dateFloatingLabelText='Start datum'
              timeFloatingLabelText='Start tijd'
              dateDidChange={this.startDateDidChange}
              timeDidChange={this.startTimeDidChange}
              date={startDate}
              time={startTime}
            />
            <DateTimePicker
              dateFloatingLabelText='Eind datum'
              timeFloatingLabelText='Eind tijd'
              dateDidChange={this.endDateDidChange}
              timeDidChange={this.endTimeDidChange}
              date={endDate}
              time={endTime}
            />
          </div>
        </div>
      )
    }
}

DateTimeSelection.propTypes = {
  id: PropTypes.number.isRequired,
  getStatsWithDates: PropTypes.func.isRequired,
  setDateTimes: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  db: PropTypes.string.isRequired,
}

export default DateTimeSelection
