import PropTypes from 'prop-types'
import React from 'react'
import { AppBar, IconButton, Toolbar, Typography } from '@material-ui/core'
import NavRightIconMenu from './NavRightIconMenu'
import NavigationArrowBack from 'material-ui/svg-icons/navigation/arrow-back'
import messages from './messages'
import { FormattedMessage } from 'react-intl'
import './Header.scss'
import ScreenLogo from '../../../src/assets/logos/logo.svg'
import ScreensToolbar from './ToolbarContainer'
import { Link } from "react-router-dom"

export const BackButton = props => {
  if (!props.isOnACL) {
    return null
  }
  return (
    <IconButton onClick={() => props.history.goBack()}>
      <NavigationArrowBack color={"#FFFFFF"} />
    </IconButton>
  )
}

BackButton.propTypes = {
  isOnACL: PropTypes.any,
  history: PropTypes.object
}

export class Header extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      url: ''
    }
  }

    handleRouteChanged = (event) => {
      this.setState({ 'url': event.pathname })
      this.forceUpdate()
    }

    componentDidMount () {
      this.setState({ 'url': this.props.history.location.pathname })
      this.props.history.listen(this.handleRouteChanged)
    }

    render () {
      const { loggedIn, logout, isOnACL, history } = this.props

      const isScreenDetail = (
        !(this.state.url === 'screens' || this.state.url === '/screens') &&
            this.state.url.includes('screens')
      )

      return loggedIn ? (
        <AppBar
          position={"static"}
          title={isOnACL ? <FormattedMessage {...messages.management} /> : ''}
          showMenuIconButton={isOnACL}
          zDepth={0}
          id={"header"}
          onTitleClick={() => history.push('/screens')}
        >
          <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
            {!isOnACL &&
            <div>
              <Link to={'/screens'} style={{ display: "flex", alignItems: "center", gap: "23px" }}>
                <img alt='logo' src={ScreenLogo} width='58' height='auto' />
                <Typography variant='h1' style={{ fontSize: "18px", fontFamily: "Didot, sans-serif", color: "white" }}>
                  <FormattedMessage {...messages.title} />
                </Typography>
              </Link>
            </div>
            }
            <div className='' style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
            >
              {(this.state.url === 'screens'
                  || this.state.url === '/screens'
                  || this.state.url === 'screens'
                  || this.state.url === '/home')
              && <ScreensToolbar history={history} url={this.state.url} />}

              {isScreenDetail &&
              <div
                className='search--bar'
              />
              }
              <NavRightIconMenu history={history} logout={logout} />
            </div>
          </Toolbar>
        </AppBar>
      ) : null
    }
}

Header.propTypes = {
  loggedIn: PropTypes.any,
  logout: PropTypes.func.isRequired,
  isOnACL: PropTypes.any.isRequired,
  history: PropTypes.object
}

export default Header
