import PropTypes from 'prop-types'
import React, { Component } from 'react'
import User from './User'
import List from '../../../../../components/ScrollList'
import FloatingActionButton from '../../../../../components/FABwithPermissions'
import { Background } from '../../../../../constants/colors'
import { CREATE } from '../../../../../constants/routes'
import { CREATE_USER } from '../../../../../constants/acl'
import './Users.scss'

const styles = {
  root: {
    backgroundColor: Background
  }
}

export class Users extends Component {

  componentWillMount () {
    this.props.getUsers()
  }

  toCreateUser () {
    const pathname = this.props.history.location.pathname
    this.props.history.push(`${pathname}/${CREATE}`)
  }

  render () {
    return this.props.children
      ? <div>{this.props.children}</div>
      : (
        <div style={styles.root}>
          <List>
            {this.props.users.map((user, i) => <User history={this.props.history} key={user.id} {...user} />)}
          </List>
          <FloatingActionButton permission={CREATE_USER} onClick={() => this.toCreateUser()} />
        </div>
      )
  }
}

Users.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
      title:  PropTypes.string.isRequired,
      company: PropTypes.string.isRequired,
      groups: PropTypes.array.isRequired
    })
  ),
  history: PropTypes.object,
  getUsers: PropTypes.func.isRequired,
  children: PropTypes.element
}

export default Users
