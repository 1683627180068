import PropTypes from 'prop-types'
import React from 'react'
import {
  ScreenFlexContainer,
  ScreenName,
  ScreenDescription,
  ScreenTemp,
  ScreenStateContainer,
  ScreenVideoStatus,
  ScreenVideoState
} from './StyledComponents'
import OK from "../../../assets/icons/ok.svg"
import ERROR from "../../../assets/icons/error.svg"

export class Screen extends React.Component {
  render () {
    const {
      screenName,
      temp,
      location,
      videoState,
      status,
      altInfo,
      screenLocation
    } = this.props

    const statusText = (
      <ScreenVideoState status={this.props.status}>
        <img
          src={this.props.status.statusString === ('ok' || "Ok") ? OK : ERROR}
          alt={this.props.status.statusString}
        />
        {status.statusString.toUpperCase()}
      </ScreenVideoState>
    )

    const output = videoState !== null
      ? (
        <ScreenVideoState status={this.props.status}>
          {videoState.output}
        </ScreenVideoState>
      )
      : null
    let properTemp = temp
    if (altInfo && altInfo.length > 0) {
      properTemp = temp === 0 && altInfo[0].temp !== temp ? altInfo[0].temp : temp
    }
    let locationScreen = location.description
    if (location.description === undefined) {
      locationScreen = screenLocation.description
    }
    return (
      <React.Fragment>
        <ScreenFlexContainer>
          <ScreenName className='title' onClick={this.props.toScreen}>
            {screenName}
            <ScreenDescription>
              {locationScreen}
            </ScreenDescription>
          </ScreenName>
          <ScreenTemp className='temp' status={this.props.status}>
            {properTemp !== -275 ? properTemp + '°' : ''}
          </ScreenTemp>
        </ScreenFlexContainer>
        <ScreenStateContainer>
          <ScreenVideoStatus status={this.props.status}>{statusText}</ScreenVideoStatus>
          {output}
        </ScreenStateContainer>
      </React.Fragment>

    )
  }
}

Screen.propTypes = {
  temp: PropTypes.number.isRequired,
  screenName: PropTypes.string.isRequired,
  toScreen: PropTypes.func,
  status: PropTypes.shape({
    status: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    statusString: PropTypes.string.isRequired
  }).isRequired,
  location: PropTypes.shape({
    id: PropTypes.number.isRequired,
    city: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  }).isRequired,
  videoState: PropTypes.shape({
    input: PropTypes.string,
    output: PropTypes.string,
    isInputConnected: PropTypes.any,
    isOutputConnected: PropTypes.any
  }),
  altInfo: PropTypes.any,
  screenLocation: PropTypes.any
}

export default Screen
