import PropTypes from 'prop-types'
import React from 'react'
import { Card, CardActions } from 'material-ui/Card'
import CardTitle from '../CardTitle'

const TitleButtonCard = (props) => {
  return (
    <Card className={props.className}>
      <CardTitle title={props.title} />
      <CardActions>
        {props.editButton}
      </CardActions>
    </Card>
  )
}

TitleButtonCard.propTypes = {
  title: PropTypes.string.isRequired,
  editButton: PropTypes.element,
  className: PropTypes.string
}

export default TitleButtonCard
