import React from 'react'
import * as Colors from '../../constants/colors'
import * as routes from '../../constants/routes'
import Hamburger from '../../assets/icons/hamburger.svg'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Drawer, IconButton, MenuItem } from '@material-ui/core'
import ScreensToolbar from "./ToolbarContainer"
import PropTypes from 'prop-types'

const useStyles = makeStyles({
  list: {
    width: 362,
    padding: "0 30px 30px 30px",
  }
})

function TemporaryDrawer (props) {
  const [state, setState] = React.useState({
    right: false,
    url: ''
  })
  const classes = useStyles()
  const anchor = 'right'

  const toACL = () => {
    props.history.push(`/${routes.ACL}/${routes.USERS}`)
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    setState({ ...state, [anchor]: open })
  }

  const list = (anchor) => (
    <div
      className={clsx(classes.list)}
      role='presentation'
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <ScreensToolbar
        url={props.history.location.pathname}
        dark
        history={props.history}
      />
      <div className={"menu-items"}>
        <MenuItem onClick={() => toACL()}>Control panel</MenuItem>
        <MenuItem onClick={() => toACL()}>Instellingen</MenuItem>
        <MenuItem onClick={() => props.logout()}>Uitloggen
        </MenuItem>
      </div>
    </div>
  )

  return (
    <React.Fragment key={anchor}>
      <IconButton onClick={toggleDrawer(anchor, true)} style={{ fontSize: 15 }}>
        <img src={Hamburger} alt='hamburger' color={Colors.White} />
      </IconButton>
      <Drawer anchor={anchor}
        open={state[anchor]}
        onClose={toggleDrawer(anchor, false)}
        variant={'temporary'}
      >
        {list(anchor)}
      </Drawer>
    </React.Fragment>
  )
}

export default TemporaryDrawer

TemporaryDrawer.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  logout: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  history: PropTypes.object
}

