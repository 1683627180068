import PropTypes from 'prop-types'
import React, { Component } from 'react'
import messages from '../constants/messages'
import TextField from 'material-ui/TextField'
import Button from '../../../../../../../components/ResponsiveButton'
import { Card, CardText } from 'material-ui/Card'
import CardTitle from '../../../../../../../components/CardTitle'
import { FormattedMessage } from 'react-intl'
import { orange500 } from 'material-ui/styles/colors'
import { container } from '../../../../../../../styles/layout'
import './User.scss'
import { validateEmail } from '../../../../../../../utils/validation'
import * as colors from '../../../../../../../constants/colors'

const Styles = {
  warningLabelStyle: { color: orange500 },
  floatingLabelFocusStyle: { color: colors.TextBlack },
  underlineStyle: { color: colors.TextBlack },
  fontLight: { fontFamily: 'Interface' }
}

export class Edit extends Component {
  constructor (props) {
    super(props)
    let { name, username, company, title, api_token } = props.user
    this.state = {
      name,
      username,
      company,
      title,
      api_token,
      isSubmitButtonDisabled: true
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentWillReceiveProps (nextProps) {
    let { name, username, company, title } = nextProps.user
    this.setState({ name, username, company, title })
  }

  handleInputChange (evt) {
    const target = evt.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value
    }, () => {
      if (name === 'username') this.handleEmailValidation(value)
      this.preemptiveFormValidation()
    })

  }

  handleEmailValidation (email) {
    this.setState({
      'emailValidationMessage': validateEmail(email)
        ? ''
        : <FormattedMessage {...messages.invalidEmail} />
    })
  }

  preemptiveFormValidation () {
    const email = this.state.username || ''
    const isFormValid = validateEmail(email) &&
      this.state.company.trim().length > 1 &&
      this.state.title.trim().length > 1 &&
      this.state.name.trim().length > 1
    this.setState({
      isSubmitButtonDisabled: !isFormValid
    })
  }

  handleSubmit (evt) {
    evt.preventDefault()
    this.props.editUser(
      this.props.user.id,
      {
        username: this.state.username.trim(),
        name: this.state.name.trim(),
        title: this.state.title.trim(),
        company: this.state.company.trim(),
        api_token: this.state.api_token.trim(),
      }
    )
  }

  render () {
    const formBackgroundColor = this.props.isMobile ? '#fff' : null
    const form = (
      <form onSubmit={this.handleSubmit} autoComplete='off' style={{ backgroundColor: formBackgroundColor }} >
        <div className='edit-users-textfield-wrapper'>
          <TextField
            type='name'
            name='name'
            hintText={<FormattedMessage {...messages.nameFloatingLabelText} />}
            fullWidth
            autoComplete='off'
            style={Styles.fontLight}
            value={this.state.name}
            onChange={this.handleInputChange}
          />
        </div>
        <div className='edit-users-textfield-wrapper'>
          <TextField
            type='text'
            name='username'
            hintText={<FormattedMessage {...messages.usernameFloatingLabelText} />}
            fullWidth
            autoComplete='off'
            errorText={this.state.emailValidationMessage}
            style={Styles.fontLight}
            value={this.state.username}
            onChange={this.handleInputChange}
            errorStyle={Styles.warningLabelStyle}
          />
        </div>
        <div className='edit-users-textfield-wrapper'>
          <TextField
            type='text'
            name='title'
            hintText={<FormattedMessage {...messages.titleFloatingLabelText} />}
            fullWidth
            autoComplete='off'
            style={Styles.fontLight}
            value={this.state.title}
            onChange={this.handleInputChange}
          />
        </div>
        <div className='edit-users-textfield-wrapper'>
          <TextField
            type='text'
            name='company'
            hintText={<FormattedMessage {...messages.companyFloatingLabelText} />}
            fullWidth
            autoComplete='off'
            style={Styles.fontLight}
            value={this.state.company}
            onChange={this.handleInputChange}
          />
        </div>
        <div className='edit-users-textfield-wrapper'>
          <TextField
            type='text'
            name='api_token'
            hintText={<FormattedMessage {...messages.apiTokenFloatingLabelText} />}
            fullWidth
            autoComplete='off'
            style={Styles.fontLight}
            value={this.state.api_token}
            onChange={this.handleInputChange}
          />
        </div>
        <div style={{ paddingTop: '24px', paddingBottom: '0' }}>
          <Button
            label={<FormattedMessage {...messages.submit} />}
            type='submit'
            value='submit'
            isMobile={this.props.isMobile}
            disabled={this.state.isSubmitButtonDisabled}
          />
        </div>
        {this.props.permissions.includes('deleteUser') &&
        <div style={{ marginTop: '12px' }}>
          <Button
            label={<FormattedMessage {...messages.delete} />}
            type='button'
            value='delete'
            onClick={() => {this.props.deleteUser(this.props.user.id)}}
            buttonStyle={{ backgroundColor: colors.Panic }}
            isMobile={this.props.isMobile}
          />
        </div>}
      </form>)

    const content = this.props.isMobile ? form : (
      <div className='padding-box'>
        <Card style={container}>
          <CardTitle
            titleStyle={{ color: colors.TextBlack, fontWeight: 300, fontFamily: 'Interface' }}
            title={
              <FormattedMessage {...{
                ...messages.title,
                values: { user: '' } }}
              />
            }
          />
          <CardText style={{ paddingTop: '0px', paddingBottom: '8px' }} >
            {form}
          </CardText>
        </Card>
      </div>)

    return (
      <div className={this.props.isMobile ? null : 'Edit-user-card-wrapper card-wrapper'}>
        {content}
      </div>
    )
  }
}

Edit.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
    groups: PropTypes.array.isRequired
  }),
  editUser: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  isMobile: PropTypes.any.isRequired,
  permissions: PropTypes.any
}

export default Edit
