import * as Colors from '../../../constants/colors'
import { orange500 } from 'material-ui/styles/colors'

export const underlineStyle = {
  borderColor: Colors.TextBlack
}

export const warningLabelStyle = {
  color: orange500
}

export const forgotLabelStyle = {
  color: Colors.White,
  fontWeight: 300,
  textTransform: 'none',
  textAlign: 'left',
  display: 'inline-block',
  padding: '0',
}
