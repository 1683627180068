import React from 'react'
import PropTypes from 'prop-types'
import { CardTitle } from 'material-ui/Card'
import { TextBlack } from '../../constants/colors'

const style = {
  color: TextBlack,
  fontWeight: '300',
  fontSize: 'x-large',
  lineHeight: '32px'
}

export const StyledCardTile = (props) => {
  const compoundStyle = { ...style, ...props.titleStyle, color: 'black' }
  return <CardTitle {...props} titleStyle={compoundStyle} />
}

export default StyledCardTile

StyledCardTile.propTypes = {
  titleStyle: PropTypes.object
}
