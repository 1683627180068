import React from 'react'
import { Switch, Route } from 'react-router-dom'
import LoginForm from '../routes/Login/containers/LoginFormContainer'
import Forgot from '../routes/Forgot'
import Reset from '../routes/Reset'
export const AppLayout = props => {
  return(
    <div>
      <Switch>
        <Route path={'/reset/*'} component={Reset} />
        <Route path={'/reset'} component={Forgot} />
        <Route path={'/'} component={LoginForm} />
      </Switch>
    </div>
  )
}
export default AppLayout