import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './screen-vis.scss'
import * as helper from './helpers'
import { Formatted } from './FormattedNumber'

export class ScreenVis extends Component {
  constructor (props) {
    super(props)
    const { width, height, pitch } = props
    const percentages = helper.resolutionToPercentages(width, height)
    const meters = helper.resolutionToMeter(width, height, pitch)

    this.state = {
      width: 0,
      height: 0,
      percentages,
      meters
    }
    this.scale = 0.4
    this._updateDimensions = this._updateDimensions.bind(this)
  }

  componentDidMount () {
    this._updateDimensions()
    window.addEventListener('resize', this._updateDimensions)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this._updateDimensions)
  }

  componentWillReceiveProps (nextProps) {
    const { width, height, pitch } = nextProps
    const percentages = helper.resolutionToPercentages(width, height)
    const meters = helper.resolutionToMeter(width, height, pitch)

    this.setState({
      percentages,
      meters
    })
    this._updateDimensions(percentages)
  }

  /**
     * Make the visualisation responsive by calculating the width of the container
     * @private
     */
  _updateDimensions (percentages = this.state.percentages) {
    if (this.container) {
      if (this.container.clientWidth) {
        const size = this.container.clientWidth
        this.setState({
          width: Math.round(size * percentages.width * this.scale),
          height: Math.round(size * percentages.height * this.scale)
        })
      }
    }
  }

  render () {
    const { height, width, meters } = this.state
    return (
      <div ref={(container) => {
        this.container = container
      }}
      >
        <div style={{ display: "flex" }}>
          <div className='bar' style={{ width, height }} />
          <div className='bar__specs'>
            <div className='bar__specs--top'>
              <h6>Resolutie</h6>
              <b>{this.props.width} x {this.props.height} px</b>
            </div>
            <div className='bar__specs--bottom'>
              <div>
                <h6>Formaat</h6>
                <Formatted number={meters.width} /> x <Formatted number={meters.height} /> meter
              </div>
              <div>
                <h6>Pitch</h6>
                <b>{this.props.pitch} mm</b>
              </div>
              <div>
                <h6>Titel</h6>
                <Formatted number={meters.width * meters.height} /> m&#178;
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ScreenVis.propTypes = {
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  pitch: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ])
}

export default ScreenVis
