import PropTypes from 'prop-types'
import React from 'react'
import messages from '../constants/messages.js'
import { FormattedMessage } from 'react-intl'
import EditRole from './EditRole'
import EditTable from '../../../../../../../components/EditTableLayout'

export const Edit = ({
  users,
  permissions,
  addUserToRole,
  removeUserFromRole,
  addPermissionToRole,
  removePermissionFromRole,
  editRole,
  deleteRole,
  name,
  match
}) => {
  const id = Number(match.params.id)
  const handleUserUpdate = (userId, active) => {
    if (active) {
      removeUserFromRole(id, userId)
    } else {
      addUserToRole(id, userId)
    }
  }

  const handlePermissionUpdate = (permissionId, active) => {
    if (active) {
      removePermissionFromRole(id, permissionId)
    } else {
      addPermissionToRole(id, permissionId)
    }
  }

  const userItems = users.map((user) => {
    return { id: user.id, label: user.username, second: user.role, active: user.active }
  })
  const permissionItems = permissions.map((permission) => {
    return { id: permission.id, label: permission.label, active: permission.active }
  })

  return (
    <div className='role-container'>
      <div className='role-item-header role-card-wrapper'>
        <EditRole id={id} name={name} deleteRole={deleteRole} editRole={editRole} messages={messages} />
      </div>
      <div className='role-item role-card-wrapper'>
        <EditTable
          secondColumnEnabled
          items={userItems}
          handleClick={handleUserUpdate}
          title={<FormattedMessage {...messages.viewRoleUsersTitle} />}
          columnHeader={<FormattedMessage {...messages.username} />}
          secondHeader={<FormattedMessage {...messages.role} />}
        />
      </div>
      <div className='role-item role-item-header role-card-wrapper'>
        <EditTable
          items={permissionItems}
          handleClick={handlePermissionUpdate}
          title={<FormattedMessage {...messages.editPermissionsTitle} />}
          columnHeader={<FormattedMessage {...messages.permission} />}
        />
      </div>
    </div>
  )
}

Edit.propTypes = {
  match: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  permissions: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  editRole: PropTypes.func.isRequired,
  addUserToRole: PropTypes.func.isRequired,
  removeUserFromRole: PropTypes.func.isRequired,
  addPermissionToRole: PropTypes.func.isRequired,
  removePermissionFromRole: PropTypes.func.isRequired,
  deleteRole: PropTypes.func.isRequired
}

export default Edit
