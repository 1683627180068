import { fetchFromSlimAPI } from '../libs/api'
import { setErrorMessage, setSuccessMessage } from './notice'
import messages from '../routes/ACL/routes/Groups/components/messages'
import generalMessages from '../constants/messages'
import * as actions from '../constants/actions'

// ------------------------------------
// Async Actions
// ------------------------------------

export const getGroups = () => {
  return (dispatch) => {
    dispatch(fetchFromSlimAPI('group/all', 'get', undefined, (success) => {
      if (success && !success.code) {
        dispatch(groupsChange([...success]))
      }
    }))
  }
}

export const deleteGroup = (groupID) => {
  return (dispatch) => {
    // check if all fields are filled
    if (!groupID) {
      dispatch(setErrorMessage(generalMessages.fieldMissing))
      return
    }
    dispatch(fetchFromSlimAPI(`groups/${groupID}`, 'delete', undefined, (success) => {
      if (success && !success.code) {
        // should return empty response on success
        dispatch(removeGroupFromGroups(groupID))
        dispatch(setSuccessMessage(messages.removedGroup))
      }
    }))
  }
}

// ------------------------------------
// Actions
// ------------------------------------

export const updateGroupInGroups = (group) => {
  return {
    type  : actions.GROUPS_UPDATE_GROUP,
    group
  }
}

export const removeGroupFromGroups = (id) => {
  return {
    type  : actions.GROUPS_DELETE_GROUP,
    id
  }
}

export const addGroupToGroups = (group) => {
  return {
    type  : actions.GROUPS_ADD_GROUP,
    group
  }
}

export function groupsChange (groups = []) {
  return {
    type  : actions.GROUPS_CHANGE,
    groups : groups
  }
}

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = { groups: [] }
export default function groupsReducer (state = initialState, action) {
  switch(action.type){
  case actions.GROUPS_CHANGE:
    return ({ ...state, groups: action.groups })

  case actions.GROUPS_DELETE_GROUP: {
    const groups = state.groups.filter(function (group) {
      return group.id !== Number(action.id)
    })
    return ({
      ...state,
      groups
    })
  }

  case actions.GROUPS_ADD_GROUP:
    return ({ ...state, groups: [...state.groups, action.group] })

  case actions.GROUPS_UPDATE_GROUP: {
    const groups = state.groups.map(function (group) {
      if (group.id === action.group.id) {
        return action.group
      }
      return group
    })
    return ({
      ...state,
      groups
    })
  }
  default:
    return { ...state }
  }
}
