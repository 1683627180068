import { defineMessages } from 'react-intl'

export default defineMessages({
  edit: {
    id: 'Role.components.edit',
    defaultMessage: 'EDIT'
  },
  view: {
    id: 'Role.components.view',
    defaultMessage: 'VIEW'
  },
  username: {
    id: 'Role.components.username',
    defaultMessage: 'username'
  },
  permissions: {
    id: 'Role.components.permissions',
    defaultMessage: 'Permissions'
  },
  noUsersFoundForRole: {
    id: 'Role.components.noUsersFoundForRole',
    defaultMessage: 'This role has no users'
  },
  viewRoleUsersTitle: {
    id: 'Role.components.ViewRoleUsers.title',
    defaultMessage: 'Users in role'
  },
  permission: {
    id: 'Role.components.permission',
    defaultMessage: 'Permission'
  },
  noPermissionsFoundForRole: {
    id: 'Role.components.noPermissionsFoundForRole',
    defaultMessage: 'This role has no permissions'
  },
  editRoleNameTitle: {
    id: 'Role.components.EditRole.title',
    defaultMessage: 'Edit name of role'
  },
  editRoleNameHintText: {
    id: 'Role.components.EditRole.hintText',
    defaultMessage: 'Name'
  },
  editRoleNameFloatingLabelText: {
    id: 'Role.components.EditRole.floatingLabelText',
    defaultMessage: 'Name of role'
  },
  deleteRole: {
    id: 'Role.components.Role.delete',
    defaultMessage: 'Delete role'
  },
  editRoleNameSubmit: {
    id: 'Role.components.EditRole.submit',
    defaultMessage: 'SUBMIT'
  },
  editPermissionsTitle: {
    id: 'Role.components.EditRolePermissions.title',
    defaultMessage: 'Edit permissions'
  },
  editRoleUsersExplaination: {
    id: 'Role.components.EditRoleUsers.explaination',
    defaultMessage: 'Tap X on user chip to remove them from role'
  },
  editRoleUsersNotInRoleTitle: {
    id: 'Role.components.EditRoleUsers.usersNotInRoleTitle',
    defaultMessage: 'Users not in role'
  },
  editRoleUsersNotInRoleExplaination: {
    id: 'Role.components.EditRoleUsers.usersNotInRoleExplaination',
    defaultMessage: 'Tap a user to add it to this role'
  },
  'role': {
    id: 'Role.components.role',
    defaultMessage: 'Role'

  }

})
