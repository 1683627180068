import PropTypes from 'prop-types'
import { Component } from 'react'

export class Permissions extends Component {
  componentDidMount () {
    if (this.props.loggedIn) this.getCurrentUser()
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.loggedIn === false && nextProps.loggedIn === true) {
      this.getCurrentUser()
    }
  }

  getCurrentUser () {
    if (this.props.permissions === false) this.props.getMe()
  }

  render () {
    return null
  }
}

Permissions.propTypes = {
  permissions : PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.any
  ]).isRequired,
  loggedIn : PropTypes.any.isRequired,
  getMe : PropTypes.func.isRequired
}

export default Permissions
