/**
 * Takes two array of objects and returns a copy of the first array with a key 'active' added to each object
 * 'active' is true if the object id appears in both arrays
 * @param allObjects
 * @param someObjects
 * @returns {array}
 */
export default function mapActiveObjects (allObjects, someObjects) {
  return allObjects.map(function (object) {
    let active = someObjects.some(function (someObject) {
      return object.id === someObject.id
    })
    return { ...object, active }
  })
}

/**
 * Takes an array of objects and returns a new object with objects with the key 'active' set to true/false
 * @param objectsArray
 * @param active
 * @returns {*}
 */
export function filterActiveObjects (objectsArray, active = true) {
  if (!Array.isArray(objectsArray) || !objectsArray.length) return objectsArray
  return objectsArray.filter((obj) => {
    return obj.active === active
  })
}
