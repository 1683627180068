import React from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import { withRouter } from 'react-router'
export const AuthRoute = props => {
  if(props.me.permissions.indexOf(props.permission) > -1){
    return <Route {...props} />
  }
  else{
    props.history.push('/')
    return null
  }
}
AuthRoute.propTypes = {
  permission: PropTypes.string,
  me: PropTypes.object,
  history: PropTypes.object
}
const mapStateToProps = (state) => ({
  me: state.me,
})

export default withRouter(connect(mapStateToProps, {})(AuthRoute))