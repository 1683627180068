import { connect } from 'react-redux'
import { withRouter } from 'react-router'

/*  This is a container component. Notice it does not contain any JSX,
 nor does it import React. This component is **only** responsible for
 wiring in the actions and state necessary to render a presentational
 component - in this case, the screens:   */

import ScreenDataVis from '../components/stats/DataVis'

/*  Object of action creators (can also be function that returns object).
 Keys will be passed as props to presentational components. Here we are
 implementing our wrapper around increment; the component doesn't care   */
const mapDispatchToProps = {

}

const mapStateToProps = (state) => {
  return ({ stats: state.screen.stats, realTimeAudience: state.screen.realTimeAudience })
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScreenDataVis))
