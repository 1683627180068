import { fetchFromSlimAPI } from '../libs/api'
import * as actions from '../constants/actions'

// ------------------------------------
// Async Actions
// ------------------------------------

export const getMe = () => {
  return (dispatch) => {
    return dispatch(fetchFromSlimAPI('me', 'get', undefined, (success) => {
      if (success && !success.hasOwnProperty('code')) {
        let { groups, permissions, role, screens, username } = success
        let me = {
          groups: [...groups],
          permissions: [...permissions],
          role,
          screens: [...screens],
          username
        }
        dispatch(meChange(me))
        return Promise.resolve(me)
      }
    }))
  }
}

// ------------------------------------
// Actions
// ------------------------------------

export function meChange (me = initialState) {
  return {
    type    : actions.ME_CHANGE,
    payload : me
  }
}

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  groups: [],
  permissions: [],
  role: false,
  screens: [],
  username: ''
}

export const meReducer = (state = initialState, action) => {

  switch (action.type) {

  case actions.ME_CHANGE:
    return ({
      ...state,
      ...action.payload
    })

  default: return state
  }

}

export default meReducer
