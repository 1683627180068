import React, { Component } from "react"
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core"
import PropTypes from "prop-types"

const OPTIONS = [
  "Ok",
  "Error",
  "Disconnected",
  "Acknowledged"
]

class MapCheckBox extends Component {
  constructor (props) {
    super(props)
    this.state = {
      checkboxes: OPTIONS.reduce(
        (options, option) => ({
          ...options,
          [option]: true
        }),
        {}
      )
    }
  }

    // What do to when the checkbox changes
    handleCheckboxChange = changeEvent => {
      const name = changeEvent.target.name
      this.setState(prevState => ({
        checkboxes: {
          ...prevState.checkboxes,
          [name]: !prevState.checkboxes[name]
        }
      }), () => {
        this.props.updateStatusStrings(this.state.checkboxes)
      })

    };
    // Dynamically create checkbox
    createCheckbox = option => (
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              isSelected={this.state.checkboxes[option]}
              checked={this.state.checkboxes[option]}
              onChange={this.handleCheckboxChange}
              key={option}
              name={option}
              color='primary'
            />
          }
          label={option}
        />
      </FormGroup>
    );

    createCheckboxes = () => OPTIONS.map(this.createCheckbox);

    render () {
      return (
        <div style={{ padding: "22px 20px" }}>
          <form>
            {this.createCheckboxes()}
          </form>
        </div>
      )
    }
}
MapCheckBox.propTypes = {
  updateStatusStrings: PropTypes.any.isRequired
}
export default MapCheckBox
