
import { cyan500, grey100, grey300, grey400, grey500, white, darkBlack, fullBlack } from 'material-ui/styles/colors'
import { Candela, TextBlack } from './colors'

export const muiTheme = {
  fontFamily: 'InterFace',
  palette: {
    primary1Color: TextBlack,
    primary2Color: Candela,
    primary3Color: grey400,
    accent1Color: TextBlack,
    accent2Color: grey100,
    accent3Color: grey500,
    textColor: darkBlack,
    alternateTextColor: white,
    canvasColor: white,
    borderColor: grey300,
    pickerHeaderColor: cyan500,
    shadowColor: fullBlack
  }
}

export default muiTheme
