import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { login, usernameChange, clearLoginError } from '../../../../../store/authentication'

import LoginForm from '../components/LoginForm'

const mapActionCreators = {
  login,
  usernameChange,
  clearLoginError
}

const mapStateToProps = (state) => ({
  fetching: state.auth.fetching,
  authenticated: state.auth.authenticated,
  username: state.auth.username,
  error: state.auth.error,
  isMobile: state.isMobile
})

export default withRouter(connect(mapStateToProps, mapActionCreators)(LoginForm))
