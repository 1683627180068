import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Card, CardText } from 'material-ui/Card'
import CardTitle from '../CardTitle'
import TextField from 'material-ui/TextField'
import { Table, TableBody, TableRow, TableRowColumn } from 'material-ui/Table'
import './edit.scss'
import Header from './Header'
import messages from './messages'
import { FormattedMessage } from 'react-intl'

const styles = {
  idColumn: { width: 20 },
  fontLight: { fontFamily: 'Interface' }
}

export class Edit extends Component {

  constructor (props) {
    super(props)
    this.state = {
      filterText: ''
    }
  }

  handleUpdateInput = (event) => {
    this.setState({
      filterText: event.target.value.toLowerCase()
    })
  }

  filterWithFilterText = (element) => {
    if (!this.state.filterText.length) return true
    return element.fields.reduce((prevValue, element) => {
      if (prevValue) return true
      return element.toLowerCase().includes(this.state.filterText.toLowerCase())
    }, false)
  }

  handleClick = (rowId) => {
    const items = this.props.items.filter(this.filterWithFilterText)
    const element = items[rowId]
    this.props.handleClick(element.id, element.active)
  }

  /*
   * wrapping TableRow or other Table components in a custom component breaks table functionality,
   * ie: it breaks the toggle box
   * suggestions in https://github.com/callemall/material-ui/issues/4524 not working
   */

  render () {
    const rows = this.props.items
      .filter(this.filterWithFilterText)
      .map((element, i) => (
        <TableRow key={i} id={element.id} selected={element.active} style={styles.fontLight}>
          <TableRowColumn key={0} style={styles.idColumn} className='edit-table-id-column'>{element.id}</TableRowColumn>
          {element.fields.map((field, i) => (
            <TableRowColumn key={i + 1}
              className={i > 0 ? `edit-table-secondary-column edit-table-column-${i + 1}` : null}
            >
              {field}
            </TableRowColumn>
          ))}
        </TableRow>
      ))

    return (
      <Card>
        <CardTitle title={this.props.title} style={styles.fontLight} />
        <CardText>
          <TextField
            fullWidth
            hintText={<FormattedMessage {...messages.searchAllFields} />}
            style={styles.fontLight}
            onChange={this.handleUpdateInput}
          />
          <Table multiSelectable onCellClick={((rowId) => this.handleClick(rowId))}>
            <Header {...{ ...this.props, styles: styles.idColumn }} />
            <TableBody displayRowCheckbox deselectOnClickaway={false} stripedRows={false}>
              {rows}
            </TableBody>
          </Table>
        </CardText>
      </Card>
    )
  }

}

// handleClick returns an id and active state from the items array when row is toggled
Edit.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
      active: PropTypes.any.isRequired,
      fields: PropTypes.arrayOf(PropTypes.string.isRequired)
    })
  ),
  handleClick: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ])
}

export default Edit
