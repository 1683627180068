import PropTypes from 'prop-types'
// Layout HOC
import React from 'react'
import Alert from 'material-ui/svg-icons/alert/warning'
import { List, ListItem } from 'material-ui/List'
import AcknowledgeButton from './AcknowledgeButton'
import SolveButton from './SolveButton'
import {
  Table,
  TableBody,
  TableRow,
  TableRowColumn
} from 'material-ui/Table'
import { FormattedMessage } from 'react-intl'
import messages from '../../constants/messages'

export const Status = props => {
  const { incidents, acknowledgeIncident, solveIncident, screenId, username } = props
  const incidentsCount = incidents.length
  if (incidentsCount === 0) {
    return <div />
  }
  return (

    <div>
      <List key={0} className='screen-grid-title'>
        {incidents.map((incident, i) => {
          return (
            <ListItem
              key={i}
              leftIcon={<Alert />}
              innerDivStyle={{ paddingRight: '72px', fontWeight: '300' }}
              primaryText={incident.message}
              initiallyOpen={false}
              primaryTogglesNestedList
              nestedItems={[
                <div key={i} className={'incidentItem'}>
                  <Table selectable={false} key={0}>
                    <TableBody displayRowCheckbox={false}>
                      <TableRow>
                        <TableRowColumn><FormattedMessage {...messages.id} /></TableRowColumn>
                        <TableRowColumn>{incident.id || '-'}</TableRowColumn>
                      </TableRow>
                      <TableRow>
                        <TableRowColumn><FormattedMessage {...messages.incidentLevel} /></TableRowColumn>
                        <TableRowColumn>{incident.level || '-'}</TableRowColumn>
                      </TableRow>
                      <TableRow>
                        <TableRowColumn><FormattedMessage {...messages.incidentRule} /></TableRowColumn>
                        <TableRowColumn>{incident.rule || '-'}</TableRowColumn>
                      </TableRow>
                      <TableRow>
                        <TableRowColumn colSpan='2'>
                          <strong>
                            <FormattedMessage {...messages.incidentOccurrences} />
                          </strong>
                        </TableRowColumn>
                      </TableRow>
                      <TableRow>
                        <TableRowColumn><FormattedMessage {...messages.incidentCount} /></TableRowColumn>
                        <TableRowColumn>{incident.count || '-'}</TableRowColumn>
                      </TableRow>
                      <TableRow>
                        <TableRowColumn><FormattedMessage {...messages.incidentFirstOccurrence} /></TableRowColumn>
                        <TableRowColumn>{incident.firstOccurance || '-'}</TableRowColumn>
                      </TableRow>
                      <TableRow>
                        <TableRowColumn><FormattedMessage {...messages.incidentLastOccurrence} /></TableRowColumn>
                        <TableRowColumn>{incident.lastOccurance || '-'}</TableRowColumn>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <AcknowledgeButton onClick={() => acknowledgeIncident(incident.id, screenId)} />
                  <SolveButton onClick={() => solveIncident(incident.id, screenId, username)} />
                </div>
              ]}
            />
          )
        })}
      </List>
    </div>
  )
}

Status.propTypes = {
  incidents: PropTypes.arrayOf(
    PropTypes.shape({
      acknowledgedDate: PropTypes.date,
      acknowledgedUser: PropTypes.string,
      count: PropTypes.number,
      firstOccurance: PropTypes.date,
      lastOccurance: PropTypes.date,
      id: PropTypes.number,
      level: PropTypes.number,
      rule: PropTypes.number,
      message: PropTypes.string
    })
  ),
  acknowledgeIncident: PropTypes.func.isRequired,
  solveIncident: PropTypes.func,
  screenId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  username: PropTypes.string
}

export default Status
