import PropTypes from 'prop-types'
import { Component } from 'react'
import * as dimensions from '../../constants/dimensions'

export class IsMobile extends Component {
  constructor (props) {
    super(props)
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  componentDidMount () {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions.bind(this))
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateWindowDimensions.bind(this))
  }

  shouldComponentUpdate () {
    return false
  }

  updateWindowDimensions () {
    const isMobile = window.innerWidth <= dimensions.MOBILE_WIDTH
    if (isMobile !== this.props.isMobile) this.props.resizeChange(isMobile)
  }

  render () { return null }
}

IsMobile.propTypes = {
  isMobile: PropTypes.any.isRequired,
  resizeChange: PropTypes.func.isRequired
}

export default IsMobile
