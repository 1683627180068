import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'
/* eslint-disable */
const Icon = (props) => (
  <SvgIcon {...props}>

    <path
      d='M17.21,18.93H5.2a0.27,0.27,0,0,1-.27-0.27V7.87A0.27,0.27,0,0,1,5.2,7.6H21.39a0.27,0.27,0,0,1,.27.27v6.41a0.27,0.27,0,1,1-.54,0V8.14H5.47V18.39H17.21A0.27,0.27,0,1,1,17.21,18.93Z'
      transform='translate(0 -4.3)' />
    <path
      d='M5.2,16.71H2.77a0.27,0.27,0,0,1-.27-0.27V6.19a0.27,0.27,0,0,1,.27-0.27H18.15a0.27,0.27,0,0,1,.27.27V7.8a0.27,0.27,0,1,1-.54,0V6.46H3v9.71H5.2A0.27,0.27,0,0,1,5.2,16.71Z'
      transform='translate(0 -4.3)' />
    <path
      d='M2.7,15.09H0.27A0.27,0.27,0,0,1,0,14.82V4.57A0.27,0.27,0,0,1,.27,4.3H15.65a0.27,0.27,0,0,1,.27.27V6.19a0.27,0.27,0,1,1-.54,0V4.84H0.54v9.71H2.7A0.27,0.27,0,1,1,2.7,15.09Z'
      transform='translate(0 -4.3)' />
    <path
      d='M21.93,21.09H20.85a0.27,0.27,0,0,1-.27-0.27V20.39a2.13,2.13,0,0,1-.51-0.29l-0.37.22a0.27,0.27,0,0,1-.37-0.1l-0.54-.93a0.27,0.27,0,0,1,.1-0.37l0.37-.22a1.92,1.92,0,0,1,0-.6l-0.37-.21a0.27,0.27,0,0,1-.13-0.16,0.27,0.27,0,0,1,0-.2l0.54-.93a0.27,0.27,0,0,1,.37-0.1l0.37,0.22a2.13,2.13,0,0,1,.51-0.29V16a0.27,0.27,0,0,1,.27-0.27h1.08a0.27,0.27,0,0,1,.27.27V16.4a2.13,2.13,0,0,1,.51.29l0.37-.22a0.27,0.27,0,0,1,.37.1L24,17.51a0.27,0.27,0,0,1,0,.2,0.27,0.27,0,0,1-.13.16l-0.37.21a1.92,1.92,0,0,1,0,.6l0.37,0.22a0.27,0.27,0,0,1,.1.37l-0.54.93a0.27,0.27,0,0,1-.37.1l-0.38-.22a2.1,2.1,0,0,1-.51.29v0.44A0.27,0.27,0,0,1,21.93,21.09Zm-0.81-.54h0.54V20.19a0.27,0.27,0,0,1,.19-0.26,1.6,1.6,0,0,0,.65-0.37,0.27,0.27,0,0,1,.32,0l0.31,0.18,0.27-.47-0.31-.18a0.27,0.27,0,0,1-.13-0.3A1.47,1.47,0,0,0,23,18a0.27,0.27,0,0,1,.13-0.3l0.31-.18-0.27-.47-0.31.18a0.27,0.27,0,0,1-.32,0,1.61,1.61,0,0,0-.65-0.37,0.27,0.27,0,0,1-.19-0.26V16.23H21.12v0.36a0.27,0.27,0,0,1-.19.26,1.61,1.61,0,0,0-.65.37,0.27,0.27,0,0,1-.32,0l-0.31-.18-0.27.47,0.31,0.18a0.27,0.27,0,0,1,.13.3,1.47,1.47,0,0,0,0,.75,0.27,0.27,0,0,1-.13.3l-0.31.18,0.27,0.47L20,19.53a0.27,0.27,0,0,1,.32,0,1.61,1.61,0,0,0,.65.37,0.27,0.27,0,0,1,.19.26v0.36Z'
      transform='translate(0 -4.3)' />
    <path
      d='M21.39,19.47a1.08,1.08,0,1,1,1.08-1.08A1.08,1.08,0,0,1,21.39,19.47Zm0-1.62a0.54,0.54,0,1,0,.54.54A0.54,0.54,0,0,0,21.39,17.85Z'
      transform='translate(0 -4.3)' />

  </SvgIcon>
)
/* eslint-enable */
export default Icon
