
// ------------------------------------
// Actions
// ------------------------------------

export const startFetching = (fetchingName = '') => { return { type: `START_FETCHING_${fetchingName}` } }
export const stopFetching = (fetchingName = '') => { return { type: `STOP_FETCHING_${fetchingName}` } }

// ------------------------------------
// Reducer
// ------------------------------------
export const fetchingReducer = (fetchingName = '') => (state = false, action) => {
  switch (action.type) {
  case `START_FETCHING_${fetchingName}`:
    return true
  case `STOP_FETCHING_${fetchingName}`:
    return false
  default:
    return state
  }
}

export default fetchingReducer
