import { fetchFromVaporAPI } from '../libs/api'
// ------------------------------------
// Constants
// ------------------------------------
export const ME_CHANGE = 'ME_CHANGE'
// ------------------------------------
// Actions
// ------------------------------------

export function meChange (me = initialState) {
  return {
    type    : ME_CHANGE,
    payload : me
  }
}

// ------------------------------------
// API calls
// ------------------------------------

export const getMe = () => {
  return (dispatch) => {
    return dispatch(fetchFromVaporAPI('me', 'get', undefined, (success, err) => {
      if (success && !success.hasOwnProperty('code')) {
        let { groups, permissions, role, screens, username } = success
        let me = { groups: [...groups],
          permissions: [...permissions],
          role,
          screens: [...screens],
          username }
        dispatch(meChange(me))
        return Promise.resolve(me)
      }
    }))
  }
}

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = { groups: [], permissions: false, role: false, screens: [], username: '' }

export const meReducer = (state = initialState, action) => {

  switch (action.type) {

  case ME_CHANGE:
    return ({
      ...state,
      ...action.payload
    })

  default: return state
  }

}

export default meReducer
