import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl, intlShape } from 'react-intl'
import messages from '../../constants/messages'
import {
  XYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  AreaSeries,
  LineSeries,
  Crosshair,
  GradientDefs,
  DiscreteColorLegend
} from 'react-vis'
import 'react-vis/dist/styles/plot.scss'
import 'react-vis/dist/styles/legends.scss'
import '../../../../../../styles/overrides/_react-vis.scss'
import './stats.scss'
import { Candela, Green } from '../../../../../../constants/colors'
import multiFormat from '../../../../../../utils/d3Dateformat'
import { CardHeader, CardContent, Card } from "@material-ui/core"

export class DataVis extends Component {
  constructor (props) {
    super(props)
    this.state = {
      crosshairValues: [],
      legend: [
        { title: props.intl.formatMessage(messages.brightnessLegend), disabled: false, color: Candela },
        { title: props.intl.formatMessage(messages.lightLegend), disabled: false, color:  Green }
      ],
      dataProps: [
        { title: props.intl.formatMessage(messages.brightness),
          units: props.intl.formatMessage(messages.brightnessUnits),
          color: Green
        },
        { title: props.intl.formatMessage(messages.light),
          units: props.intl.formatMessage(messages.lightUnits),
          color: Candela
        }
      ],
      width: 500,
      height: 300
    }

    this._onMouseLeave = this._onMouseLeave.bind(this)
    this._onNearestX = this._onNearestX.bind(this)
    this._formatDate = this._formatDate.bind(this)
    this._updateDimensions = this._updateDimensions.bind(this)
  }

  componentDidMount () {
    this._updateDimensions()
    window.addEventListener('resize', this._updateDimensions)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this._updateDimensions)
  }

  /**
   * Make the visualisation responsive by calculating the width of the container
   * @private
   */
  _updateDimensions () {
    if (this.container) {
      if (this.container.clientWidth) this.setState({ width: this.container.clientWidth })
    }
  }

  /**
   * Event handler for onNearestX.
   * @param {Object} value Selected value.
   * @param {index} index Index of the value in the data array.
   * @private
   */
  _onNearestX (value, { index }) {
    const { stats } = this.props
    this.setState({ crosshairValues: [stats.brightness, stats.light].map(d => d[index] || { y: 0 }) })
  }

  /**
   * Event handler for onMouseLeave.
   * @private
   */
  _onMouseLeave () {
    this.setState({ crosshairValues: [] })
  }

  /**
   * Function to convert a Date object to a localized, human-readable string
   * @param date {Date}
   * @returns {string} a formatted date string
   * @private
   */
  _formatDate (date) {
    return this.props.intl.formatDate(date, multiFormat(date))
  }

  render () {
    const { legend, crosshairValues, dataProps, width, height } = this.state
    const { intl, stats, isMobile } = this.props
    // get values for scale y1
    const y1 = stats.brightness.map((item) => item.y)
    const y1Domain = [Math.min(...y1), Math.max(...y1)]
    // get values for scale y2
    const y2 = stats.light.map((item) => item.y)
    const y2Domain = [Math.min(...y2), Math.max(...y2)]

    const gradient = (
      <GradientDefs>
        <linearGradient id='Gradient0' x1='0' x2='0' y1='0' y2='1'>
          <stop offset='0%' stopColor={dataProps[1].color} stopOpacity={0.2} />
          <stop offset='100%' stopColor={dataProps[1].color} stopOpacity={0.2} />
        </linearGradient>
        <linearGradient id='Gradient1' x1='0' x2='0' y1='0' y2='1'>
          <stop offset='0%' stopColor={dataProps[0].color} stopOpacity={0.2} />
          <stop offset='100%' stopColor={dataProps[0].color} stopOpacity={0.2} />
        </linearGradient>
      </GradientDefs>
    )
    // <Filters /> temporary removed this
    // TODO: fix filtering on serverside?

    const crosshairValue = crosshairValues.length > 0 ? intl.formatDate(crosshairValues[0].x, {
      weekday: 'short', day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric'
    }) : null
    const prefix = intl.formatMessage(messages.date)
    const crossHairTitle = `${prefix} ${crosshairValue}`
    const roundedTickTotal = Math.round(width / 100)
    const tickTotalMin = roundedTickTotal > 5 ? roundedTickTotal : 5
    const tickTotal = tickTotalMin < 20 ? tickTotalMin : 20
    return (
      <div ref={(container) => { this.container = container }}>
        <DiscreteColorLegend
          key={10}
          items={legend}
          orientation='vertical'
          onItemClick={(obj, num) => {
            this.setState(prevState => {
              let newLegend = [...prevState.legend]
              newLegend[num].disabled = !newLegend[num].disabled
              return { newLegend }
            })
          }}
        />
        <XYPlot
          key={0}
          xType='time'
          y1Type='linear'
          y1Domain={y1Domain}
          y1Range={[height, 0]}
          y2Type='linear'
          y2Domain={y2Domain}
          y2Range={[height, 0]}
          onMouseLeave={this._onMouseLeave}
          margin={{ left: 60, right: 40, top: 10, bottom: 40 }}
          width={width}
          height={height}
          animation
        >
          {gradient}
          <HorizontalGridLines key={1} />

          <XAxis
            key={2}
            tickFormat={this._formatDate}
            tickTotal={tickTotal}
          />
          <YAxis
            key={3}
            hideLine
            right={0}
            attr='y1'
            className='axis--y1'
            orientation='right'
          />
          <YAxis
            key={4}
            hideLine
            orientation='left'
            attr='y2'
            className='axis--y2'
          />
          <LineSeries
            key={5}
            yDomain={y1Domain}
            style={{ strokeWidth: '1px' }}
            onNearestX={this._onNearestX}
            curve={'curveMonotoneX'}
            color={dataProps[1].color}
            data={stats.brightness || null}
            {...(legend[0].disabled ? { opacity: 0.1 } : null)}
          />
          <AreaSeries
            key={6}
            yDomain={y1Domain}
            curve={'curveMonotoneX'}
            color={'url(#Gradient0)'}
            data={stats.brightness || null}
            {...(legend[0].disabled ? { opacity: 0.0 } : null)}
          />
          <AreaSeries
            key={7}
            yDomain={y2Domain}
            curve={'curveMonotoneX'}
            color={'url(#Gradient1)'}
            data={stats.light || null}
            {...(legend[1].disabled ? { opacity: 0.0 } : null)}
          />
          <LineSeries
            key={8}
            style={{ strokeWidth: '1px' }}
            yDomain={y2Domain}
            curve={'curveMonotoneX'}
            color={dataProps[0].color}
            data={stats.light || null}
            {...(legend[1].disabled ? { opacity: 0.1 } : null)}
          />
          {isMobile
            ? null
            : (
              <Crosshair key={9} values={crosshairValues} >
                <Card>
                  <CardHeader key={100} title={crossHairTitle} />
                  <CardContent key={200} >
                    {crosshairValues.map((item, i) => {
                      let legend = dataProps[i]
                      return <div key={i}>{`${legend.title}: ${Math.round(item.y)} ${legend.units}`}</div>
                    })}
                  </CardContent>
                </Card>
              </Crosshair>
            )
          }
        </XYPlot>
      </div>
    )
  }
}

DataVis.propTypes = {
  intl: intlShape,
  isMobile: PropTypes.any,
  stats: PropTypes.shape({
    brightness: PropTypes.array,
    light: PropTypes.array
  })
}

export default injectIntl(DataVis)
