import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
// enchancers

// reducers

import auth from './auth'
import screens from './screens'
import screen from './screen'
import notice from './notice'
import isMobile from './isMobile'
import users from './users'
import me from './me2'
import user from './user'
import roles from './roles'
import groups from './groups'
import group from './group'
import role from './role'

export default combineReducers({
  routing: routerReducer,
  auth,
  screens,
  screen,
  notice,
  isMobile,
  me,
  users,
  user,
  roles,
  groups,
  group,
  role
})
