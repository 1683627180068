import { fetchFromSlimAPI } from '../libs/api'
import { setErrorMessage, setSuccessMessage } from './notice'
import messages from '../constants/messages'
import * as routes from '../constants/routes'
import * as actions from '../constants/actions'
import { history } from './index'
import { updateRoleInRoles, removeRoleFromRoles, addRoleToRoles } from './roles'
import mapActiveObjects from "../routes/ACL/routes/Roles/routes/Role/utils/helper"
import { PERMISSION_LABELS } from "../constants/acl"

// ------------------------------------
// Actions Async
// ------------------------------------

export const createRole = (roleName) => {
  return (dispatch) => {
    // check if all fields are filled
    if (!roleName.length) {
      dispatch(setErrorMessage(messages.fieldMissing))
      return
    }
    let body = JSON.stringify({ name: roleName })
    dispatch(fetchFromSlimAPI('role/create', 'post', body, (success) => {
      if (success.id) {
        // redirect to roles
        dispatch(addRoleToRoles({ id: success.id, name: roleName }))
        history.push(`/${routes.ACL}/${routes.ROLES}`)
        dispatch(setSuccessMessage(messages.didCreateRole))
      } else {
        if (success.code === 400) dispatch(setErrorMessage(messages.roleNameTaken))
        else dispatch(setErrorMessage(messages.generalError))
      }
    }))
  }
}

export const getRole = (roleID) => {
  return (dispatch) => {
    dispatch(fetchFromSlimAPI(`role/${roleID}`, 'get', undefined, (success) => {
      if (success && !success.code) {
        // should return { id:string, name:string, permissions:array }
        let { id, name, permissions } = success
        dispatch(updateRole({ id: Number(id), name }))
        dispatch(mapActivePermissions([...permissions]))
      }
    }))
  }
}

export const editRole = (roleID, rolename) => {
  return (dispatch) => {
    // check if all fields are filled
    if (!rolename.length) {
      dispatch(setErrorMessage(messages.fieldMissing))
      return
    }
    let body = JSON.stringify({ id: roleID, name: rolename })
    dispatch(fetchFromSlimAPI(`role/${roleID}`, 'PATCH', body, (success) => {
      if (success && !success.code) {
        // should return { id:string, name:string }
        let { id, name } = success
        let role = { id: Number(id), name }
        dispatch(updateRole(role))
        dispatch(updateRoleInRoles(role))
        dispatch(setSuccessMessage(messages.didUpdateRoleName))
      }
    }))
  }
}

export const deleteRole = (roleID) => {
  return (dispatch) => {
    // check if all fields are filled
    if (!roleID) {
      dispatch(setErrorMessage(messages.fieldMissing))
      return
    }
    dispatch(fetchFromSlimAPI(`role/${roleID}`, 'DELETE', undefined, (success) => {
      if (success && !success.code) {
        dispatch(removeRoleFromRoles(roleID))
        dispatch(setSuccessMessage(messages.didRemoveRole))
        history.push(`/${routes.ROLES}`)
      }
    }))
  }
}

export const mapActivePermissions = (activePermissions) => {
  return (dispatch) => {
    dispatch(fetchFromSlimAPI(`permission/all`, 'get', undefined, () => {}))
      .then(success => {
        let mapped = mapActiveObjects([...success], activePermissions)
          .map(permission => {
            return {
              ...permission,
              label: PERMISSION_LABELS[permission.name]
            }
          })
        dispatch(updateRolePermissions(mapped))
      })
  }
}

export const addPermissionToRole = (roleID, permissionId) => {
  return (dispatch) => {
    // check if all fields are filled
    if (!permissionId || !roleID) {
      return
    }
    let body = JSON.stringify({ permissionId: permissionId })
    dispatch(fetchFromSlimAPI(`role/${roleID}/permission`, 'post', body, (success) => {
      if (success && !success.code) {
        // should return empty response on success
        dispatch(addRolePermission(Number(permissionId)))
        dispatch(setSuccessMessage(messages.didAddPermissionToRole))
      }
    }))
  }
}

export const removePermissionFromRole = (roleID, permissionId) => {
  return (dispatch) => {
    // check if all fields are filled
    if (!permissionId || !roleID) {
      return
    }
    let body = JSON.stringify({ permissionId: permissionId })
    dispatch(fetchFromSlimAPI(`role/${roleID}/permission`, 'delete', body, (success) => {
      if (success && !success.code) {
        // should return empty response on success
        dispatch(removeRolePermission(Number(permissionId)))
        dispatch(setSuccessMessage(messages.didRemovePermissionFromRole))
      }
    }))
  }
}

export const getRoleUsers = (roleID) => {
  return (dispatch) => {
    let fetchAllUsers = dispatch(getUsers())
    fetchAllUsers.then(allUsers => {
      // should return [{ id:int, username:string }]
      dispatch(fetchFromSlimAPI(`role/${roleID}/users`, 'get', undefined, (success) => {
        if (success && !success.code) {
          // should return [{ id:int, username:string }]
          let users = mapActiveObjects(allUsers, success)
          dispatch(updateRoleUsers(users))
        }
      }))
    })
  }
}

export const getUsers = () => {
  return (dispatch) => {
    return dispatch(fetchFromSlimAPI('user/all', 'get', undefined, (success, err) => {
      if (!success) {
        console.log(err)
      }
    }))
  }
}

export const addUserToRole = (roleID, userId) => {
  return (dispatch) => {
    // check empty values
    if (!userId || !roleID) return
    let body = JSON.stringify({ userId:userId })
    dispatch(fetchFromSlimAPI(`role/${roleID}/user`, 'post', body, (success) => {
      if (success && !success.code) {
        // should return empty response on success
        dispatch(addRoleUsers(Number(userId)))
        dispatch(setSuccessMessage(messages.didAddUserToRole))
      }
    }))
  }
}

export const removeUserFromRole = (roleID, userId) => {
  return (dispatch) => {
    // check empty values
    if (!userId || !roleID) return

    let body = JSON.stringify({ userId:userId })
    dispatch(fetchFromSlimAPI(`role/${roleID}/user`, 'delete', body, (success) => {
      if (success && !success.code) {
        // should return empty response on success
        dispatch(removeRoleUsers(Number(userId)))
        dispatch(setSuccessMessage(messages.didRemoveUserFromRole))
      }
    }))
  }
}

// ------------------------------------
// Actions
// ------------------------------------

export function updateRole (role) {
  return {
    type  : actions.UPDATE_ROLE,
    role
  }
}

export function updateRolePermissions (permissions) {
  return {
    type  : actions.UPDATE_ROLE_PERMISSIONS,
    permissions
  }
}

export function addRolePermission (id) {
  return {
    type  : actions.ADD_ROLE_PERMISSION,
    id
  }
}

export function removeRolePermission (id) {
  return {
    type  : actions.REMOVE_ROLE_PERMISSION,
    id
  }
}

export function updateRoleUsers (users) {
  return {
    type  : actions.UPDATE_ROLE_USERS,
    users
  }
}

export function addRoleUsers (id) {
  return {
    type  : actions.ADD_ROLE_USER,
    id
  }
}

export function removeRoleUsers (id) {
  return {
    type  : actions.REMOVE_ROLE_USER,
    id
  }
}

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  role: {},
  permissions: [],
  users: []
}
export default function roleReducer (state = initialState, action) {
  switch (action.type){
  case actions.UPDATE_ROLE:
    return { ...state, role: action.role }

  //Screens actions
  case actions.UPDATE_ROLE_PERMISSIONS:
    return { ...state, permissions: action.permissions }

  case actions.ADD_ROLE_PERMISSION:
    return {
      ...state,
      permissions: state.permissions.map(function (screen) {
        if (screen.id !== Number(this)) return screen
        return { ...screen, active: true }
      }, action.id)
    }

  case actions.REMOVE_ROLE_PERMISSION:
    return {
      ...state,
      permissions: state.permissions.map(function (screen) {
        if (screen.id !== Number(this)) return screen
        return { ...screen, active: false }
      }, action.id)
    }

  //Users actions
  case actions.UPDATE_ROLE_USERS:
    return { ...state, users: action.users }

  case actions.ADD_ROLE_USER:
    return {
      ...state,
      users: state.users.map(function (user) {
        if (user.id !== Number(this)) return user
        return { ...user, active: true }
      }, action.id)
    }

  case actions.REMOVE_ROLE_USER:
    return {
      ...state,
      users: state.users.map(function (user) {
        if (user.id !== Number(this)) return user
        return { ...user, active: false }
      }, action.id)
    }

  default:
    return { ...state }
  }
}
