/**
 * Helper function to check if user has the required permission
 * @param myPermissions {array}
 * @param requiredPermissions {array | string} with one or more required permissions
 * @returns {boolean}
 */
export default function checkPermissions (myPermissions, requiredPermissions) {
  if (!requiredPermissions || !requiredPermissions.length) return true // no permissions required apparently?
  if (!Array.isArray(myPermissions) || !myPermissions || !myPermissions.length) return false
  // if requiredPermissions is a string, we can assume there is only a single permission to check for
  if (typeof requiredPermissions === 'string') {
    return myPermissions.some(function (myPermission) {
      return requiredPermissions === myPermission
    })
  }
  // check if every required permission is present in my permissions
  return requiredPermissions.every(function (required) {
    return myPermissions.some(function (myPermission) {
      return required === myPermission
    })
  })
}
