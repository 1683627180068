import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Snackbar } from '@material-ui/core'
import './Notice.scss'
import * as colors from '../../constants/colors'
import { FormattedMessage } from 'react-intl'

export class Notice extends Component {
  constructor (props) {
    super(props)
    this.state = {
      open: this.props.open
    }
  }
  componentWillReceiveProps (nextProps) {
    // remove notices after navigating to a new page
    if (this.props.open !== nextProps.open) {
      this.setState({ open: nextProps.open })
    }
  }

  handleRequestClose = (reason) => {
    if (reason === 'clickaway') {
      this.setState({
        open: false
      })
    }
  }

  render () {
    let { message, type } = this.props

    let backgroundColor = colors.Candela
    if (type === 'error') backgroundColor = colors.Panic
    else if (type === 'success') backgroundColor = colors.Candela

    message = (typeof message === 'string') ? message : <FormattedMessage {...message} />
    return (
      <Snackbar
        open={this.state.open}
        message={message}
        autoHideDuration={3000}
        onRequestClose={this.handleRequestClose}
        bodyStyle={{
          backgroundColor,
          transition: 'background-color 1s ease'
        }}
      />
    )
  }
}

Notice.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  type: PropTypes.string.isRequired,
  open: PropTypes.any.isRequired
}

export default Notice
