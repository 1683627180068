import { API_BASE_URL, API_REQUEST_OBJECT, SLIM_BASE_URL } from '../constants/api'
import auth from './auth'
import messages from '../constants/messages'
import { logout, getUserToken } from '../store/authentication'
import { setErrorMessage } from '../store/notice'

export const decodeResponse = response => {

  return Promise.resolve(response.status === 204 || response.status === 201 ? {} : response.json())
    .then(json => Promise.resolve({
      status: response.status,
      ok: response.ok,
      json
    }))

}

export const fetchFromVaporAPI = (endpoint, method = 'post', body = undefined, callback) => {
  return (dispatch) => {
    let token = getUserToken()
    // If we don't have an authorization token... logout and inform user
    if (!token) {
      handleFetchErrors(401, dispatch, callback)
      return false
    }
    // Build request object
    let requestObj = {
      ...API_REQUEST_OBJECT,
      method,
      body
    }
    requestObj.headers.Authorization = `Bearer ${token}`
    // Post request

    return fetch(`${API_BASE_URL}${endpoint}`, requestObj)
      .then(status)
      .then(response => {
        return Promise.resolve(response.status === 204 ? {} : response.json())
      })
      .then(data => {
        if (typeof data === 'object') {
          callback(data)
          return Promise.resolve(data)
        } else {
          // If there was a problem...
          return Promise.reject(data)
        }
      })
      .catch(error => {
        handleFetchErrors(error, dispatch, callback)
      })
  }
}
export const SLIM_API_REQUEST_HEADERS = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
  'Origin': '*'
}

export const SLIM_API_REQUEST_OBJECT = {
  mode: 'cors',
  headers: SLIM_API_REQUEST_HEADERS
}
export const fetchFromSlimAPI = (endpoint, method = 'post', body = undefined, callback) => {
  return (dispatch) => {
    let token = getUserToken()
    // If we don't have an authorization token... logout and inform user
    if (!token) {
      handleFetchErrors(401, dispatch, callback)
      return false
    }
    const requestObject = { ...SLIM_API_REQUEST_OBJECT }

    // Build request object
    let requestObj = {
      ...requestObject,
      method,
      body
    }
    requestObj.headers.Authorization = `Bearer ${token}`
    return fetch(`${SLIM_BASE_URL}${endpoint}`, requestObj)
      .then(status)
      .then(response => {
        return Promise.resolve(response.status === 204 || response.status === 201 ? {} : response.json())
      })
      .then(data => {
        if (typeof data === 'object') {
          callback(data)
          return Promise.resolve(data)
        } else {
          // If there was a problem...
          // console.log('some error')
          return Promise.reject(data)
        }
      })
      .catch(error => {
        // console.error(error)
        console.error(error.stack)
        handleFetchErrors(error, dispatch, callback)
      })
  }
}

export const buildRequestObject = (method = 'post', body = undefined) => {
  const token = auth.getToken()

  const requestObject = token !== null && token !== undefined
    ? { ...API_REQUEST_OBJECT, headers: { ...API_REQUEST_OBJECT.headers, Authorization: `Bearer ${token}` } }
    : API_REQUEST_OBJECT

  // Build request object
  return {
    ...requestObject,
    method,
    body
  }
}

export const status = (response) => {

  if (response.status >= 200 && response.status < 300) {
    // 2xx Success
    return Promise.resolve(response)
  } else {
    // 3xx Redirection, 4xx Client Error, 5xx Server Error, 1xx Informational
    return Promise.reject(response.status)
  }
}
export const handleFetchErrors = (errorStatus = 500, dispatch, callback) => {
  switch (errorStatus) {
  case 501:
    // authorization error, invalid token: immediately reset state and redirect to login
    dispatch(logout(messages.unauthorized))
    break
  case 511:
    // token expired: inform user and redirect to login
    dispatch(logout(messages.loginExpired))
    break
  case 401:
    // not logged in, but requires log in
    dispatch(logout(messages.unauthorized))
    break
  case 403:
    // logged in, but insufficient permissions
    dispatch(setErrorMessage(messages.forbidden))
    break
  default:
    // general server error
    dispatch(setErrorMessage(messages.generalError))
  }

  callback(false)
}
