import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { TextField } from '@material-ui/core/'
import LoginButton from '../../../containers/LoginButtonContainer'
import messages from '../../../components/messages'
import { FormattedMessage } from 'react-intl'
import { validateEmail } from '../../../../../utils/validation'

export class Forgot extends Component {
  constructor (props) {
    super(props)

    this.state = {
      emailValidationMessage: '',
      isSubmitButtonDisabled: !this.props.username || !validateEmail(this.props.username)
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleInputChange (evt) {
    const { value } = evt.target

    this.props.usernameChange(value)
    this.preemptiveFormValidation(value)
    this.handleEmailValidation(value)
  }

  handleEmailValidation (email) {
    this.setState({
      emailValidationMessage: validateEmail(email)
        ? ''
        : <FormattedMessage {...messages.invalidEmail} />
    })
  }

  preemptiveFormValidation (email = '') {
    const isFormValid = validateEmail(email)
    this.setState({
      isSubmitButtonDisabled: !isFormValid
    })
  }

  handleSubmit (evt) {
    evt.preventDefault()
    this.props.requestResetPassword(this.props.username.trim())
  }

  render () {
    return (
      <form onSubmit={this.handleSubmit} className='login-form'>
        <div className='login-textfield-wrapper'>
          <TextField
            fullWidth
            label={'Email'}
            name='email'
            errorText={this.state.emailValidationMessage}
            value={this.props.username}
            onChange={this.handleInputChange}
            variant='filled'
          />
        </div>
        <LoginButton
          label={<FormattedMessage {...messages.requestPasswordReset} />}
          disabled={this.props.fetching || this.state.isSubmitButtonDisabled}
        />
      </form>
    )
  }
}

Forgot.propTypes = {
  requestResetPassword: PropTypes.func.isRequired,
  fetching: PropTypes.any.isRequired,
  username: PropTypes.string.isRequired,
  usernameChange: PropTypes.func.isRequired
}

export default Forgot
