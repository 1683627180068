import React from 'react'
import PropTypes from 'prop-types'
import RaisedButton from 'material-ui/RaisedButton'
import withPermissions from '../../../../../../containers/withPermissions'
import { ACKNOWLEDGE_INCIDENTS } from '../../../../../../constants/acl'

export const SolveButton = ({ onClick }) => (
  <RaisedButton
    onClick={onClick}
    style={{ marginTop: 20 }}
    primary
    label='Solve'
    className={'solveButton'}
    fullWidth
    permission={[ACKNOWLEDGE_INCIDENTS]}
  />
)

SolveButton.propTypes = {
  onClick: PropTypes.func.isRequired
}

export const SolveButtonWithPermissions = withPermissions(SolveButton)

export default SolveButtonWithPermissions
