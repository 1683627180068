import { connect } from 'react-redux'
import { withRouter } from 'react-router'

/*  This is a container component. Notice it does not contain any JSX,
 nor does it import React. This component is **only** responsible for
 wiring in the actions and state necessary to render a presentational
 component - in this case, the screens:   */

import Extra from '../components/extra/Extra'

/*  Object of action creators (can also be function that returns object).
 Keys will be passed as props to presentational components. Here we are
 implementing our wrapper around increment; the component doesn't care   */
const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return ({
    contactPerson: state.screen.screen.contactPerson,
    document: state.screen.screen.document,
    id: state.screen.screen.id,
    brightnessPercentage: state.screen.screen.brightnessPercentage,
    light: state.screen.screen.light,
    city: state.screen.screen.location.city,
    locationDescription: state.screen.screen.location.description,
    realEstate: state.screen.screen.realEstate,
    temp: state.screen.screen.temp,
    videoState: state.screen.screen.videoState
  })
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Extra))
