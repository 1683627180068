import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import LoginButton from '../components/LoginButton'

const mapStateToProps = (state) => ({
  isMobile: state.isMobile
})

export default withRouter(connect(mapStateToProps, {})(LoginButton))
